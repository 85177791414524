export const getMileageReportsByAuditStatus = /* GraphQL */ `
  query GetMileageReportsByAuditStatus(
    $auditStatus: AuditStatus
    $sortDirection: ModelSortDirection
    $filter: ModelMileageReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMileageReportsByAuditStatus(
      auditStatus: $auditStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        auditStatus
        id
        odoMileage
        photos {
          mimeType
          size
          storageKey
        }
        tsReportDate
        username
        createdAt
        updatedAt
        vehicle {
          status
          inactiveDate
        }
      }
      nextToken
    }
  }
`;
