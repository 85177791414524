import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import StarIcon from '@material-ui/icons/Star';

import { deletePaymentCard } from 'graphql/mutations';
import { asyncRetryMutation } from 'utilities/graph';

const BillingInfoStoredPaymentList = ({
  username,
  paymentMethods,
  onDeleteCompleted,
  classes,
  setError = () => { },
}) => {
  const [disabled, setDisabled] = useState(false);

  if (paymentMethods.length === 0) {
    return <></>;
  }

  const handleRemoveCard = async (id, label) => {
    if (!window.confirm(`'Do you want to remove this card ${label} ?`)) {
      return;
    }

    try {
      setDisabled(true);
      await asyncRetryMutation(deletePaymentCard, {
        input: {
          id,
          username,
        },
      });

      if (onDeleteCompleted) {
        onDeleteCompleted();
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Payment Methods
      </Typography>
      <List>
        {paymentMethods.map((method) => {
          return (
            <ListItem key={`payment-method-${method.id}`}>
              {!method.isDefault ? null : (
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
              )}
              <ListItemText
                primary={method.label}
                secondary={`Expires on ${method.expirationMonth}/${method.expirationYear}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  disabled={disabled}
                  onClick={() => handleRemoveCard(method.id, method.label)}
                >
                  <DeleteIcon color="inherit" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

BillingInfoStoredPaymentList.propTypes = {
  classes: PropTypes.object,
  paymentMethods: PropTypes.array,
  username: PropTypes.string,
  onDeleteCompleted: PropTypes.func,
  setError: PropTypes.func,
};

export default BillingInfoStoredPaymentList;
