import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import FlagIcon from '@material-ui/icons/Flag';

import { asyncRetryMutation } from 'utilities/graph';
import { updateTrip } from '../Trips/graphql';

export default function TripReviewButton({ trip = {}, onUpdate }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateReviewStatus = async () => {
    setIsLoading(true);

    const { id } = trip;
    const input = {
      id,
      reviewStatus: (trip.reviewStatus === 'pending') ? 'completed' : 'pending',
      reviewReason: (trip.reviewReason) ? trip.reviewReason : 'admin',
    };

    try {
      const {
        data: {
          updateTrip: result,
        },
      } = await asyncRetryMutation(updateTrip, { input }, { clearCacheKeys: ['ListTrips'] });
      if (onUpdate) {
        onUpdate(result);
      }
    } catch (e) {
      global.logger.warn(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Tooltip
      title={'Flag this trip for secondary review'}
      placement="bottom"
    >
      <IconButton
        aria-label={'flag'}
        size={'small'}
        disabled={isLoading}
        onClick={() => {
          handleUpdateReviewStatus();
        }}
      >
        {trip.reviewStatus === 'pending' ? (
          <FlagIcon color="primary" />
        ) : (
          <FlagOutlinedIcon />
        )}
      </IconButton>
    </Tooltip>
  );
}

TripReviewButton.propTypes = {
  trip: PropTypes.object,
  onUpdate: PropTypes.func,
};
