export const getParticipantsByStatus = /* GraphQL */ `
  query GetParticipantsByStatus(
    $status: Status
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getParticipantsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountNo
        # ageGroup
        # closedDate
        # closedReason
        # closedBy
        # createdAt
        # createdBy
        # email
        firstDataReceivedDate
        firstName
        # gender
        # governmentAffiliation
        lastName
        # middleName
        # mroDataProviderPreference
        # mroDevicePreference
        # mroManualCapture
        # onboardedDate
        participantPilotProgramId
        # phoneNumber
        # preferredContactType
        # roadChargeCreditCents
        status
        statusMessage
        # sumFuelTaxCreditCents
        # sumMileageUserFeeCents
        # updatedBy
        # updatedAt
        username
        flags {
          hasIntegrityViolation,
          isBillingOverdue,
          isBillingDefault,
          isInactive,
          isVinMismatch,
        }
      }
      nextToken
    }
  }
`;
