import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import useScreenView from 'utilities/useScreenView';

import TabPanel from 'components/Tab/TabPanel';

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  tabs: {
    'borderRight': `1px solid ${theme.palette.divider}`,
    'marginRight': theme.spacing(2),
    'width': 100,
    [theme.breakpoints.up('sm')]: {
      width: 230,
    },
  },
  panels: {
    flex: 1,
  },
}));

export default function VerticalTabs({ tabs = [], isNested = false, defaultTabIndex = 0 }) {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { isMobileView } = useScreenView();

  const [tabIndex, setTabIndex] = useState(defaultTabIndex);

  const handleChangeTab = (e, newValue) => {
    setTabIndex(newValue);

    if (!isNested) {
      const tabName = tabs[newValue].label.replace('&', ' ');
      history.push({
        search: `?tab=${tabName}`,
      });
    }
  };

  useEffect(() => {
    if (!tabs || tabs.length === 0 || isNested) return;

    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get('tab');
    if (tab) {
      const tabIndex = !isNaN(tab) ? parseInt(tab) : tabs.findIndex(({ label }) => label.replace('&', ' ') === tab);
      setTabIndex(tabIndex);
    }
  }, [isNested, tabs, search]);

  return (
    <div className={classes.root}>
      {!isMobileView &&
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabIndex}
        onChange={handleChangeTab}
        className={classes.tabs}
      >
        {tabs.map(({ label }, index) => (
          <Tab key={index} label={label} id={`vertical-tab-${index}`} />
        ))}
      </Tabs>}
      {tabs.map(({ component }, index) => (
        <TabPanel key={index} value={tabIndex} index={index} className={classes.panels}>
          {component}
        </TabPanel>
      ))}
    </div>
  );
}

VerticalTabs.propTypes = {
  tabs: PropTypes.array,
  isNested: PropTypes.bool,
  defaultTabIndex: PropTypes.number,
};
