import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DEFAULT_PROMPT_WAIT_TIME_IN_SECONDS = 10;

export default function InactivityMonitor({
  showTimer = false,
}) {
  const history = useHistory();

  const [remainingTime, setRemainingTime] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [promptRemainingTime, setPromptRemainingTime] = useState(DEFAULT_PROMPT_WAIT_TIME_IN_SECONDS);

  const PARTICIPANT_SESSION_TIMEOUT = parseFloat(localStorage.getItem('ruc:configuration:PARTICIPANT_SESSION_TIMEOUT') || 10);

  const logout = async () => {
    setShowWarning(false);

    try {
      await Auth.signOut();
      history.push('/signin');
    } catch (e) {
      history.push('/signin');
    }
  };

  const resetTimer = () => {
    reset();
    setShowWarning(false);
  };

  const onIdle = () => {
    setShowWarning(true);
  };

  const {
    getRemainingTime,
    reset,
  } = useIdleTimer({
    onIdle,
    timeout: (PARTICIPANT_SESSION_TIMEOUT * 60 * 1000),
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    // RUC-1029 Force signout users when tab/browser is closed
    window.addEventListener('beforeunload', async () => {
      await Auth.signOut();
    });
  }, []);

  useEffect(() => {
    if (promptRemainingTime <= 0) {
      logout();
    }
  }, [promptRemainingTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!showWarning) {
        const remainingTime = Math.ceil(getRemainingTime() / 1000);
        // global.logger.debug('remainingTime', remainingTime);
        setRemainingTime(remainingTime);
        setPromptRemainingTime(DEFAULT_PROMPT_WAIT_TIME_IN_SECONDS);
      } else {
        setPromptRemainingTime(promptRemainingTime - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [showWarning, getRemainingTime, promptRemainingTime]);

  return (
    <React.Fragment>
      {showTimer &&
      <div>
        {remainingTime}
      </div>}
      <Dialog
        open={showWarning}
        onClose={resetTimer}
      >
        <DialogTitle id="alert-dialog-title">
          Inactivity Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have been idle for {PARTICIPANT_SESSION_TIMEOUT} minutes. Do you still need more time?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} color="primary">
            Logout ({promptRemainingTime})
          </Button>
          <Button onClick={resetTimer} color="secondary" variant='contained'>
            I need more time
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

InactivityMonitor.propTypes = {
  showTimer: PropTypes.bool,
};
