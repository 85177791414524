import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  useLocation,
} from 'react-router-dom';

import { Auth } from 'aws-amplify';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import VerificationCode from './VerificationCode';
import ControlledInput from 'components/Form/ControlledInput';
import RoleExplanation from './RoleExplanation';
import Logo from 'components/Logo';
import { mutationUnauth } from 'utilities/graph';
import { emailCheck } from 'graphql/mutations';

const normalizeEmail = (email) => {
  return email.trim().toLowerCase();
};

const RegisterParticipant = ({ onCompleteStep }) => {
  const classes = useStyles();

  // form states
  const { control, errors, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);

  // pilot program
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const pilot = query.get('pilot') || 'MBUF';

  const inputs = [{
    type: 'text',
    name: 'email',
    label: 'Email Address',
    autoFocus: true,
    required: true,
    invalidText: 'A correctly formatted email is required',
  }, {
    type: 'password',
    name: 'password',
    label: 'Create Password',
    required: true,
    invalidText: 'A password is required',
  }];

  function handleCloseError() {
    setError(false);
  }

  async function handleRegister({ email, password }) {
    const normalized = normalizeEmail(email);

    // API check eligibility
    const checkEmailPayload = {
      input: {
        email,
      },
    };
    const { data: { emailCheck: { eligible, closed } } } = await mutationUnauth(emailCheck, checkEmailPayload);

    if (closed) {
      // eslint-disable-next-line
      setError('Pilot is closed.');
      return;
    }


    if (!eligible) {
      // eslint-disable-next-line
      setError('This email address is not eligible to participate in this pilot.  If you believe you are receiving this message in error, please contact customer support.');
      return;
    }

    try {
      await Auth.signUp({
        username: normalized,
        password,
        attributes: {
          email: normalized,
        },
      });

      setEmail(email);
      setPassword(password);
      setIsRegistered(true);
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        try {
          await Auth.signIn(normalized, password);
          return window.location.reload();
        } catch (e) {
          if (e.code === 'UserNotConfirmedException') {
            setEmail(email);
            setPassword(password);
            setIsRegistered(true);
          } else {
            setIsRegistered(false);
            setError(e.message);
          }
        }
      } else {
        setError(e.message);
      }
    }
  }

  async function handleVerifyEmail(response) {
    try {
      if (response === 'SUCCESS') {
        const normalized = normalizeEmail(email);
        const cognitoUser = await Auth.signIn(
          normalized,
          password,
        );

        onCompleteStep({
          email: normalized,
          password,
          pilotProgram: {
            shortName: pilot.toUpperCase(),
          },
        }, cognitoUser);
      }
    } catch (e) {
      setError(e.message);
    }
  }

  return (
    <div>
      {!isRegistered && (
        <>
          <RoleExplanation pilot={pilot.toUpperCase()} />
          <div className={classes.paper}>
            <form
              className={classes.form}
              onSubmit={handleSubmit(handleRegister)}
              noValidate
            >
              <Grid container spacing={2}>
                {inputs.map((input, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <ControlledInput
                        control={control}
                        errors={errors}
                        {...input}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {error && (
                <Alert
                  className={classes.alert}
                  severity="error"
                  variant="filled"
                  onClose={handleCloseError}>
                  {error}
                </Alert>
              )}
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Continue
              </Button>
            </form>
          </div>
        </>
      )}
      {isRegistered && (
        <div className={classes.paper}>
          <Logo width={250} fullColor display='block' margin='auto' />
          <Typography component="h1" variant="h5">Verify Email</Typography>
          <VerificationCode
            email={email}
            onVerifyEmail={handleVerifyEmail}
          />
        </div>
      )}
    </div>
  );
};

RegisterParticipant.propTypes = {
  onCompleteStep: PropTypes.func,
};

export default RegisterParticipant;
