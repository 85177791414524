/* eslint-disable max-len,no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'index.js';
import { ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import { minus, times } from 'number-precision';
import { v4 } from 'uuid';

import { useStyles } from 'pages/Auth/components/commonStyles';
import {
  getPilotProgram,
  getTrip,
  listMRORates,
  listTripSegments,
  listVehicles,
} from 'graphql/queries';
import { createTripAdjustment } from 'graphql/mutations';
import {
  asyncGet,
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';
import {
  formatFuel,
  formatMileage,
  convertMileage,
  convertMileageRate,
  convertFuel,
} from 'utilities/format';
import {
  getMroRate,
} from 'utilities/adjustment';

const mileageUnit = localStorage.getItem('ruc:configuration:MILEAGE_UNIT') || 'km';
const fuelUnit = localStorage.getItem('ruc:configuration:FUEL_UNIT') || 'l';

const CreateTripAdjustmentDialog = ({ isOpen, onClose, tripId, tripSegmentId }) => {
  const classes = useStyles();

  const [tripSegments, setTripSegments] = useState([]);
  const [note, setNote] = useState('');
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tripSegmentsFields, setTripSegmentsFields] = useState([]);

  const reset = async (refresh = true) => {
    setTripSegments([]);
    setNote('');

    if (refresh) {
      setTimeout(() => {
        setLastUpdatedAt(Date.now());
      });
    }
  };

  const submit = async () => {
    try {
      setIsSubmitting(true);

      const promises = tripSegments
        .filter(({ isDirty }) => isDirty)
        .map(async (segment) => {
          const adjMileage = minus(segment.mileageFormat, segment.cacheItems.mileageFormat);
          const adjFuel = minus(segment.fuelFormat, segment.cacheItems.fuelFormat);

          const input = {
            tripId,
            id: v4(),
            username: segment.username,
            tripSegmentId: segment.id,
            tripSegmentDate: segment.createdAt,
            vehicleId: segment.vehicleId,
            type: segment.type,
            state: segment.state,
            stateCode: segment.stateCode,
            todId: segment.todId,
            todName: segment.todName,
            tollId: segment.tollId,
            adjMileage: segment.mileageIsConverted ? convertMileage(adjMileage, 'km') : adjMileage,
            adjMileageFeeCents: minus(times(segment.mileageFee, 100), (segment.mileageFeeCents || 0)),
            adjFuel: segment.fuelIsConverted ? convertFuel(adjFuel, 'li') : adjFuel,
            adjFuelFeeCents: minus(times(segment.fuelFee, 100), (segment.fuelFeeCents || 0)),
            adjCordonFeeCents: 0,
            adjTodFeeCents: 0,
            adjTollFeeCents: 0,
            note,
            createdBy: localStorage.getItem('ruc:username'),
            createdAt: new Date().toISOString(),
            paymentStatus: 'pending',
          };

          const { data: { createTripAdjustment: savedItem } } = await asyncRetryMutation(createTripAdjustment, {
            input,
          });

          return savedItem;
        });
      const savedItems = await Promise.all(promises);

      reset();
      onClose(savedItems);
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    (async () => {
      setIsSubmitting(true);

      const [
        tripSegments,
        { data: { getTrip: trip } },
      ] = await Promise.all([
        asyncListAll(listTripSegments, { tripId }, { bypassCache: true }),
        asyncGet(getTrip, { id: tripId }),
      ]);

      const [
        vehicles,
        { data: { getPilotProgram: pilotProgram } },
        allMroRates,
      ] = await Promise.all([
        asyncListAll(listVehicles, { username: trip.username, sortDirection: 'DESC' }),
        asyncGet(getPilotProgram, { id: trip.pilotProgramId }),
        asyncListAll(listMRORates),
      ]);

      const tripSegmentsFields = [{
        type: 'number',
        key: 'mileageFormat',
        label: `Mileage (${mileageUnit})`,
        isDisabled: ({ type }) => {
          return type === 'toll';
        },
        show: () => true,
        onUpdate: (segment) => {
          const adjustingVehicle = vehicles.find((item) => item.id == segment.vehicleId);
          if (!adjustingVehicle) {
            alert('Cannot find the associated vehicle to adjust fuel');
            return segment;
          }

          if (!trip.username) {
            alert('administrator must not adjust trips that were not assigned to a user');
            return segment;
          }

          if (segment.todId || segment.cordonId) {
            alert('TOD and Cordon pilot program types are not enabled for this pilot');
            return segment;
          }

          const {
            mroRate,
            fuelUnitRate,
            fuelEconomy,
          } = getMroRate(allMroRates, segment.stateCode, pilotProgram.shortName, adjustingVehicle);

          const mileage = convertMileage(segment.mileageFormat, 'km');
          const fuel = adjustingVehicle.type === 'electric' ? 0 : (mileage / fuelEconomy);

          segment['mileageFee'] = parseFloat((mileage * mroRate.centsPerMileageUnit / 100).toFixed(2));
          segment['fuelFee'] = parseFloat((fuel * fuelUnitRate / 100).toFixed(2));
          segment['fuelFormat'] = parseFloat(convertFuel(fuel, fuelUnit));

          return segment;
        },
      }, {
        type: 'number',
        key: 'mileageFee',
        label: 'Road Charge',
        isDisabled: () => true,
        show: (segment) => !segment.todId && !segment.cordonId && !segment.tollId,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: (segment) => {
          const adjustingVehicle = vehicles.find((item) => item.id == segment.vehicleId);
          const { mroRate, mileageRateType } = getMroRate(allMroRates, segment.stateCode, pilotProgram.shortName, adjustingVehicle);
          const rate = `($${(convertMileage(mroRate.centsPerMileageUnit) / 100).toFixed(3)}/${mileageUnit})`;
          return (<InputAdornment position="end">{rate} ({mileageRateType})</InputAdornment>);
        },
      }, {
        type: 'number',
        key: 'fuelFormat',
        label: 'Fuel',
        isDisabled: () => true,
        show: (segment) => !segment.todId && !segment.cordonId && !segment.tollId,
        endAdornment: <InputAdornment position="end">{fuelUnit}</InputAdornment>,
      }, {
        type: 'number',
        key: 'fuelFee',
        label: 'Fuel Tax Credits',
        isDisabled: () => true,
        show: (segment) => !segment.todId && !segment.cordonId && !segment.tollId,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: (segment) => {
          const adjustingVehicle = vehicles.find((item) => item.id == segment.vehicleId);
          const { fuelUnitRate, fuelRateType } = getMroRate(allMroRates, segment.stateCode, pilotProgram.shortName, adjustingVehicle);
          const rate = `($${convertFuel(fuelUnitRate / 100)}/${fuelUnit})`;
          return (<InputAdornment position="end">{rate} ({fuelRateType})</InputAdornment>);
        },
      }];

      const formatTripSegments = tripSegments.map((item) => {
        item.displayName = item.state || '';
        item.mileageFormat = formatMileage(item.mileage, mileageUnit);
        item.mileageIsConverted = item.mileageFormat !== item.mileage;
        item.mileageFee = item.mileageFeeCents / 100;
        item.fuelFormat = formatFuel(item.fuel, fuelUnit);
        item.fuelIsConverted = item.fuelFormat !== item.fuel;
        item.fuelFee = item.fuelFeeCents / 100;
        item.cordonFee = item.cordonFeeCents / 100;
        item.cordonEnterFee = item.cordonEnterFeeCents / 100;
        item.todFee = item.todFeeCents / 100;
        item.tollFee = (item.tollFeeCents || 0) / 100;
        item.cacheItems = tripSegmentsFields.reduce((obj, field) => {
          obj[field.key] = item[field.key];
          return obj;
        }, {});
        item.dirtyItems = {};
        return item;
      });

      setTripSegments(formatTripSegments);
      setTripSegmentsFields(tripSegmentsFields);
      setIsSubmitting(false);
    })();
  }, [isOpen, tripId, tripSegmentId, lastUpdatedAt]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="create-trip-adjustment-dialog"
        open={isOpen}
        onClose={() => onClose()}
        disableBackdropClick={true}
        maxWidth="lg"
      >
        <DialogContent>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">Trip Adjustment</Typography>
            <Grid container spacing={2}>
              {tripSegments.filter(({ type }) => type === 'public' || type === 'private').map((segment, index) => (
                <Grid container item key={index} spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="p" variant="h5">
                      {segment.type.toUpperCase()}: {segment.displayName} ({(segment.percentageState * 100).toFixed(0)}%)
                    </Typography>
                  </Grid>
                  {tripSegmentsFields.map(({ key, label, show, type, startAdornment, endAdornment, isDisabled, onUpdate }) => {
                    if (show && !show(segment)) return;
                    return (
                      <Grid item xs={12} md={3} key={key}>
                        <TextField
                          id={key}
                          name={label}
                          label={`${label}${segment.cacheItems[key] !== segment[key] ? ' (adjusted)' : ''}`}
                          type={type}
                          value={segment[key]}
                          fullWidth
                          disabled={isSubmitting || (isDisabled && isDisabled(segment))}
                          variant="outlined"
                          onChange={(e) => {
                            segment[key] = type === 'number' ? +(e.target.value) : e.target.value;
                            segment.isDirty = tripSegmentsFields.some(({ key }) => segment.cacheItems[key] !== segment[key]);
                            if (onUpdate) {
                              segment = onUpdate(segment);
                            }
                            setTripSegments([...tripSegments]);
                          }}
                          error={segment.cacheItems[key] !== segment[key]}
                          InputProps={{
                            startAdornment,
                            endAdornment: typeof endAdornment === 'function' ? endAdornment(segment) : endAdornment,
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ))}
              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={'note'}
                  name={'note'}
                  label={'Note'}
                  type={'text'}
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  multiline
                  required={true}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </Grid>
              <Grid container item spacing={2} className={classes.submit}>
                <Grid item xs={12} md={2}>
                  <Button
                    type="button"
                    size="large"
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    type="button"
                    size="large"
                    color="inherit"
                    disabled={isSubmitting}
                    onClick={() => reset()}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={12} md={8} align="right">
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !tripSegments.some(({ isDirty }) => isDirty) || note === ''}
                    onClick={submit}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

CreateTripAdjustmentDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  tripId: PropTypes.string,
  tripSegmentId: PropTypes.string,
};

export default CreateTripAdjustmentDialog;
