import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Storage } from 'aws-amplify';
import moment from 'moment-timezone';
import { v4 } from 'uuid';

import Alert from '@material-ui/lab/Alert';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

import ControlledInput from 'components/Form/ControlledInput';
import { convertMileage } from 'utilities/format';
import { asyncRetryMutation, asyncListAll } from 'utilities/graph';
import {
  listMileageReports,
  listVehicles,
} from 'graphql/queries';
import { createMileageReport } from 'graphql/mutations';
import AlertDialog from 'components/AlertDialog';

const MileageReport = ({
  username,
  from,
  to,
  onComplete,
  onCancel,
  options = {},
}) => {
  const classes = useStyles();
  const { submitCopy = 'Submit', cancelCopy } = options;

  const [fileUrl, setFileUrl] = useState(null);
  const [reports, setReports] = useState([]);
  const [lastReportOdoMileage, setLastReportOdoMileage] = useState(0);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  // form states
  const { control, errors, handleSubmit, formState, register, watch, setValue, reset } = useForm({
    defaultValues: {
      tsReportDate: moment().format('YYYY-MM-DD'),
    },
  });
  const { isSubmitting } = formState;

  const watchReportImages = watch('reportImages');
  const watchVehicle = watch('vehicle');

  const [error, setError] = useState(false);

  const inputs = [{
    type: 'date',
    name: 'tsReportDate',
    label: 'Reading Date',
    disabled: true,
    required: true,
    shrinkLabel: true,
    invalidText: 'Reading Date is required',
  }, {
    type: 'select',
    name: 'vehicle',
    label: 'Vehicle',
    required: true,
    invalidText: 'A vehicle is required',
    options: vehicles.map((vehicle) => {
      return {
        value: vehicle,
        label: `${vehicle.make} ${vehicle.model} - ${vehicle.licensePlate}`,
      };
    }),
  }, {
    type: 'number',
    name: 'odoMileage',
    label: 'Odometer Mileage Reading',
    required: true,
    invalidText: 'Mileage is required',
  }, {
    type: 'file',
    name: 'reportImages',
    label: 'Odometer Photograph',
    required: true,
    invalidText: 'Photograph is required',
    register,
  }];

  const handleCreateMileageReport = async ({
    // tsReportDate, // date is locked
    vehicle,
    odoMileage,
    reportImages,
  }) => {
    if (convertMileage(odoMileage, 'km') < lastReportOdoMileage) {
      setError('Odometer Mileage Reading can not be less than the value reported last time.');
      return;
    }

    const image = reportImages[0];
    const storageKey = `participant/${username}/mileage-reports/${Date.now().toString()}.${getFileExtension(image.name)}`;
    const formattedReportDate = moment().toISOString();

    try {
      await Storage.put(storageKey, image, {
        level: 'public',
      });

      const { size, type: mimeType } = image;
      await asyncRetryMutation(createMileageReport, {
        input: {
          username,
          id: v4(),
          odoMileage: convertMileage(odoMileage, 'km'),
          tsReportDate: formattedReportDate,
          vehicleId: vehicle.id,
          photos: [{
            storageKey,
            size,
            mimeType,
          }],
          auditStatus: 'pending',
        },
      });

      reset({
        odoMileage: 0,
        reportImages: null,
      });

      setOpenAlertDialog(true);
      if (onComplete) {
        onComplete();
      }
    } catch (e) {
      setError(e.message);
    }
  };

  function getFileExtension(filename) {
    return /(?:\.([^.]+))?$/.exec(filename)[1];
  }

  function handleCloseError() {
    setError(false);
  }

  useEffect(() => {
    if (watchReportImages && watchReportImages.length > 0) {
      const image = watchReportImages[0];

      if (!image.type.startsWith('image/')) {
        setError('Image format is not supported');
        reset({
          reportImages: null,
        });
        return;
      }

      // 20 MB
      if (!image.size > 20 * 1048576) {
        setError('Image size is too large');
        reset({
          reportImages: null,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setFileUrl(e.target.result);
      };
      reader.readAsDataURL(image);
    }
  }, [watchReportImages]);

  useEffect(() => {
    (async () => {
      if (!watchVehicle) return;

      const lastReport = reports
        .filter(({ vehicleId }) => vehicleId === watchVehicle.id)
        .sort((a, b) => a.tsReportDate < b.tsReportDate ? 1 : -1)[0];

      if (lastReport) {
        setLastReportOdoMileage(lastReport.odoMileage);
      } else {
        setLastReportOdoMileage(0);
      }
    })();
  }, [username, reports, watchVehicle]);

  useEffect(() => {
    (async () => {
      const promises = [];
      if (from && to) {
        // Limit by date range
        promises.push(asyncListAll(listMileageReports, {
          username,
          filter: {
            auditStatus: {
              eq: 'approved',
            },
            tsReportDate: {
              between: [from, to],
            },
          },
          sortDirection: 'DESC',
        }));
      } else {
        // Catch 'em all!
        promises.push(asyncListAll(listMileageReports, {
          username,
          filter: {
            auditStatus: {
              eq: 'approved',
            },
          },
          sortDirection: 'DESC',
        }));
      }

      // And one more to get the vehicles...
      promises.push(asyncListAll(listVehicles, {
        username,
        filter: {
          mroType: {
            eq: 'manual',
          },
        },
        sortDirection: 'DESC',
      }));

      const [reports, vehicles] = await Promise.all(promises);
      setVehicles(vehicles);
      setReports(reports);

      if (vehicles[0]) {
        setValue('vehicle', vehicles[0]);
      }
    })();
  }, [username, from, to]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AssessmentIcon color="inherit" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create Mileage Report
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleCreateMileageReport)}
          noValidate
        >
          <Grid container spacing={2}>
            {inputs.map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}
          </Grid>
          {watchReportImages && watchReportImages.length > 0 && (
            <div className={classes.imagePreview}>
              <img
                src={fileUrl}
                width="100%"
              />
            </div>
          )}
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            {submitCopy}
          </Button>
          {cancelCopy && (
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              onClick={() => {
                onCancel();
              }}
            >
              {cancelCopy}
            </Button>
          )}
        </form>
        <Snackbar
          open={error !== false}
          autoHideDuration={10000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
        <AlertDialog
          open={openAlertDialog}
          onClose={() => setOpenAlertDialog(false)}
          title="Success!"
          text="Your odometer mileage has been submitted. If we have any questions our help desk will reach out to you to verify mileage. Safe travels."
        />
      </div>
    </Container >
  );
};

MileageReport.propTypes = {
  from: PropTypes.string,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  options: PropTypes.object,
  to: PropTypes.string,
  username: PropTypes.string,
};

export default MileageReport;
