import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LinkButton from 'components/Table/LinkButton';
import Table from 'components/Table';
import { listParticipantConnections } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

const title = 'Vehicle Connections';
const description = '';

export default function ConnectionTable({ viewer = 'admin' }) {
  const [data, setData] = useState([]);

  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'vehicleId',
      label: 'Vehicle ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'resourceProvider',
      label: 'Provider',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'externalVehicleId',
      label: 'External Vehicle ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'accessToken',
      label: 'Access Token',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'authorizationCode',
      label: 'Auth Code',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'refreshToken',
      label: 'Refresh Token',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'Participant',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}?tab=Vehicles`}
              label="View participant details"
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const records = await asyncListAll(listParticipantConnections, {});

        setData(records.sort(sortBy('createdAt', true)));
      } catch (e) {
        global.logger.debug(e);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        themeProps={{ cell: { } }}
      />
    </React.Fragment>
  );
}

ConnectionTable.propTypes = {
  username: PropTypes.string,
  viewer: PropTypes.string,
};
