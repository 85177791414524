export const getTripsByUsernameByCreatedAt = /* GraphQL */ `
  query GetTripsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        invalid
        routeEngine
        routeEngineVersion
        notes
        updatedAt
        adjustments {
          items {
            tripId
            id
          }
        }
        tripSegments {
          items {
            tripId
            id
            type
            stateCode
            mileage
            fuel
            paymentStatus
          }
        }
        vehicle {
          vin
          make
          model
          year
          licensePlate
          registrationState
        }
      }
      nextToken
    }
  }
`;
