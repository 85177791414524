import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import StopIcon from '@material-ui/icons/Stop';

import { formatMileage } from 'utilities/format';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SegmentList({ segments = [], cordons = [], tods = [], onUpdate }) {
  const classes = useStyles();

  const [list, setList] = useState({});
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (!segments || segments.length === 0) return;
    global.logger.debug(segments);
    setList(segments.reduce((list, segment, index) => {
      segment.cordon = cordons.find(({ id }) => id === segment.cordonId);
      segment.tod = tods.find(({ id }) => id === segment.todId);

      segment.displayLabel = `${segment.stateCode}`;

      if (segment.cordon) {
        segment.displayLabel = `${segment.stateCode} - ${segment.cordon.name}`;
      }

      if (segment.tod) {
        segment.displayLabel = `${segment.stateCode || 'N/A'} - ${segment.tod.name}`;
      }

      if (segment.tollId) {
        segment.displayLabel = `${segment.tollId}`;
        segment.displayDescription = `${segment.tollEntryId} - ${segment.tollExitId}`;
      }

      segment.displayDescription = segment.displayDescription || `${formatMileage(segment.mileage, null, true)}`;

      const key = `${segment.type.toUpperCase()} ROADS`;
      list[key] = list[key] || [];
      list[key].push(segment);
      return list;
    }, {}));
    setChecked(segments.map(({ id }) => id));
  }, [segments, cordons, tods]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    if (onUpdate) {
      onUpdate(newChecked);
    }
  };

  const handleToggleAll = () => {
    const newChecked = checked.length > 0 ? [] : segments.map(({ id }) => id);
    global.logger.debug(newChecked);
    setChecked(newChecked);
    if (onUpdate) {
      onUpdate(newChecked);
    }
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem
        dense
        button
        onClick={handleToggleAll}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.length > 0}
            indeterminate={checked.length > 0 && checked.length !== segments.length}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText
          primary={`Select All`}
        />
      </ListItem>
      {Object.keys(list).map((key) => (
        <Fragment key={key}>
          <ListSubheader>{key}</ListSubheader>
          {list[key].map(({ id, displayLabel, displayDescription, color }, index) => {
            return (
              <ListItem
                key={index}
                role={undefined}
                dense
                button
                onClick={handleToggle(id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={displayLabel}
                  secondary={displayDescription}
                />
                <ListItemSecondaryAction>
                  <StopIcon style={{ color }} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </Fragment>
      ))}
    </List>
  );
}

SegmentList.propTypes = {
  segments: PropTypes.array,
  cordons: PropTypes.array,
  tods: PropTypes.array,
  onUpdate: PropTypes.func,
};
