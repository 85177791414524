import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import NotificationsTable from 'pages/Admin/components/NotificationsTable';
import CreateNotificationDialog from './CreateNotificationDialog';

import { asyncListAll } from 'utilities/graph';
import {
  getNotificationsByUsernameByCreatedAt,
} from 'graphql/queries';
import { sortBy } from 'utilities/sorting';
import { useStyles } from '../styles';

const ParticipantNotifications = ({ username }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (username) {
        setIsLoading(true);
        try {
          const notifications = await asyncListAll(
            getNotificationsByUsernameByCreatedAt, { username }, { bypassCache: true },
          );

          setNotifications(notifications.sort(sortBy('createdAt', true)));
        } catch (e) {
          global.logger.debug(e);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [username, forceRefresh]);

  const handleOpenCreateDialog = () => {
    setCreateDialogIsOpen(true);
  };

  const handleCloseCreateDialog = (forceUpdate) => {
    setCreateDialogIsOpen(false);
    if (forceUpdate) {
      setForceRefresh(!forceRefresh);
    }
  };

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  if (!notifications) {
    return null;
  }

  return (
    <React.Fragment>
      <NotificationsTable
        title="Notifications"
        data={notifications}
        onHandleCreate={handleOpenCreateDialog}
      />
      {createDialogIsOpen &&
        <CreateNotificationDialog
          isOpen={createDialogIsOpen}
          onClose={handleCloseCreateDialog}
          username={username}
        />}
    </React.Fragment>
  );
};

ParticipantNotifications.propTypes = {
  username: PropTypes.string,
};

export default ParticipantNotifications;
