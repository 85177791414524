import { convertMileageRate } from './format';

export const getMroRate = (mroRates, stateCode, pilotProgramShortName, vehicle) => {
  let mileageRateType = 'state';
  let fuelRateType = 'gasoline';

  const mroRate = Object.assign({}, mroRates.find(({ state }) => state === stateCode));
  const fuelUnitRate = (vehicle.type === 'diesel') ? mroRate.centsPerFuelUnitForDiesel : mroRate.centsPerFuelUnit;
  const fuelEconomy = convertMileageRate(parseFloat(vehicle.epaVehicleCombinedMpg));

  // dynamic rate program
  if (pilotProgramShortName === 'MBUF+DR') {
    mileageRateType = 'dynamic';
    mroRate.centsPerMileageUnit = parseFloat(fuelUnitRate / fuelEconomy);
  }

  // diesel fuel
  if (vehicle.type === 'diesel') {
    fuelRateType = 'diesel';
  }

  return {
    mroRate,
    fuelUnitRate,
    mileageRateType,
    fuelRateType,
    fuelEconomy,
  };
};
