import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import AssociateTripsDialog from 'pages/Admin/components/AssociateTripsDialog';
import TripsTable from 'pages/Admin/components/TripsTable';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { getTrips } from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Trips() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [associateTripsDialogIsOpen, setAssociateTripsDialogIsOpen] = useState(false);

  // update trip associations
  const [toUpdateTrips, setToUpdateTrips] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [updateTripsMessage, setUpdateTripsMessage] = useState(false);

  const onSubmit = async ({ from, to }) => {
    setIsLoading(true);

    const processStatus = ['pending', 'processed', 'failed'];
    const results = await Promise.all(processStatus.map((processStatus) => {
      const queryParams = {
        processStatus,
        createdAt: {
          between: [from, to],
        },
      };
      return asyncListAll(getTrips, queryParams, { bypassCache: true });
    }));

    const trips = results.reduce((allItems, items) => {
      return [...allItems, ...items];
    }, []);

    setTrips(trips.sort(sortBy('createdAt', true)));
    setIsLoading(false);
  };

  useEffect(() => { }, []);

  const handleOpenAssociateTripsDialog = (trips) => {
    setToUpdateTrips(trips.map((trip) => trip.tripId));
    setAssociateTripsDialogIsOpen(true);
  };

  const handleCloseAssociateTripsDialog = (result) => {
    setAssociateTripsDialogIsOpen(false);
    setForceRefresh(!forceRefresh);

    const { message = null } = result;
    if (message) {
      setUpdateTripsMessage(message);
    }
  };

  const handleCloseMessage = () => {
    setUpdateTripsMessage(false);
  };

  return (
    <div className={classes.root}>
      <DateRangeSelector
        unit='week'
        onSubmit={onSubmit}
        submitOnLoad={true}
        disabled={isLoading}
      />
      <TripsTable
        data={trips}
        onHandleAssociateTrips={handleOpenAssociateTripsDialog}
        forceRefresh={forceRefresh}
      />
      <>
        <AssociateTripsDialog
          isOpen={associateTripsDialogIsOpen}
          onClose={handleCloseAssociateTripsDialog}
          trips={toUpdateTrips}
        />
        <Snackbar
          open={updateTripsMessage != false}
          autoHideDuration={10000}
          onClose={handleCloseMessage}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="success"
            variant="filled"
            onClose={handleCloseMessage}>
            {updateTripsMessage}
          </Alert>
        </Snackbar>
      </>
    </div>
  );
}
