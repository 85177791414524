const emailAddress = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const phoneNumber = /^\(\d{3}\)\s\d{3}-\d{4}$/;
const name = /^[A-Za-z \-]*$/;

export const regex = {
  email,
  emailAddress,
  phoneNumber,
  name,
};
