export const vehicleFuelPreferences = [{
  label: 'Gasoline',
  value: 'gas',
}, {
  label: 'Diesel',
  value: 'diesel',
}, {
  label: 'Hybrid',
  value: 'hybrid',
}, {
  label: 'Electric',
  value: 'electric',
}];
