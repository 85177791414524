/* eslint-disable no-unreachable,no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { decodeVin } from 'utilities/nhtsa';
import { asyncRetryMutation, asyncGet } from 'utilities/graph';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Logo from 'components/Logo';

import ControlledInput from 'components/Form/ControlledInput';
import TextField from '@material-ui/core/TextField';

import {
  validateVehicleVin,
  validatePilotEligibility,
} from 'utilities/vehicle';
import {
  states,
} from 'utilities/constants';
import {
  shellVehicle,
} from 'utilities/constants/shellModels';

import {
  getEPAVehiclesByMakeByYear,
} from 'graphql/queries';
import {
  updateParticipant,
  vinCheck,
} from 'graphql/mutations';
import {
  createVehicle,
  updateVehicle,
} from './graphql';

const normalizeString = (inString) => {
  return (inString || '').replace(/ /g, '').replace(/-/g, '').toLowerCase();
};

const hasMatch = (source, target) => {
  if (!source || !target) return false;

  return normalizeString(source).includes(normalizeString(target));
};

const getUniqueEpaVehicles = async (vehicleYear, vehicleMake, filterModel, filterSeries) => {
  const {
    data: {
      getEPAVehiclesByMakeByYear: {
        items,
      },
    },
  } = await asyncGet(getEPAVehiclesByMakeByYear, {
    make: vehicleMake.toUpperCase(),
    year: {
      eq: vehicleYear,
    },
  }, { bypassCache: true });

  const unique = {};
  const uniqueEpaVehicles = items
    .filter(({ model, trany, displacement }) => {
      const key = `${model}|${trany}|${displacement}`;
      if (!unique[key]) {
        unique[key] = true;
        return true;
      }
    })
    .filter(({ model }) => {
      return (hasMatch(model, filterModel) || hasMatch(model, filterSeries));
    });

  return uniqueEpaVehicles;
};

// VIN
// - hydrogen   JTDAAAAA9PA011307
// - electric   5YJSA1H20FFP78357
// - gas        2T3DFREV0FW317743
// - hybrid gas 5TDJRKEC4MS009652
// - hybrid ev  ZFF95NLA7M0263166

const RegisterVehicle = ({
  username,
  vehicle = shellVehicle,
  onCompleteStep,
  onPreviousStep,
  options = {},
}) => {
  const classes = useStyles();

  // registration & participant management screens
  const {
    previousStepCopy,
    submitCopy = 'Continue',
    title = 'Vehicle Information',
  } = options;

  // feature flags
  const registerWithVinEnabled = localStorage.getItem(`ruc:configuration:FEATURE_REGISTER_WITH_VIN`) === 'enabled';

  // form states
  const { control, errors, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;

  const [vehicleVin, setVehicleVin] = useState(null);
  const [vehicleModel, setVehicleModel] = useState();
  const [vehicleMake, setVehicleMake] = useState();
  const [vehicleYear, setVehicleYear] = useState();

  const [error, setError] = useState(false);
  const [eligibilityErrors, setEligibilityErrors] = useState([]);
  const [epaVehicles, setEpaVehicles] = useState([]);
  const [filterModel, setFilterModel] = useState(false);
  const [fuelType, setFuelType] = useState(null);
  const [fuelSubtype, setFuelSubtype] = useState(null);
  const [vinFuelType, setVinFuelType] = useState(null);
  const [modelOther] = useState({
    value: {
      model: 'Other',
      mpgCombined: 23, // default fuel efficiency
      kplCombined: 9.7783, // default fuel efficiency
    },
    label: 'Other',
  });

  const resetVehicle = () => {
    setVehicleModel('');
    setVehicleMake('');
    setVehicleYear('');
    setFilterModel('');
    setVinFuelType('');
    setFuelType('');
    setEpaVehicles([]);
  };


  useEffect(() => {
    if (!vehicleVin || vehicleVin === '' || vehicleVin.length !== 17) {
      return;
    }

    if (!validateVehicleVin(vehicleVin)) {
      setError('Unable to determine vehicle information because the Vehicle VIN is not valid.');
      return;
    }

    (async () => {
      try {
        const { make, model, series, year, fuelType1, fuelType2 } = await decodeVin(vehicleVin);
        const filterModel = model || '';
        const filterSeries = series || '';
        const vehicleMake = make.toUpperCase();
        const vehicleYear = year;

        const epaVehicles = await getUniqueEpaVehicles(vehicleYear, vehicleMake, filterModel, filterSeries);

        setVehicleMake(vehicleMake);
        setVehicleYear(vehicleYear);
        setFilterModel(filterModel);
        setEpaVehicles(epaVehicles);

        switch (fuelType1) {
          case 'Gasoline':
            setVinFuelType(fuelType2 === 'Electric' ? 'Hybrid' : 'Gas');
            break;
          case 'Electric':
          case 'Hydrogen':
            setVinFuelType('Electric');
            break;
          case 'Diesel':
            setVinFuelType('Diesel');
            break;
          default:
            global.logger.warn('unsupported vehicle fuel type');
        }
      } catch (e) {
        if (e.includes('failed to decode vin')) {
          setError('Unable to decode VIN');
          return;
        } else {
          global.logger.warn(e);
        }
      }
    })();
  }, [vehicleVin]);

  const getVehicleTypeFromModel = (fuelType1, fuelType2) => {
    switch (fuelType1) {
      case 'Premium Gasoline':
      case 'Regular Gasoline':
      case 'Midgrade Gasoline':
        return { type: fuelType2 === 'Electricity' ? 'Hybrid' : 'Gas' };
      case 'Electric':
      case 'Electricity':
        return { type: 'Electric' };
      case 'Hydrogen':
        return { type: 'Electric', subtype: 'Hydrogen' };
      case 'Diesel':
        return { type: 'Diesel' };

      default:
        global.logger.warn('unsupported vehicle fuel type');
        return {};
    }
  };

  const inputs = [{
    autoFocus: true,
    type: 'text',
    label: 'Year',
    disabled: registerWithVinEnabled,
    defaultValue: vehicle.year,
    value: vehicleYear,
  }, {
    type: 'text',
    label: 'Make',
    disabled: registerWithVinEnabled,
    defaultvalue: vehicle.make,
    value: vehicleMake,
  }, {
    type: 'select',
    name: 'model',
    label: 'Model',
    required: true,
    options: epaVehicles.map((vehicle) => {
      return {
        value: vehicle,
        label: `${vehicle.model} (${vehicle.trany}, ${vehicle.cylinders} cylinders, ${vehicle.displacement}L)`,
        testValue: vehicle.id,
        key: vehicle.id,
      };
    }).sort((a, b) => a.label > b.label ? 1 : -1).concat(modelOther),
    invalidText: (
      vehicleYear &&
      vehicleMake &&
      epaVehicles.length === 0
    ) ? `No models found for a ${vehicleYear} ${vehicleMake} ${filterModel}` : 'Vehicle model is required',
    inputProps: {
      onChange: async (event) => {
        // event.target.value is the epa vehicle
        const selectedVehicle = event.target.value;
        const {
          model,
          fuelType1,
          fuelType2,
          mpgCombined,
        } = selectedVehicle;
        if (model === 'Other') {
          setFuelType(vinFuelType);
          setValue('fuel', vinFuelType);
          setValue('mpg', 'N/A');
        } else {
          const { type, subtype } = getVehicleTypeFromModel(fuelType1, fuelType2);
          setFuelType(type);
          setFuelSubtype(subtype);
          setValue('fuel', type);
          setValue('mpg', mpgCombined || 'N/A');
        }
        setVehicleModel({
          model: model,
        });
      },
    },
  }, {
    type: 'text',
    name: 'nonEpaModel',
    label: 'Model',
    display: vehicleModel && vehicleModel.model == 'Other',
    required: true,
    invalidText: 'A model is required',
  }, {
    type: 'text',
    name: 'fuel',
    label: 'Fuel Type',
    disabled: true,
  }, {
    type: 'text',
    name: 'mpg',
    disabled: true,
    label: 'MPG',
    defaultValue: 'N/A',
  }, {
    type: 'select',
    name: 'registrationState',
    defaultValue: vehicle.registrationState,
    label: 'Registered State',
    required: true,
    options: Object.keys(states).map((state) => {
      return {
        value: state,
        label: states[state],
      };
    }),
    invalidText: 'State of registration is required',
  }, {
    type: 'text',
    name: 'licensePlate',
    defaultValue: vehicle.licensePlate,
    label: 'License Plate No.',
    required: true,
    invalidText: 'License plate is required',
    setValue,
    inputProps: {
      maxLength: 8,
    },
  }];

  if (registerWithVinEnabled) {
    inputs.unshift({
      autoFocus: true,
      type: 'text',
      name: 'vin',
      label: 'Vehicle Identification Number (VIN)',
      required: true,
      invalidText: 'Vehicle VIN is required',
      inputProps: {
        maxLength: 17,
        onChange: (event) => {
          resetVehicle();
          setVehicleVin(event.target.value);
        },
        onBlur: async (event) => {
          if (event.target.value !== vehicleVin) {
            resetVehicle();
            setVehicleVin(event.target.value);
          }
        },
      },
    });
  } else {
    if (vehicleYear >= 1996 && vehicleYear <= 2006) {
      inputs.push({
        type: 'text',
        name: 'vin',
        label: 'Vehicle VIN',
        required: true,
        invalidText: 'Please provide a valid Vehicle VIN',
        setValue,
      });
    }
  }

  async function handleRegisterVehicle({
    make,
    model,
    nonEpaModel,
    year,
    licensePlate,
    registrationState,
    vin,
  }) {
    // pilot eligibility
    const { isValid, errors } = validatePilotEligibility(year, fuelType);
    if (!isValid) {
      setEligibilityErrors(errors);
      return;
    }

    const epaVehicle = Object.assign({}, model);
    const input = {
      username,
      vin: vin.toUpperCase(),
      make: epaVehicle.make || vehicleMake,
      model: epaVehicle.model === 'Other' ? nonEpaModel : epaVehicle.model,
      year: epaVehicle.year || vehicleYear,
      type: fuelType.toLowerCase(),
      subtype: fuelSubtype ? fuelSubtype.toLowerCase() : undefined,
      licensePlate,
      registrationState,
      mroId: 'N/A',
      mroType: 'N/A',
      epaVehicleId: epaVehicle.id || 'N/A',
      epaVehicleCombinedKpl: epaVehicle.kplCombined,
      epaVehicleCombinedMpg: epaVehicle.mpgCombined,
      createdBy: localStorage.getItem('ruc:username'),
      updatedBy: localStorage.getItem('ruc:username'),
      isPrimary: true,
    };

    const vehicleExists = (vehicle.id) ? true : false;
    if (vehicleExists) {
      input.beginningOdometerReading = vehicle.beginningOdometerReading;
      input.createdBy = vehicle.createdBy;
      input.currentOdometerReading = vehicle.currentOdometerReading;
      input.gotoll = vehicle.gotoll;
      input.fuelTaxCreditCents = vehicle.fuelTaxCreditCents;
      input.id = vehicle.id;
      input.locations = vehicle.locations;
      input.logs = vehicle.logs;
      input.mileage = vehicle.mileage;
      input.mileageUserFeeCents = vehicle.mileageUserFeeCents;
      input.mroType = vehicle.mroType;
      input.mroId = vehicle.mroId;
      input.reports = vehicle.reports;
      input.updatedBy = vehicle.updatedBy;
    }

    let vehicleRecord;
    try {
      /**
       * Users who return to the vehicle section of
       * the registration flow before they complete
       * onboarding
       */
      if (vehicleExists) {
        /* eslint-disable-next-line */
        const [updatedVehicle, updatedParticipant] = await Promise.all([
          asyncRetryMutation(updateVehicle, { input }),
          asyncRetryMutation(updateParticipant, {
            input: {
              username,
              mroDevicePreference: null, // reset for the reporting options screen
              updatedBy: localStorage.getItem('ruc:username'),
            },
          }),
        ]);

        vehicleRecord = updatedVehicle.data.updateVehicle;
      } else {
        // check for duplicates (doesn't need to be FF locked)
        if (vin) {
          const { data: { vinCheck: { vinExists } } } = await asyncRetryMutation(vinCheck, {
            input: {
              vin,
            },
          });

          if (vinExists) {
            setError('This vehicle has already been registered');
            return;
          }
        }

        // create vehicle
        const response = await asyncRetryMutation(createVehicle, { input });
        vehicleRecord = response.data.createVehicle;
      }

      onCompleteStep(Object.assign({}, input, vehicleRecord));
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.paper}>
      <Logo width={250} fullColor display='block' margin='auto' />
      <Typography component="h1" variant="h5">{title}</Typography>
      {eligibilityErrors.length === 0 ? (
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleRegisterVehicle)}
          noValidate
        >
          <Grid container spacing={2}>
            {registerWithVinEnabled && (
              inputs.splice(0, 1).map(((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              }))
            )}
            {inputs.splice(0, 2).map(((input, index) => {
              return (
                <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...input}
                  />
                </Grid>
              );
            }))}
            {inputs.map((input, index) => {
              if (Object.hasOwn(input, 'display') && !input.display) {
                return (<></>);
              } else {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              }
            })}
            <Grid item xs={12} sm={6}>
              <Button
                type="button"
                size="large"
                fullWidth
                variant="contained"
                color="inherit"
                className={classes.secondaryAction}
                onClick={() => {
                  onPreviousStep();
                }}
              >
                {previousStepCopy || 'Back'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {submitCopy}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <div className={classes.paper}>
          <strong id="eligibility-failure">
            Unfortunately your vehicle does not meet the eligibility requirements of this California Road Charge Collection Pilot:
          </strong>
          <ul>
            {eligibilityErrors.map((error, index) => {
              return (<li id={`eligibility-reason-${index}`} key={index}>{error}</li>);
            })}
          </ul>
          <Button
            type="button"
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={() => {
              setEligibilityErrors([]);
            }}
          >
            Update Vehicle Info
          </Button>
        </div>
      )
      }
      <Snackbar
        open={error != false}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterVehicle.propTypes = {
  username: PropTypes.string,
  vehicle: PropTypes.object,
  onCompleteStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  options: PropTypes.object,
};

export default RegisterVehicle;
