import { round, times, enableBoundaryChecking } from 'number-precision';
import moment from 'moment-timezone';

enableBoundaryChecking(false);

const miToKmRatio = 0.62137119223733387264;
const mpgToKplRatio = 0.425143707;

const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

export const formatAddress = (inAddressObject) => {
  if (!inAddressObject) return '';
  const {
    address1,
    address2,
    city,
    state,
    postalCode,
    extendedPostalCode,
  } = inAddressObject;

  return [
    `${address1 ? address1 : ''}`,
    `${address2 ? ` ${address2}` : ''}`,
    `${city ? `, ${city}` : ''}`,
    `${state ? `, ${state}` : ''}`,
    `${postalCode ? ` ${postalCode}` : ''}`,
    `${extendedPostalCode ? `-${extendedPostalCode}` : ''}`,
  ].join('');
};

export const formatFuel = (value = 0, inUnit) => {
  const unit = inUnit || localStorage.getItem('ruc:configuration:FUEL_UNIT') || 'l';

  switch ((unit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return convertFuel(value, 'gal');
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return value;
  }
};

export const formatFuelUnit = (value = 0, unit = 'l') => {
  switch ((unit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      // this is intent to use "l" for calculating "cents per gallons" (fuel unit in denominator)
      return convertFuel(value, 'l');
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return value;
  }
};

export const convertFuel = (value = 0, targetUnit = 'l') => {
  switch ((targetUnit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return parseFloat((value * 0.264172).toFixed(2));
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return parseFloat((value * 3.78541).toFixed(2));
  }
};

export const formatFuelRate = (value = 0, unit = 'l') => {
  switch ((unit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return convertFuelRate(value, 'gal');
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return value;
  }
};

export const convertFuelRate = (value = 0, targetUnit) => {
  const galToLRatio = 0.26417205235814842368;

  switch ((targetUnit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return round(times(value, (1 / galToLRatio)), 2);
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return round(times(value, galToLRatio), 2);
  }
};

export const formatMileage = (inValue = 0, inUnit, withUnit = false) => {
  const unit = inUnit || localStorage.getItem('ruc:configuration:MILEAGE_UNIT') || 'km';
  let value;

  switch ((unit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      value = convertMileage(inValue, 'mi');
      break;
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      value = inValue;
      break;
  }

  return `${value}${withUnit ? ` ${unit}` : ''}`;
};

export const formatMileageUnit = (inValue = 0, inUnit, withUnit = false) => {
  const unit = inUnit || localStorage.getItem('ruc:configuration:MILEAGE_UNIT') || 'km';
  let value;

  switch ((unit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      // this is intent to use "km" for calculating "cents per miles" (mileage unit in denominator)
      value = convertMileage(inValue, 'km');
      break;
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      value = inValue;
      break;
  }

  return `${value}${withUnit ? ` ${unit}` : ''}`;
};


export const convertMileage = (value = 0, targetUnit = 'km') => {
  switch ((targetUnit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      return round(times(value, miToKmRatio), 2);
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      return round(times(value, (1 / miToKmRatio)), 2);
  }
};

export const convertMileageRate = (value = 0, targetUnit = 'km') => {
  switch ((targetUnit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      return round(times(value, (1 / mpgToKplRatio)), 2);
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      return round(times(value, mpgToKplRatio), 2);
  }
};

export const simpleDate = (value, timeZone = DEFAULT_TIME_ZONE) => {
  if (!value) return '';
  return moment(value).tz(timeZone).format('MM-DD-YYYY');
};

export const simpleDateTime = (value, timeZone = DEFAULT_TIME_ZONE) => {
  if (!value) return '';
  return moment(value).tz(timeZone).format('MM-DD-YYYY HH:mm:ss');
};


export const formatCurrency = (value = 0) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(round(value / 100, 2));
};

export const roundNumber = (value = 0, decimal = 2) => {
  return round(value, decimal);
};

export const formatNumber = (value = 0) => {
  return new Intl.NumberFormat('en-US').format(round(value, 2));
};

export const convertCentsToDollars = (value = 0) => {
  return (value / 100).toFixed(2);
};

export const formatMroType = (type) => {
  switch (type) {
    case 'automatedWithLocation':
      return 'Plug-In Device (PID) with GPS';
    case 'automatedWithoutLocation':
      return 'Plug-In Device (PID) without GPS';
    case 'telematics':
      return 'In-Vehicle Telematics';
    case 'manual':
      return 'Manual Odometer Reporting';
    default:
      return type;
  }
};

export const formatMroTypeCertID = (type) => {
  switch (type) {
    case 'automatedWithLocation':
      return 5;
    case 'automatedWithoutLocation':
      return 4;
    default:
      return '';
  }
};

export const formatVehicleFuelType = (type) => {
  switch (type) {
    case 'gas':
    case 'hybrid':
      // hybrid is considered gasoline for fuel type
      return 'Gasoline';
    case 'diesel':
      return 'Diesel';
    case 'electric':
      return 'Electric';
    default:
      return type;
  }
};

export const formatAccountStatus = (status) => {
  switch (status) {
    case 'created':
      return 'Created';
    case 'onboarded':
      return 'Onboarded';
    case 'approved':
      return 'Approved';
    case 'active':
      return 'Active';
    case 'flagged':
      return 'Flagged';
    case 'suspended':
      return 'Suspended';
    case 'closed':
      return 'Closed';
    default:
      return '';
  }
};

export const formatRucGroupName = (pilotProgramShortName) => {
  switch (pilotProgramShortName) {
    case 'MBUF+DR':
      return 'Variable Rate';
    case 'MBUF':
    default:
      return 'Flat Rate';
  }
};

export const formatRUCEmployer = (participant) => {
  let employer = '';
  if (participant?.flags?.isGovernmentEmployee) {
    employer = 'Government';
  } else if (participant?.flags?.isCaliforniaElected || participant?.flags?.isLegislator) {
    employer = 'Elected Official';
  }

  return employer;
};
