import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(0),
    padding: 5,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function Logo({ fullColor, withETCoalition, width = 0, height = 0, margin = 0, display = 'inline', darkBackground = false, avatar }) {
  const classes = useStyles();

  if (avatar) {
    return (
      <Avatar variant="circle" className={classes.avatar} src="/favicon.ico" alt="TU" />
    );
  }

  const filepath = 'caltrans-logo.svg';

  return (
    <img
      src={`/images/${filepath}`}
      alt="Transurban"
      width={width || 'auto'}
      height={height || '100%'}
      style={{
        margin,
        display,
      }}
    />
  );
}

Logo.propTypes = {
  fullColor: PropTypes.bool,
  withETCoalition: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  darkBackground: PropTypes.bool,
  avatar: PropTypes.bool,
  display: PropTypes.string,
};
