import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';

import BasicInfo from 'pages/Participant/Account/components/BasicInfo';
import BillingInfo from 'pages/Participant/Account/components/BillingInfo';
import Inquiries from 'pages/Participant/Account/components/Inquiries';
import MileageReport from 'pages/Participant/Account/components/MileageReport';
import MROInfo from 'pages/Participant/Account/components/MROInfo';
import ParticipantTrips from 'pages/Admin/Participant/components/ParticipantTrips';
import VehicleInfo from 'pages/Participant/Account/components/VehicleInfo';
import Statements from 'pages/Participant/Account/components/Statements';
import Connections from 'pages/Participant/Account/components/Connections';
import Dashboard from 'pages/Participant/Account/components/Dashboard';
import ParticipantMileageReports from 'pages/Admin/Participant/components/ParticipantMileageReports';

import { asyncGet, asyncListAll } from 'utilities/graph';
import { getParticipant, listVehicles } from 'graphql/queries';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';

function accountHasManualReportingVehicle(vehicles) {
  return vehicles.filter(({ mroType }) => {
    return mroType === 'manual';
  }).length > 0;
}

function accountHasTelematics(vehicles) {
  return vehicles.filter(({ mroType }) => {
    return mroType === 'telematics';
  }).length > 0;
}

function accountHasDevice(vehicles) {
  return vehicles.filter(({ mroType }) => {
    return (
      mroType === 'automatedWithLocation' ||
      mroType === 'automatedWithoutLocation'
    );
  }).length > 0;
}

function accountHasPendingConnections(vehicles) {
  return vehicles.length > 0 && vehicles.filter(({ mroType, participantConnectionId }) => {
    return mroType === 'telematics' && !participantConnectionId;
  }).length > 0;
}

const useParticipantTabs = (user, targetParticipant) => {
  const [participant, setParticipant] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [haspendingConnections, setHasPendingConnections] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (!user || !user.username) return;
    (async () => {
      const { username } = targetParticipant || user;

      if (!targetParticipant) {
        const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'] || ['N/A'];
        if (!userGroups.includes('Users')) return;
      }

      const [
        { data: { getParticipant: participant } },
        participantVehicles,
      ] = await Promise.all([
        asyncGet(getParticipant, { username }),
        asyncListAll(listVehicles, { username }),
      ]);

      if (!participant) return;

      setParticipant(participant);
      setVehicles(participantVehicles);
      setHasPendingConnections(accountHasPendingConnections(participantVehicles));

      localStorage.setItem('ruc:name', `${participant.firstName} ${participant.lastName}`);
      localStorage.setItem('ruc:email', participant.email);

      const billingFeatures = systemBillingFeatures(localStorage);

      const tabs = [
        {
          label: 'Dashboard',
          component: <Dashboard user={user} participant={participant} />,
        },
        {
          label: 'My Info',
          component: <BasicInfo user={user} />,
        },
        {
          label: 'Vehicles',
          component: <VehicleInfo user={user} />,
        },
        {
          label: 'Trips',
          component: <ParticipantTrips
            username={username}
            title="My Trips"
            viewer="participant"
            pilotProgram={participant.pilotProgram.shortName}
          />,
          hideFromMenu: !accountHasDevice(participantVehicles),
        },
        {
          label: 'Create Mileage Report',
          component: <MileageReport username={username} />,
          hideFromMenu: participant.status === 'closed' || !accountHasManualReportingVehicle(participantVehicles),
        },
        {
          label: 'Mileage Reports',
          component: <ParticipantMileageReports username={username} viewer='participant' />,
          hideFromMenu: accountHasDevice(participantVehicles),
        },
        {
          label: 'Reporting Devices',
          component: <MROInfo user={user} />,
          hideFromMenu: !accountHasDevice(participantVehicles),
        },
        {
          label: 'Connections',
          component: <Connections user={user} />,
          hideFromMenu: !accountHasTelematics(participantVehicles),
        },
        {
          label: 'Billing Info',
          component: <Container maxWidth="xs"><BillingInfo user={user} /></Container>,
          hideFromMenu: !billingFeatures.registration,
        },
        {
          label: 'Statements',
          component: <Statements username={username} />,
        },
        {
          label: 'Inquiries',
          component: <Inquiries username={username} />,
        },
      ].filter(({ hideFromMenu }) => !hideFromMenu).map((item) => {
        item.path = `/my-account?tab=${item.label}`;
        return item;
      });

      setTabs(tabs);
    })();
  }, [user]);

  return {
    participant,
    vehicles,
    tabs,
    haspendingConnections,
  };
};

export default useParticipantTabs;
