export const getTrips = /* GraphQL */ `
  query GetTripsByProcessStatusByCreatedAt(
    $processStatus: TripProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByProcessStatusByCreatedAt(
      processStatus: $processStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        fuel
        distGps
        distRoute
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        processStatus
        processMsg
        logs
        invalid
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        createdAt
        updatedAt
        adjustments {
          items {
            tripId
            id
          }
        }
        tripSegments {
          items {
            tripId
            id
            type
            stateCode
            mileage
            fuel
            paymentStatus
          }
        }
        vehicle {
          vin
          make
          model
          year
          licensePlate
          registrationState
        }
      }
      nextToken
    }
  }
`;

export const updateTrip = /* GraphQL */ `
  mutation UpdateTrip(
    $input: UpdateTripInput!
    $condition: ModelTripConditionInput
  ) {
    updateTrip(input: $input, condition: $condition) {
      id
      recordId
      recordTripId
      mroDeviceSerialNumber
      vehicleVin
      tsStart
      tsEnd
      polyline
      distMro
      odoMro
      fuel
      distGps
      distRoute
      odoGps
      odoMroStart
      odoMroEnd
      fuelStart
      fuelEnd
      fuelAdded
      totalFuel
      username
      vehicleId
      pilotProgramId
      mroId
      processStatus
      processMsg
      logs
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      reviewStatus
      reviewReason
      createdAt
      updatedAt
      adjustments {
        items {
          tripId
          id
        }
      }
      tripSegments {
        items {
          tripId
          id
          paymentStatus
        }
      }
    }
  }
`;
