import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import VerticalTabs from 'components/Tab/VerticalTabs';

import AccountStatusAlert from './components/AccountStatusAlert';

import useParticipantTabs from '../useParticipantTabs';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

const MyAccount = ({ user, targetParticipant }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    participant,
    tabs,
    haspendingConnections,
  } = useParticipantTabs(user, targetParticipant);

  if (!participant) {
    return <div />;
  }

  if (participant.status === 'created') {
    history.push('/register');
    return <div />;
  }

  if (participant.status === 'onboarded') {
    return (
      <AccountStatusAlert
        participant={participant}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Grid>
        <Grid className={classes.alert}>
          {haspendingConnections && (
            <Alert severity="warning">
              One or more of your registered vehicles requires a telematics connection. <a href="/dashboard?tab=Connections">Set up the connection here.</a>
            </Alert>
          )}
        </Grid>
        <Grid>
          <VerticalTabs
            tabs={tabs}
            isNested={targetParticipant ? true : false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

MyAccount.propTypes = {
  user: PropTypes.object,
  targetParticipant: PropTypes.object,
};

export default MyAccount;
