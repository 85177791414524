import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import ParticipantMros from 'pages/Admin/Participant/components/ParticipantMros';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';

const MROInfo = ({ user }) => {
  const { username } = user;

  return (
    <Container component="main">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ParticipantMros
            username={username}
            title="My Reporting Devices"
            viewer="participant"
          />
        </Grid>
        <Grid item xs={12}>
          <Alert color="info">
            Changing Devices: In order to change your device or switch to another mileage reporting option, please click
            on the Support link above to communicate your request to our Customer Service Representatives.
          </Alert>
        </Grid>
      </Grid>
    </Container >
  );
};

MROInfo.propTypes = {
  user: PropTypes.object,
};

export default MROInfo;
