import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const statuses = [
  'created',
  'onboarded',
  'approved',
  'active',
  'flagged',
  'suspended',
  'closed',
];

export default function AccountStatusSelector({ onUpdate }) {
  const [checkedStatus, setCheckedStatus] = useState([]);

  const handleClick = (status) => {
    const index = checkedStatus.findIndex((s) => s === status);
    if (index === -1) {
      checkedStatus.push(status);
    } else {
      checkedStatus.splice(index, 1);
    }

    setCheckedStatus([...checkedStatus]);
    onUpdate([...checkedStatus]);
  };

  useEffect(() => {
    const initialCheckedStatus = [
      'active',
      'flagged',
      'suspended',
    ];

    setCheckedStatus(initialCheckedStatus);

    if (onUpdate) {
      onUpdate(initialCheckedStatus);
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper style={{ padding: 16, marginBottom: 16 }}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              {statuses.map((status) => (
                <FormControlLabel
                  key={status}
                  value="end"
                  style={{ marginRight: 24 }}
                  control={
                    <Checkbox
                      checked={checkedStatus.includes(status)}
                      color="primary"
                      onClick={() => handleClick(status)}
                    />}
                  label={status.toUpperCase()}
                  labelPlacement="end"
                />))}
            </FormGroup>
          </FormControl>

        </Paper>
      </Grid>
    </Grid>
  );
}

AccountStatusSelector.propTypes = {
  onUpdate: PropTypes.func,
};
