import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';

import {
  listParticipantsWithVehicles,
  getEventsByEventNameByTimestamp,
} from './graphql';
import { listAllForDateRange } from './helpers';

import { APP } from 'utilities/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function mapFields(
  participants,
  deletedVehicleEvents,
) {
  // count by participant status
  const getTotalCountForStatus = (status) => {
    return participants.filter((x) => x.status === status).length;
  };

  const totalNonPayFlaggedAccounts = participants
    .filter(({ status }) => status === 'flagged')
    .filter(({ flags: { isBillingOverdue, isBillingDefault } }) => isBillingOverdue || isBillingDefault)
    .length;

  const totalNonPaySuspendedAccounts = participants
    .filter(({ status }) => status === 'suspended')
    .filter(({ flags: { isBillingOverdue, isBillingDefault } }) => isBillingOverdue || isBillingDefault)
    .length;

  const totalNoHeartBeatFlaggedAccounts = participants
    .filter(({ status }) => status === 'flagged')
    .filter(({ flags: { isInactive } }) => isInactive)
    .length;

  const totalVinMismatchFlaggedAccounts = participants
    .filter(({ status }) => status === 'flagged')
    .filter(({ flags: { isVinMismatch } }) => isVinMismatch)
    .length;

  const totalNoHeartBeatSuspendedAccounts = participants
    .filter(({ status }) => status === 'suspended')
    .filter(({ flags: { isInactive } }) => isInactive)
    .length;

  const totalVinMismatchSuspendedAccounts = participants
    .filter(({ status }) => status === 'suspended')
    .filter(({ flags: { isVinMismatch } }) => isVinMismatch)
    .length;
  console.log(participants.filter(({ status }) => status === 'suspended'));
  // Definition: https://transurbantech.atlassian.net/browse/RUC-1156?focusedCommentId=50240
  return {
    'Total Created Accounts': getTotalCountForStatus('created'),
    'Total Onboarded Accounts': getTotalCountForStatus('onboarded'),
    'Total Approved Accounts': getTotalCountForStatus('approved'),
    'Total Active Accounts':
      getTotalCountForStatus('active') +
      getTotalCountForStatus('flagged') +
      getTotalCountForStatus('suspended'),
    'Total Flagged Accounts': getTotalCountForStatus('flagged'),
    'Total Compliant (no-Issue) Accounts': getTotalCountForStatus('active'),
    'Total Non-Pay Flagged Accounts': totalNonPayFlaggedAccounts,
    'Total Suspended Accounts': getTotalCountForStatus('suspended'),
    'Total Non-Pay Suspended Accounts': totalNonPaySuspendedAccounts,
    'Total Closed Accounts': getTotalCountForStatus('closed'),
    'Total Active VINs':
      getTotalCountForStatus('active') +
      getTotalCountForStatus('flagged') +
      getTotalCountForStatus('suspended'),
    'Total Flagged VINs': getTotalCountForStatus('flagged'),
    'Total Compliant (no- Issue) VINs': getTotalCountForStatus('active'),
    'Total Flagged-No Heartbeat VINs': totalNoHeartBeatFlaggedAccounts,
    'Total Flagged-VIN Mismatch VINs': totalVinMismatchFlaggedAccounts,
    'Total Suspended VINs': getTotalCountForStatus('suspended'),
    'Total Suspended-No Heartbeat VINs': totalNoHeartBeatSuspendedAccounts,
    'Total Suspended-VIN Mismatch VINs': totalVinMismatchSuspendedAccounts,
    'Total Deleted VINs': deletedVehicleEvents.length,
  };
}

export default function SummaryReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [to, setTo] = useState(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);

      const fromDate = '2024-08-01T07:00:00.000Z';
      const toDate = moment().tz(APP.TIME_ZONE).startOf('day').toISOString();

      const [
        participants,
        deletedVehicleEvents,
      ] = await Promise.all([
        // get participants with vehicles
        asyncListAll(listParticipantsWithVehicles),
        // get all removed vehicle events in reporting period
        listAllForDateRange({
          from: fromDate,
          to: toDate,
          gqlQuery: getEventsByEventNameByTimestamp,
          params: (from, to) => {
            return {
              eventName: 'REMOVE',
              timestamp: { between: [from, to] },
              filter: {
                key: { contains: 'Vehicle' },
              },
            };
          },
        }),
      ]);

      return [mapFields(
        participants,
        deletedVehicleEvents)];
    } catch (e) {
      global.logger.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Pilot Weekly Operational Metrics Report
            </Typography>
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`PILOT WEEKLY OPS METRICS Date ${moment().format('MM/DD/YYYY')}.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
