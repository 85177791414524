import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getImageUrl } from 'utilities/image';

export default function ImagePreviewDialog({
  imgS3Key,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState();
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    if (!imgS3Key) {
      setImgUrl(null);
      return;
    }

    (async () => {
      setIsLoading(true);

      const imgUrl = await getImageUrl(imgS3Key);
      setImgUrl(imgUrl);

      setIsLoading(false);
    })();
  }, [imgS3Key]);

  if (!imgS3Key) return null;

  return (<Dialog
    open={true}
    onClose={onClose}
    maxWidth={'xl'}
  >
    <Toolbar>
      <IconButton edge="start" color="inherit" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Toolbar>
    <DialogContent>
      {isLoading ? <CircularProgress color="inherit" /> : <img width="100%" src={imgUrl} />}
    </DialogContent>
  </Dialog>);
}

ImagePreviewDialog.propTypes = {
  imgS3Key: PropTypes.string,
  onClose: PropTypes.func,
};
