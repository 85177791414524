import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import VerticalTabs from 'components/Tab/VerticalTabs';

import TripSegmentsTable from 'pages/Admin/components/TripSegmentsTable';
import TripAdjustmentsTable from 'pages/Admin/components/TripAdjustmentsTable';
import TripsTable from 'pages/Admin/components/TripsTable';

import ParticipantMileageReports from 'pages/Admin/Participant/components/ParticipantMileageReports';

import { asyncGet, asyncListAll } from 'utilities/graph';
import {
  getParticipantStatement,
  getTripAdjustmnentsByParticipantByCreatedAt,
  getTripSegmentsByTransaction,
  getTripAdjustmentsByTransactionByPaymentStatus,
} from 'graphql/queries';
import {
  getTripsByUsernameByCreatedAt,
} from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function Transaction({
  id: inId,
  createdAt, // participant viewer only for trips loading
  username, // participant viewer only for trips loading
  mroDevicePreference, // participant viewer only for loading trips or mileage reports
  computedMatch,
  viewer = 'admin',
  statementId,
}) {
  const classes = useStyles();

  const [id, setId] = useState();
  const [isNested, setIsNested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
      setIsNested(true);
    } else if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
      setIsNested(false);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);
        try {
          const tabs = [];
          if (viewer === 'admin') {
            const [
              segments,
              adjustments,
            ] = await Promise.all([
              asyncListAll(getTripSegmentsByTransaction, { transactionId: id }),
              asyncListAll(getTripAdjustmentsByTransactionByPaymentStatus, { transactionId: id }),
            ]);
            tabs.push({
              label: 'Trip Segments',
              component: <TripSegmentsTable data={segments} viewer={viewer} />,
            });
            tabs.push({
              label: 'Trip Adjustments',
              component: <TripAdjustmentsTable data={adjustments} />,
            });
          }

          if (viewer === 'participant') {
            const {
              data: {
                getParticipantStatement: {
                  periodFrom: from,
                  periodTo: to,
                },
              },
            } = await asyncGet(getParticipantStatement, { id: statementId });

            const fromDate = moment(from).subtract(1, 'month').toISOString();
            const toDate = to;

            switch (mroDevicePreference) {
              case 'telematics': // mileage reports
              case 'manual': {
                const [
                  trips,
                ] = await Promise.all([
                  asyncListAll(getTripsByUsernameByCreatedAt, {
                    username,
                    createdAt: {
                      between: [fromDate, toDate],
                    },
                    sortDirection: 'DESC',
                  }, { bypassCache: true }),
                ]);

                const filteredTrips = trips.filter(({ processedAt }) => {
                  return from <= processedAt && processedAt <= to;
                });

                tabs.push({
                  label: 'Mileage Reports',
                  component: <ParticipantMileageReports
                    username={username}
                    viewer={viewer}
                    trips={filteredTrips}
                  />,
                });
              } break;
              default: { // defaults to trips and trip adjustments
                const [
                  adjustments,
                  trips,
                ] = await Promise.all([
                  asyncListAll(getTripAdjustmnentsByParticipantByCreatedAt, {
                    username,
                    createdAt: {
                      between: [from, to],
                    },
                    sortDirection: 'DESC',
                  }, { bypassCache: true }),
                  asyncListAll(getTripsByUsernameByCreatedAt, {
                    username,
                    createdAt: {
                      between: [fromDate, toDate],
                    },
                    sortDirection: 'DESC',
                  }, { bypassCache: true }),
                ]);

                const filteredTrips = trips.filter(({ processedAt }) => {
                  return from <= processedAt && processedAt <= to;
                });

                tabs.push({
                  label: 'Trips',
                  component: <TripsTable data={filteredTrips} viewer={viewer} />,
                });
                tabs.push({
                  label: 'Adjustments',
                  component: <TripAdjustmentsTable data={adjustments} viewer={viewer} />,
                });
              }
            }
          }

          setTabs(tabs);
        } catch (e) {
          global.logger.warn(e);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id, classes, isNested, viewer, username, createdAt, mroDevicePreference, statementId]);

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <VerticalTabs
      tabs={tabs}
      isNested={isNested}
    />
  );
}

Transaction.propTypes = {
  computedMatch: PropTypes.object,
  createdAt: PropTypes.string,
  id: PropTypes.string,
  mroDevicePreference: PropTypes.string,
  username: PropTypes.string,
  viewer: PropTypes.string,
  statementId: PropTypes.string,
};
