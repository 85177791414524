import pLimit from 'p-limit';
import moment from 'moment';
import {
  simpleDate,
  simpleDateTime,
  convertMileage,
  convertFuel,
  convertCentsToDollars,
} from 'utilities/format';

import {
  getTripsWithTransactionsByProcessStatusByCreatedAt,
  getTripSegmentsByPaymentStatusByCreatedAt,
  getTripAdjustmentsByPaymentStatusByCreatedAt,
} from './queries';

import {
  getTrip,
  getAllVehicles,
  getAllTransactions,
  getAllSystemStatements,
  getParticipants,
  listAllTripsForDateRange,
  listAllTripSegmentsForDateRange,
  listAllTripAdjustmentsForDateRange,
} from './data';

function mapAdjustments(adjustments, vehicle, adjLength) {
  const output = {};
  for (let i = 0; i < adjLength; ++i) {
    if (i < adjustments.length) {
      output[`TRIP${i + 1}_Adjustment ID`] = adjustments[i].id;
      output[`TRIP${i + 1}_Adjustment Date`] = simpleDate(adjustments[i].createdAt);
      output[`TRIP${i + 1}_Adjustment Code`] = ''; // NOTE: There is no adjustment codes
      output[`TRIP${i + 1}_Adjustment Reason`] = adjustments[i].note;
      output[`TRIP${i + 1}_Adjustment_Total Mileage`] = convertMileage(adjustments[i].adjMileage, 'mi');
      output[`TRIP${i + 1}_Adjustment_Total RUC`] = convertCentsToDollars(adjustments[i].adjMileageFeeCents);
      output[`TRIP${i + 1}_Adjustment_Total Fuel Usage`] = convertFuel(adjustments[i].adjFuel, 'gal');
      output[`TRIP${i + 1}_Adjustment_Total Fuel Tax Credit`] = convertCentsToDollars(adjustments[i].adjFuelFeeCents);
      output[`TRIP${i + 1}_Adjustment_Total Net RUC Revenue`] = convertCentsToDollars(adjustments[i].adjMileageFeeCents - adjustments[i].adjFuelFeeCents);
    } else {
      output[`TRIP${i + 1}_Adjustment ID`] = '';
      output[`TRIP${i + 1}_Adjustment Date`] = '';
      output[`TRIP${i + 1}_Adjustment Code`] = ''; // NOTE: There is no adjustment codes
      output[`TRIP${i + 1}_Adjustment Reason`] = '';
      output[`TRIP${i + 1}_Adjustment_Total Mileage`] = '';
      output[`TRIP${i + 1}_Adjustment_Total RUC`] = '';
      output[`TRIP${i + 1}_Adjustment_Total Fuel Usage`] = '';
      output[`TRIP${i + 1}_Adjustment_Total Fuel Tax Credit`] = '';
      output[`TRIP${i + 1}_Adjustment_Total Net RUC Revenue`] = '';
    }
  }

  return output;
}

export const retrieveTripData = async (fromDate, toDate) => {
  const [
    participants,
    allVehicles,
    allTransactions,
    allStatements,
    allTrips,
    tripSegments,
    tripAdjustments,
  ] = await Promise.all([
    getParticipants(),
    getAllVehicles(),
    getAllTransactions(),
    getAllSystemStatements(),

    listAllTripsForDateRange(getTripsWithTransactionsByProcessStatusByCreatedAt, fromDate, toDate),
    listAllTripSegmentsForDateRange(getTripSegmentsByPaymentStatusByCreatedAt, fromDate, toDate),
    listAllTripAdjustmentsForDateRange(getTripAdjustmentsByPaymentStatusByCreatedAt, fromDate, toDate),
  ]);

  const month = moment(fromDate).add(1, 'days').format('YYYY-MM');

  const statementsByUsername = {};
  allStatements
    .filter((s) => s.month === month)
    .forEach((statement) => {
      statementsByUsername[statement.username] = statementsByUsername[statement.username] || [];
      statementsByUsername[statement.username].push(statement);
    });

  const tripMappings = {};
  tripSegments.forEach((segment) => {
    const { tripId, vehicleId, transactionId, username } = segment;
    const participant = participants.find((p) => p.username === username);

    if (!participant) return;

    tripMappings[tripId] = tripMappings[tripId] || {
      tripId,
      segments: [],
      adjustments: [],
      vehicle: undefined,
      transaction: undefined,
      statement: undefined,
      participant: undefined,
    };

    tripMappings[tripId].segments.push(segment);

    if (transactionId) {
      const transaction = allTransactions.find(({ id }) => id === transactionId);

      tripMappings[tripId].transaction = transaction;

      tripMappings[tripId].statement = (statementsByUsername[username] || []).find(({ id }) => {
        return id === transaction.statementId;
      });
    }

    // fill statement for private segments
    if (!tripMappings[tripId].statement && statementsByUsername[username] && statementsByUsername[username].length > 0) {
      tripMappings[tripId].statement = statementsByUsername[username][0];
    }

    tripMappings[tripId].participant = participant;
    tripMappings[tripId].vehicle = allVehicles.find(({ id }) => id === vehicleId) || {};
  });

  tripAdjustments.forEach((adjustment) => {
    const { tripId, vehicleId, transactionId, username } = adjustment;
    const participant = participants.find((p) => p.username === username);

    if (!participant) return;

    tripMappings[tripId] = tripMappings[tripId] || {
      tripId,
      segments: [],
      adjustments: [],
      vehicle: undefined,
      transaction: undefined,
      statement: undefined,
      participant: undefined,
    };

    tripMappings[tripId].adjustments.push(adjustment);

    if (transactionId) {
      const transaction = allTransactions.find(({ id }) => id === transactionId);

      tripMappings[tripId].transaction = transaction;

      tripMappings[tripId].statement = statementsByUsername[username].find(({ id }) => {
        return id === transaction.statementId;
      });
    }

    // fill statement for private segments
    if (!tripMappings[tripId].statement && statementsByUsername[username].length > 0) {
      tripMappings[tripId].statement = statementsByUsername[username][0];
    }

    tripMappings[tripId].participant = participant;
    tripMappings[tripId].vehicle = allVehicles.find(({ id }) => id === vehicleId) || {};
  });

  // get the maximum length of the adjustments
  let adjLength = 0;
  Object.values(tripMappings).forEach(({ adjustments }) => {
    if (adjustments.length > adjLength) {
      adjLength = adjustments.length;
    }
  });

  const limit = pLimit(20);

  const tripData = [];
  const promises = Object.values(tripMappings).map((tripMappingData) => {
    return limit(async () => {
      const {
        tripId,
        participant,
        vehicle,
        transaction,
        statement,
        segments,
        adjustments,
      } = tripMappingData;

      const tripInCurrentPeriod = allTrips.find(({ id }) => id === tripId);

      const trip = tripInCurrentPeriod || await getTrip(tripId);

      const {
        tsStart,
        tsEnd,
        fuel,
        fuelFeeCents,
        mileageFeeCents,
        mroDeviceSerialNumber,
        mroId,
        logs,
      } = trip;

      const segmentTotals = {
        totalMileage: segments.reduce((acc, { mileage, type }) => {
          const total = type === 'public' || type === 'private';
          return acc + (total ? mileage : 0);
        }, 0),
        chargeableMileage: segments.reduce((acc, { mileage, type, stateCode }) => {
          const chargeable = type === 'public' && stateCode === 'CA';
          return acc + (chargeable ? mileage : 0);
        }, 0),
        nonChargeableMileage: segments.reduce((acc, { mileage, type, stateCode }) => {
          const nonChargeable = (type === 'private' && stateCode === 'CA') || (stateCode !== 'CA' && (type === 'private' || type === 'public'));
          return acc + (nonChargeable ? mileage : 0);
        }, 0),
        nonChargeableMileageCA: segments.reduce((acc, { mileage, type, stateCode }) => {
          const nonChargeable = type === 'private' && stateCode === 'CA';
          return acc + (nonChargeable ? mileage : 0);
        }, 0),
      };

      const data = {
        tripId,
        participant,
        vehicle,
        transaction,
        statement,
        segments,
        adjustments,

        // trip
        tsStart,
        tsEnd,
        fuel,
        fuelFeeCents,
        mileageFeeCents,
        mroDeviceSerialNumber,
        mroId,
        logs,

        segmentTotals,
      };

      // RUC-1254 Do not include trip ruc charges and fuel info for out of range.
      const isTripInCurrentPeriod = tripInCurrentPeriod ? true : false;
      if (!isTripInCurrentPeriod) {
        data.fuel = 0;
        data.fuelFeeCents = 0;
        data.mileageFeeCents = 0;
      }

      tripData.push(data);
    });
  });

  await Promise.all(promises);

  // // calculate issue accounts (dev use only)
  // const statementMappings = {};

  // tripSegments.forEach((segment) => {
  //   const { id: segmentId, transactionId, username, type, stateCode, mileage } = segment;

  //   if (type === 'public' && stateCode === 'CA') {
  //     if (transactionId) {
  //       const transaction = allTransactions.find(({ id }) => id === transactionId);

  //       const statement = statementsByUsername[username].find(({ id }) => {
  //         return id === transaction.statementId;
  //       });

  //       statementMappings[statement.id] = statementMappings[statement.id] || {
  //         statement,
  //         totalMileage: 0,
  //         segments: [],
  //       };

  //       statementMappings[statement.id].totalMileage += mileage;

  //       statementMappings[statement.id].segments.push(segment);
  //     } else {
  //       console.error('Segment has no transaction id', segmentId);
  //     }
  //   }
  // });

  // const issues = [];
  // Object.values(statementMappings).forEach(({ statement, totalMileage }) => {
  //   const statementMileage = statement.mileage;
  //   const segmentTotalMileage = convertMileage(totalMileage, 'mi');

  //   if (Math.abs(statementMileage - segmentTotalMileage) > 1) {
  //     const participant = participants.find((p) => p.username === statement.username);

  //     issues.push([
  //       statement.username, participant.accountNo, statementMileage, segmentTotalMileage,
  //     ].join(','));
  //   }
  // });

  // console.log(issues.join('\n'));

  return {
    adjLength,
    tripData,
  };
};

export default {
  name: 'BILLING-PAYMENT AUDIT',
  useDateRange: true,
  async process(fromDate, toDate) {
    const { adjLength, tripData } = await retrieveTripData(fromDate, toDate);

    const data = tripData.map((trip) => {
      const {
        tripId,
        participant,
        vehicle,
        transaction,
        statement,
        // segments,
        adjustments,
        tsStart,
        tsEnd,
        fuel,
        fuelFeeCents,
        mileageFeeCents,
        segmentTotals,
      } = trip;

      return Object.assign({
        'RecordID': tripId,
        'AccountID': participant.accountNo,
        'Last Name': participant.lastName,
        'First Name': participant.firstName,
        'Email Address': participant.email,
        'VIN': vehicle?.vin || '',
        'TRIP_ID': tripId,
        'TRIP_Start Date': simpleDateTime(tsStart),
        'TRIP_End Date': simpleDateTime(tsEnd),
        'TRIP_Total Mileage': convertMileage(segmentTotals.totalMileage, 'mi'),
        'TRIP_Total RUC': convertCentsToDollars(mileageFeeCents),
        'TRIP_Total Fuel Usage': convertFuel(fuel, 'gal'),
        'TRIP_Total Fuel Tax Credit': vehicle.type === 'electric' ?
          convertCentsToDollars(0) :
          convertCentsToDollars(fuelFeeCents),
        'TRIP_Net RUC Revenue': vehicle.type === 'electric' ?
          convertCentsToDollars(mileageFeeCents - 0) :
          convertCentsToDollars(mileageFeeCents - fuelFeeCents),
      }, mapAdjustments(adjustments, vehicle, adjLength), {
        // Date the TRIP(X)_Adjustment_Total RUC is paid
        // This may not be correct if the adjustment is made in the following month
        'TRIP_Paid Date': (transaction) ? simpleDate(statement.paidAt) : '',
        'STMT_ID': (statement) ? statement.id : '',
        'Stmt Month': (statement) ? statement.month : '',
        'Stmt Issue Date': (statement) ? simpleDate(statement.createdAt) : '',
        'TRANSACTION_ID': (transaction) ? transaction.id : '',
        // Date of the transaction that the TRIP_ID was paid under
        'TRANSACTION_Date': (transaction) ? simpleDate(statement.paidAt) : '',
      });
    });

    return data.filter((x) => x);
  },
};
