import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';

import { asyncListAll, asyncListFirstItem } from 'utilities/graph';
import {
  getEventsByUserByTimestamp,
  listVehicles,
  listMileageReports,
  listPaymentCards,
  listMileageReportingOptions,
} from 'graphql/queries';

import EventTable from 'pages/Admin/components/EventTable';

const tableNameOptions = [
  { name: 'All' },
  { name: 'Participant' },
  { name: 'MileageReport', listFunc: listMileageReports },
  { name: 'MileageReportingOption', listFunc: listMileageReportingOptions },
  { name: 'PaymentCard', listFunc: listPaymentCards },
  { name: 'Vehicle', listFunc: listVehicles },
];

let tableSuffix;

export default function AuditTrail({
  username,
  viewer = 'admin',
}) {
  const [selectedTableName, setSelectedTableName] = useState('Participant');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState();

  useEffect(() => {
    if (!selectedTableName || !dateRange) return;

    (async () => {
      setIsLoading(true);

      const { from, to } = dateRange;

      if (!tableSuffix) {
        const item = await asyncListFirstItem(getEventsByUserByTimestamp, { username });

        // No event at all
        if (!item) {
          setIsLoading(false);
          return;
        }

        const [, hash, env] = item.key.split('__')[0].split('-');
        tableSuffix = `${hash}-${env}`;
      }

      if (selectedTableName === 'All') {
        const data = await asyncListAll( /* GraphQL */ `
          query GetEventsByUserByTimestamp(
            $username: String
            $timestamp: ModelStringKeyConditionInput
            $sortDirection: ModelSortDirection
            $filter: ModelEventFilterInput
            $limit: Int
            $nextToken: String
          ) {
            getEventsByUserByTimestamp(
              username: $username
              timestamp: $timestamp
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
            ) {
              items {
                key
                timestamp
                eventId
                eventName
                diff {
                  key
                  old
                  new
                }
                note
                username
                updatedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `, {
          username,
          timestamp: {
            between: [from, to],
          },
        });

        setData(data);
        setIsLoading(false);

        return;
      }

      const keys = [];

      if (selectedTableName === 'Participant') {
        keys.push(`Participant-${tableSuffix}__${username}__undefined`);
      } else {
        const matched = tableNameOptions.find(({ name }) => name === selectedTableName);

        const { listFunc, cacheItems } = matched;

        const items = cacheItems || await asyncListAll(listFunc, { username });
        items.forEach(({ id }) => {
          keys.push(`${selectedTableName}-${tableSuffix}__${username}__${id}`);
        });

        matched.cacheItems = items;
      }

      let events = [];
      await Promise.all(keys.map(async (key) => {
        const data = await asyncListAll( /* GraphQL */ `
          query ListEvents(
            $key: String
            $timestamp: ModelStringKeyConditionInput
            $filter: ModelEventFilterInput
            $limit: Int
            $nextToken: String
            $sortDirection: ModelSortDirection
          ) {
            listEvents(
              key: $key
              timestamp: $timestamp
              filter: $filter
              limit: $limit
              nextToken: $nextToken
              sortDirection: $sortDirection
            ) {
              items {
                key
                timestamp
                eventId
                eventName
                diff {
                  key
                  old
                  new
                }
                note
                username
                updatedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `, {
          key,
          timestamp: {
            between: [from, to],
          },
        });

        events = [...events, ...data];
      }));

      setData(events);
      setIsLoading(false);
    })();
  }, [selectedTableName, username, dateRange]);

  return (<React.Fragment>
    <DateRangeSelector
      unit='day'
      onSubmit={({ from, to }) => {
        setDateRange({ from, to });
      }}
      submitOnLoad={true}
      disabled={isLoading}
    />
    <Grid container item xs={12} md={4} alignItems="center" justify="left" style={{ paddingBottom: 16 }}>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        {
          tableNameOptions.map(({ name: tableName }) => (
            <Button
              key={tableName}
              variant={selectedTableName === tableName ? 'contained' : 'outlined'}
              disabled={isLoading}
              onClick={() => {
                setSelectedTableName(tableName);
              }}>
              {tableName}
            </Button>
          ))
        }
      </ButtonGroup>
    </Grid>
    <EventTable data={data} viewer={viewer} />
  </React.Fragment>);
}


AuditTrail.propTypes = {
  username: PropTypes.string,
  viewer: PropTypes.string,
};
