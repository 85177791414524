import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import {
  getParticipant,
  listParticipants,
  getTripsByUsernameByCreatedAt,
  getTripSegmentsByUsernameByCreatedAt,
  getTripAdjustmnentsByParticipantByCreatedAt,
  getParticipantStatementsByParticipantByMonth,
  getTransactionsByUsernameByCreatedAt,
} from 'graphql/queries';
import {
  updateTrip,
  deleteTripSegment,
  deleteTripAdjustment,
  deleteTransaction,
  deleteParticipantStatement,
} from 'graphql/mutations';
import { sortBy } from 'utilities/sorting';
import { asyncRetryMutation, asyncGet, asyncListAll } from 'utilities/graph';
import MultiSelect from 'components/MultiSelect';

const updateParticipantProps = (participant) => {
  participant.fullName = `${participant.firstName} ${participant.lastName}`;
  participant.displayName = `${participant.fullName} (${participant.email})`;

  return participant;
};

export default function TestingTools({ username }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  useEffect(() => {
    (async () => {
      setIsSubmitting(true);

      if (username) {
        const { data: { getParticipant: data } } = await asyncGet(getParticipant, { username }, { bypassCache: true });

        const participant = updateParticipantProps(data);
        setSelectedParticipants([participant]);
        setParticipants([participant]);
      } else {
        const allParticipants = (await asyncListAll(listParticipants))
          .filter(({ participantPilotProgramId }) => participantPilotProgramId)
          .sort(sortBy('lastName'))
          .sort(sortBy('firstName'))
          .map(updateParticipantProps);

        setParticipants(allParticipants);
      }

      setIsSubmitting(false);
    })();
  }, [username]);

  // TODO: selet date/month range or select for trips
  const resetAccountTrips = async () => {
    if (!window.confirm(`Do you really want to reset all the trips for thes accounts?`)) {
      return;
    }

    try {
      setIsSubmitting(true);

      console.log(selectedParticipants);
      await Promise.all(selectedParticipants.map(async ({ username }) => {
        const [
          trips,
          tripSegments,
          tripAdjustments,
          transactions,
          statements,
        ] = await Promise.all([
          asyncListAll(getTripsByUsernameByCreatedAt, { username }),
          asyncListAll(getTripSegmentsByUsernameByCreatedAt, { username }),
          asyncListAll(getTripAdjustmnentsByParticipantByCreatedAt, { username }),
          asyncListAll(getTransactionsByUsernameByCreatedAt, { username }),
          asyncListAll(getParticipantStatementsByParticipantByMonth, { username }),
        ]);

        console.log({
          username,
          trips,
          tripSegments,
          tripAdjustments,
          transactions,
          statements,
        });

        await Promise.all([
          Promise.all(tripSegments.map(({ tripId, id }) => asyncRetryMutation(deleteTripSegment, { input: { tripId, id } }))),
          Promise.all(tripAdjustments.map(({ tripId, id }) => asyncRetryMutation(deleteTripAdjustment, { input: { tripId, id } }))),
          Promise.all(transactions.map(({ id }) => asyncRetryMutation(deleteTransaction, { input: { id } }))),
          Promise.all(statements.map(({ id }) => asyncRetryMutation(deleteParticipantStatement, { input: { id } }))),
        ]);

        await Promise.all(trips.map(({ id }) => {
          const input = {
            id,
            processStatus: 'pending',
            logs: [],
          };
          return asyncRetryMutation(updateTrip, { input }, { clearCacheKeys: ['ListTrips'] });
        }));
      }));
    } catch (e) {
      global.logger.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Testing Tool
          </Typography>
          <MultiSelect
            label="Participants"
            options={participants}
            optionsLabelKey="displayName"
            value={selectedParticipants}
            onChange={(e) => setSelectedParticipants(e.target.value)}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid container item xs={12} alignItems="center" justify="center" spacing={2}>
          <Grid item xs={12} align="center">
            <Alert severity="error">
              Note: This process deletes all the trip segments, adjustments, transactions and statements
            </Alert>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={resetAccountTrips}
            >
              Reset Account Trips to pending
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}


TestingTools.propTypes = {
  username: PropTypes.string,
};
