import Smartcar from '@smartcar/auth';

export const connect = async (scopeByVehicleClassification, additionalOptions) => {
  return new Promise((resolve, reject) => {
    const smartcar = new Smartcar({
      clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
      redirectUri: process.env.REACT_APP_SMARTCAR_OAUTH_REDIRECT_URL,
      scope: scopeByVehicleClassification.split(','),
      testMode: process.env.REACT_APP_SMARTCAR_TEST_MODE === 'true' ? true : false,
      onComplete: (err, code) => {
        return (err) ? reject(err) : resolve(code);
      },
    });

    smartcar.openDialog({
      forcePrompt: true,
      singleSelect: true,
      ...additionalOptions,
    });
  });
};

export const rucScope = process.env.REACT_APP_SMARTCAR_AUTHORIZATION_SCOPE;

// https://smartcar.com/product/compatible-vehicles/
export const vehicleIsSmartcarCompatible = (make, year, model) => {
  /* eslint-disable */
  switch (make) {
    case 'ACURA':
      switch (model) {
        case 'TLX':
          return year >= 2014;
        case 'RLX':
          return year >= 2017 && year <= 2020;
        case 'ILX':
          return year >= 2018 && year <= 2022;
        case 'MDX':
          return year >= 2018;
        case 'NSX':
          return year >= 2018 && year <= 2022;
        case 'RDX':
          return year >= 2018;
        case 'INTEGRA':
          return year >= 2023;
        default:
          return year >= 2014;
      }
    case 'AUDI':
      switch (model) {
        case 'A4':
          return year >= 2017;
        case 'ALLROAD':
          return year >= 2017 && year <= 2021;
        case 'S4':
          return year >= 2017;
        case 'SQ7':
          return year >= 2017;
        case 'A5':
          return year >= 2018;
        case 'S5':
          return year >= 2018;
        case 'SQ5':
          return year >= 2018;
        case 'A3':
          return year >= 2019;
        case 'A6':
          return year >= 2019;
        case 'A7':
          return year >= 2019;
        case 'A8':
          return year >= 2019;
        case 'TRON':
          return year >= 2019;
        case 'Q3':
          return year >= 2019;
        case 'Q5':
          return year >= 2019;
        case 'Q7':
          return year >= 2019;
        case 'Q8':
          return year >= 2019;
        case 'RS5':
          return year >= 2019;
        case 'RS7':
          return year >= 2019;
        case 'S6':
          return year >= 2019;
        case 'S7':
          return year >= 2019;
        case 'S8':
          return year >= 2019;
        case 'A8 PHEV':
          return year >= 2020;
        case 'Q5 PHEV':
          return year >= 2020;
        case 'A7 PHEV':
          return year >= 2021;
        case 'TT':
          return year >= 2021;
        case 'A5 PHEV':
          return year >= 2022;
        case 'TRON':
          return year >= 2022;
        case 'R8':
          return year >= 2023;
        case 'SQ8':
          return year >= 2023;
        case 'TTS':
          return year >= 2023;
        default:
          return year >= 2017;
      }
    case 'BMW':
      switch (model) {
        case '2 SERIES':
          return year >= 2016;
        case '3 SERIES':
          return year >= 2016;
        case '3 SERIES PHEV':
          return year >= 2016;
        case '4 SERIES':
          return year >= 2016;
        case '5 SERIES':
          return year >= 2016;
        case '6 SERIES':
          return year >= 2016 && year <= 2019;
        case '7 SERIES':
          return year >= 2016;
        case 'I3':
          return year >= 2016 && year <= 2021;
        case 'I3 REX':
          return year >= 2016 && year <= 2021;
        case 'I8':
          return year >= 2016 && year <= 2020;
        case 'M2':
          return year >= 2016;
        case 'M3':
          return year >= 2016;
        case 'M4':
          return year >= 2016;
        case 'M6':
          return year >= 2016 && year <= 2019;
        case 'X1':
          return year >= 2016;
        case 'X3':
          return year >= 2016;
        case 'X4':
          return year >= 2016;
        case 'X5':
          return year >= 2016;
        case 'X5 PHEV':
          return year >= 2016;
        case 'X6':
          return year >= 2016;
        case 'Z4':
          return year >= 2016;
        case '5 SERIES PHEV':
          return year >= 2017;
        case '7 SERIES PHEV':
          return year >= 2017;
        case 'X5 M':
          return year >= 2017;
        case 'X6 M':
          return year >= 2017;
        case '8 SERIES':
          return year >= 2018;
        case 'M5':
          return year >= 2018;
        case 'X2':
          return year >= 2018;
        case 'X7':
          return year >= 2019;
        case 'M8':
          return year >= 2020;
        case 'X3 PHEV':
          return year >= 2020 && year <= 2021;
        case 'X3 M':
          return year >= 2020;
        case 'X4 M':
          return year >= 2020;
        case 'I4':
          return year >= 2021;
        case 'IX':
          return year >= 2021;
        case 'I7':
          return year >= 2023;
        case 'I5':
          return year >= 2024;
        default:
          return year >= 2016;
      }
    case 'BUICK':
      switch (model) {
        case 'VERANO':
          return year >= 2014 && year <= 2017;
        case 'ENCLAVE':
          return year >= 2015;
        case 'ENCORE':
          return year >= 2015;
        case 'LACROSSE':
          return year >= 2015 && year <= 2019;
        case 'REGAL':
          return year >= 2015 && year <= 2017;
        case 'CASCADA':
          return year >= 2016 && year <= 2019;
        case 'ENVISION':
          return year >= 2016;
        case 'REGAL SPORTBACK':
          return year >= 2018 && year <= 2020;
        case 'REGAL TOURX':
          return year >= 2018 && year <= 2020;
        case 'ENCORE GX':
          return year >= 2020;
        case 'ENCORE GX ST':
          return year >= 2020;
        default:
          return year >= 2014;
      }
    case 'CADILLAC':
      switch (model) {
        case 'ATS':
          return year >= 2015 && year <= 2019;
        case 'CTS':
          return year >= 2015 && year <= 2019;
        case 'V':
          return year >= 2015 && year <= 2019;
        case 'ELR':
          return year >= 2015 && year <= 2016;
        case 'ESCALADE':
          return year >= 2015 && year <= 2022;
        case 'SRX':
          return year >= 2015 && year <= 2016;
        case 'XTS':
          return year >= 2015 && year <= 2019;
        case 'CT6':
          return year >= 2016 && year <= 2019;
        case 'CT6 PHEV':
          return year >= 2017 && year <= 2018;
        case 'XT5':
          return year >= 2017 && year <= 2019;
        case 'XT4':
          return year >= 2019 && year <= 2019;
        case 'CT 4':
          return year >= 2020;
        case 'CT 4 V':
          return year >= 2020;
        case 'CT 5':
          return year >= 2020;
        case 'CT 5 V':
          return year >= 2020;
        case 'CT 6':
          return year >= 2020 && year <= 2020;
        case 'XT 4':
          return year >= 2020;
        case 'XT 5':
          return year >= 2020;
        case 'XT 6':
          return year >= 2020;
        case 'LYRIQ':
          return year >= 2023;
        default:
          return year >= 2015;
      }
    case 'CHEVROLET':
      switch (model) {
        case 'CAMARO':
          return year >= 2015;
        case 'COLORADO':
          return year >= 2015;
        case 'CORVETTE':
          return year >= 2015;
        case 'CRUZE':
          return year >= 2015 && year <= 2019;
        case 'EQUINOX':
          return year >= 2015;
        case 'EXPRESS':
          return year >= 2015;
        case 'IMPALA':
          return year >= 2015 && year <= 2020;
        case 'MAILBU':
          return year >= 2015;
        case 'SILVERADO':
          return year >= 2015;
        case 'SILVERADO HD':
          return year >= 2015;
        case 'SONIC':
          return year >= 2015 && year <= 2021;
        case 'SPARK':
          return year >= 2015;
        case 'SPARK EV':
          return year >= 2015 && year <= 2016;
        case 'SS':
          return year >= 2015 && year <= 2017;
        case 'SUBURBAN':
          return year >= 2015;
        case 'TAHOE':
          return year >= 2015;
        case 'TRAVERSE':
          return year >= 2015;
        case 'TRAX':
          return year >= 2015;
        case 'VOLT':
          return year >= 2015 && year <= 2019;
        case 'CRUZE LIMITED':
          return year >= 2016 && year <= 2016;
        case 'BOLT EV':
          return year >= 2017;
        case 'BLAZER':
          return year >= 2019;
        case 'TRAILBLAZER':
          return year >= 2021;
        case 'BOLT EUV':
          return year >= 2022;
        case 'BLAZER EV':
          return year >= 2024;
        default:
          return year >= 2015;
      }
    case 'CHRYSLER':
      switch (model) {
        case '200':
          return year >= 2015 && year <= 2017;
        case 'PACIFICA HYBRID':
          return year >= 2017;
        case ' COUNTRY':
          return year >= 2017 && year <= 2017;
        case 'PACIFICA':
          return year >= 2018;
        case '300':
          return year >= 2019;
        default:
          return year >= 2015;
      }
    case 'DODGE':
      switch (model) {
        case 'DURANGO':
          return year >= 2015;
        case 'CHALLENGER':
          return year >= 2017;
        case 'CHARGER':
          return year >= 2017;
        case 'HORNET PHEV':
          return year >= 2023;
        case 'HORNET':
          return year >= 2023;
        default:
          return year >= 2015;
      }
    case 'FORD':
      switch (model) {
        case 'C MAX ENERGI':
          return year >= 2016 && year <= 2017;
        case 'FUSION ENERGI':
          return year >= 2016 && year <= 2019;
        case 'ESCAPE':
          return year >= 2017;
        case 'F 150':
          return year >= 2017;
        case 'FUSION HYBRID':
          return year >= 2017 && year <= 2020;
        case 'ECO SPORT':
          return year >= 2018;
        case 'EXPEDITION':
          return year >= 2018;
        case 'EXPLORER':
          return year >= 2018;
        case 'MUSTANG':
          return year >= 2018;
        case 'RANGER':
          return year >= 2019;
        case 'SUPER DUTY':
          return year >= 2019;
        case 'TRANSIT CONNECT':
          return year >= 2019;
        case 'FUSION PLUG IN HYBRID TITANIUM':
          return year >= 2020 && year <= 2020;
        case 'BRONCO':
          return year >= 2021;
        case 'BRONCO SPORT':
          return year >= 2021;
        case 'ESCAPE SE PLUG IN HYBRID':
          return year >= 2021;
        case 'MACH E':
          return year >= 2021;
        case 'E TRANSIT':
          return year >= 2022;
        case 'F 150 LIGHTNING':
          return year >= 2022;
        case 'MAVERICK':
          return year >= 2022;
        default:
          return year >= 2016;
      }
    case 'GMC':
      switch (model) {
        case 'ACADIA':
          return year >= 2015;
        case 'CANYON':
          return year >= 2015;
        case 'SAVANA':
          return year >= 2015;
        case 'SIERRA':
          return year >= 2015;
        case 'SIERRA HEAVY DUTY':
          return year >= 2015;
        case 'TERRAIN':
          return year >= 2015;
        case 'YUKON':
          return year >= 2015;
        case 'YUXON XL':
          return year >= 2016;
        default:
          return year >= 2015;
      }
    case 'HYUNDAI':
      switch (model) {
        case 'AZERA':
          return year >= 2016;
        case 'GENESIS':
          return year >= 2016 && year <= 2016;
        case 'GENESIS COUPE':
          return year >= 2016 && year <= 2016;
        case 'SONATA':
          return year >= 2016;
        case 'TUCSON':
          return year >= 2016;
        case 'ELANTRA':
          return year >= 2017;
        case 'ELANTRA COUPE':
          return year >= 2017;
        case 'ELANTRA GT':
          return year >= 2017;
        case 'IONIQ ELECTRIC':
          return year >= 2017 && year <= 2021;
        case 'IONIQ HYBRID':
          return year >= 2017;
        case 'SANTA FE':
          return year >= 2017;
        case 'SANTA FE SPORT':
          return year >= 2017;
        case 'SONATA HYBRID':
          return year >= 2017;
        case 'SONATA PLUG-IN HYBRID':
          return year >= 2017 && year <= 2019;
        case 'VELOSTER':
          return year >= 2017;
        case 'ACCENT':
          return year >= 2018;
        case 'IONIQ PLUG-IN HYBRID':
          return year >= 2018;
        case 'KONA ELECTRIC':
          return year >= 2019;
        case 'NEXO':
          return year >= 2019 && year <= 2021;
        case 'SANTA FE XL':
          return year >= 2019 && year <= 2020;
        case 'PALISADE':
          return year >= 2020;
        case 'VENUE':
          return year >= 2020;
        case 'IONIQ 5':
          return year >= 2022;
        case 'SANTA FE PLUG-IN HYBRID':
          return year >= 2022;
        case 'TUCSON PHEV':
          return year >= 2022;
        case 'IONIQ 6':
          return year >= 2023;
        default:
          return year >= 2016;
      }
    case 'INFINITI':
      switch (model) {
        case 'QX50':
          return year >= 2018;
        case 'QX60':
          return year >= 2018;
        case 'QX80':
          return year >= 2018;
        case 'Q60':
          return year >= 2019 && year <= 2022;
        case 'Q70':
          return year >= 2019 && year <= 2019;
        case 'Q50':
          return year >= 2022;
        default:
          return year >= 2018;
      }
    case 'JAGUAR':
      switch (model) {
        case 'F TYPE':
          return year >= 2016;
        case 'XF':
          return year >= 2016;
        case 'XJ':
          return year >= 2016 && year <= 2019;
        case 'F PACE':
          return year >= 2017;
        case 'XE':
          return year >= 2017 && year <= 2020;
        case 'E PACE':
          return year >= 2018;
        case 'I PACE':
          return year >= 2019;
        default:
          return year >= 2016;
      }
    case 'JEEP':
      switch (model) {
        case 'GRAND CHEROKEE':
          return year >= 2015;
        case 'COMPASS':
          return year >= 2018;
        case 'RENEGADE':
          return year >= 2018;
        case 'WRANGLER UNLIMITED':
          return year >= 2018 && year <= 2020;
        case 'CHEROKEE':
          return year >= 2019;
        case 'GLADIATOR':
          return year >= 2020;
        case 'WRANGLER 4XE':
          return year >= 2021;
        case 'GRAND CHEROKEE 4XE':
          return year >= 2022;
        default:
          return year >= 2015;
      }
    case 'KIA':
      switch (model) {
        case 'K 900':
          return year >= 2016 && year <= 2019;
        case 'OPTIMA PLUG IN HYBRID':
          return year >= 2017 && year <= 2020;
        case 'SOUL EV':
          return year >= 2017 && year <= 2020;
        case 'NIRO PLUG IN HYBRID':
          return year >= 2018;
        case 'NIRO EV':
          return year >= 2019;
        case 'OPTIMA':
          return year >= 2019 && year <= 2020;
        case 'CADENZA':
          return year >= 2020;
        case 'FORTE EX':
          return year >= 2020;
        case 'OPTIMA HYBRID':
          return year >= 2020;
        case 'SORENTO':
          return year >= 2020;
        case 'SOUL':
          return year >= 2020;
        case 'SPORTAGE':
          return year >= 2020;
        case 'TELLURIDE':
          return year >= 2020;
        case 'SELTOS SX TURBO':
          return year >= 2021;
        case 'SORENTO HYRBID':
          return year >= 2021;
        case 'EV6':
          return year >= 2022;
        case 'SORENTO PHEV':
          return year >= 2022;
        case 'SPORTAGE PLUG IN HYBRID':
          return year >= 2023;
        case 'EV9':
          return year >= 2024;
        default:
          return year >= 2016;
      }
    case 'LAND ROVER':
      switch (model) {
        case 'DISCOVERY SPORT':
          return year >= 2016;
        case 'LR 4':
          return year >= 2016 && year <= 2016;
        case 'RANGE ROVER':
          return year >= 2016;
        case 'RANGE ROVER EVOQUE':
          return year >= 2016;
        case 'RANGE ROVER SPORT':
          return year >= 2016;
        case 'DISCOVERY':
          return year >= 2017;
        case 'RANGE ROVER VELAR':
          return year >= 2018;
        case 'RANGE ROVER P 400 E':
          return year >= 2019 && year <= 2021;
        case 'RANGE ROVER SPORT P 400 E':
          return year >= 2019 && year <= 2021;
        case 'DEFENDER':
          return year >= 2020;
        case 'THE NEW RANGE ROVER':
          return year >= 2022 && year <= 2022;
        default:
          return year >= 2016;
      }
    case 'LEXUS':
      switch (model) {
        case 'RC':
          return year >= 2015;
        case 'ES':
          return year >= 2016;
        case 'GS':
          return year >= 2016 && year <= 2020;
        case 'IS':
          return year >= 2016;
        case 'LS':
          return year >= 2016;
        case 'RX':
          return year >= 2016;
        case 'LC':
          return year >= 2018;
        case 'LX':
          return year >= 2018;
        case 'NX':
          return year >= 2018;
        case 'NX WITH NAVIGATION':
          return year >= 2018;
        case 'GX':
          return year >= 2019;
        case 'UX':
          return year >= 2019;
        case 'RX 450 H PHEV':
          return year >= 2023;
        case 'RZ':
          return year >= 2023;
        default:
          return year >= 2015;
      }
    case 'LINCOLN':
      switch (model) {
        case 'MKC':
          return year >= 2015 && year <= 2019;
        case 'MKX':
          return year >= 2015 && year <= 2018;
        case 'MKZ':
          return year >= 2015 && year <= 2020;
        case 'CONTINENTAL':
          return year >= 2017 && year <= 2020;
        case 'MKT':
          return year >= 2018 && year <= 2019;
        case 'MKZ HYBRID':
          return year >= 2018 && year <= 2020;
        case 'NAVIGATOR':
          return year >= 2018;
        case 'NAUTILUS':
          return year >= 2019;
        case 'NAVIGATOR L':
          return year >= 2019;
        case 'AVIATOR':
          return year >= 2020;
        case 'AVIATOR GRAND TOURING':
          return year >= 2020;
        case 'CORSAIR':
          return year >= 2020;
        case 'CORSAIR GRAND TOURING':
          return year >= 2021;
        default:
          return year >= 2015;
      }
    case 'MAZDA':
      switch (model) {
        case 'MAZDA3':
          return year >= 2019;
        case 'CX-30':
          return year >= 2020;
        case 'CX-5':
          return year >= 2021;
        case 'CX-9':
          return year >= 2021;
        case 'MX-30':
          return year >= 2022;
        case 'CX-5':
          return year >= 2023;
        default:
          return year >= 2019;
      }
    case 'MERCEDES-BENZ':
      switch (model) {
        case 'C-CLASS':
          return year >= 2018;
        case 'CLASS':
          return year >= 2019;
        case 'AMG GT':
          return year >= 2019;
        case 'CLA':
          return year >= 2019;
        case 'CLS':
          return year >= 2019;
        case 'E-CLASS':
          return year >= 2019;
        case 'G-CLASS':
          return year >= 2019;
        case 'GLA':
          return year >= 2019;
        case 'GLC':
          return year >= 2019;
        case 'GLC PLUG-IN HYBRID':
          return year >= 2019 && year <= 2020;
        case 'GLS':
          return year >= 2019;
        case 'S-CLASS':
          return year >= 2019;
        case 'S-CLASS PLUG-IN HYBRID':
          return year >= 2019;
        case 'SPRINTER VAN':
          return year >= 2019;
        case 'GLB':
          return year >= 2020;
        case 'GLE':
          return year >= 2020;
        case 'METRIS':
          return year >= 2020;
        case 'EQ SERIES':
          return year >= 2022;
        default:
          return year >= 2018;
      }
    case 'MINI':
      switch (model) {
        case 'CLUBMAN':
          return year >= 2018;
        case 'CONVERTIBLE':
          return year >= 2018;
        case 'COUNTRYMAN':
          return year >= 2018;
        case 'HARDTOP':
          return year >= 2018;
        case 'SE COUNTRYMAN':
          return year >= 2018;
        case 'SE HARDTOP':
          return year >= 2020;
        default:
          return year >= 2018;
      }
    case 'NISSAN':
      switch (model) {
        case 'LEAF':
          return year >= 2018 && year <= 2022;
        case 'MURANO':
          return year >= 2018;
        case 'ROGUE SPORT':
          return year >= 2020;
        case 'MAXIMA':
          return year >= 2021;
        case 'ALTIMA':
          return year >= 2022;
        case 'ARMADA':
          return year >= 2022;
        case 'FRONTIER':
          return year >= 2022;
        case 'KICK':
          return year >= 2022;
        case 'PATHFINDER':
          return year >= 2022;
        case 'ROGUE':
          return year >= 2022;
        case 'SENTRA':
          return year >= 2022;
        case 'TITAN':
          return year >= 2022;
        case 'TITAN XD':
          return year >= 2022;
        case 'ARIYA':
          return year >= 2023;
        case 'GT-R':
          return year >= 2023;
        case 'VERSA':
          return year >= 2023;
        default:
          return year >= 2018;
      }
    case 'PORSCHE':
      switch (model) {
        case 'MACAN':
          return year >= 2019;
        case 'PANAMERA':
          return year >= 2019;
        case '911':
          return year >= 2020 && year <= 2021;
        case 'CAYENNE':
          return year >= 2020;
        case 'CAYENNE PHEV':
          return year >= 2020;
        case 'TAYCAN':
          return year >= 2020;
        case '718':
          return year >= 2022;
        case '992':
          return year >= 2022;
        case '992 PHEV':
          return year >= 2022;
        case 'BOXSTER':
          return year >= 2022;
        case 'CAYMAN':
          return year >= 2022;
        default:
          return year >= 2019;
      }
    case 'RAM':
      switch (model) {
        case '1500':
          return year >= 2018;
        case '3500':
          return year >= 2018;
        case 'CHASSIS CAB':
          return year >= 2018;
        case '2500':
          return year >= 2019;
        case '1500 CLASSIC':
          return year >= 2019;
        default:
          return year >= 2018;
      }
    case 'RIVIAN':
      switch (model) {
        case 'R1S':
          return year >= 2022;
        case 'R1T':
          return year >= 2022;
        default:
          return year >= 2022;
      }
    case 'SUBARU':
      switch (model) {
        case 'ASCENT':
          return year >= 2019;
        case 'CROSSTREK':
          return year >= 2019;
        case 'HYBRID':
          return year >= 2019;
        case 'FORESTER':
          return year >= 2019;
        case 'IMPREZA':
          return year >= 2019;
        case 'LEGACY':
          return year >= 2020;
        case 'OUTBACK':
          return year >= 2020;
        case 'BRZ':
          return year >= 2022;
        case 'WRX':
          return year >= 2022;
        default:
          return year >= 2019;
      }
    case 'TESLA':
      switch (model) {
        case 'MODEL S':
          return year >= 2012;
        case 'MODEL X':
          return year >= 2016;
        case 'MODEL 3':
          return year >= 2017;
        case 'MODEL Y':
          return year >= 2020;
        case 'CYBERTRUCK':
          return year >= 2024;
        default:
          return year >= 2012;
      }
    case 'TOYOTA':
      switch (model) {
        case 'PRIUS PRIME':
          return year >= 2017;
        case 'CAMRY':
          return year >= 2018;
        case 'AVALON':
          return year >= 2019;
        case 'RAV 4':
          return year >= 2019;
        case '4 RUNNER':
          return year >= 2020;
        case 'C HR':
          return year >= 2020;
        case 'COROLLA HATCHBACK':
          return year >= 2020;
        case 'COROLLA SEDAN':
          return year >= 2020;
        case 'GR 86':
          return year >= 2020;
        case 'HIGHLANDER':
          return year >= 2020;
        case 'SEQUOIA':
          return year >= 2020;
        case 'TACOMA':
          return year >= 2020;
        case 'TUNDRA':
          return year >= 2020;
        case 'PRIUS':
          return year >= 2021;
        case 'RAV 4 PRIME':
          return year >= 2021;
        case 'SIENNA':
          return year >= 2021;
        case 'VENZA':
          return year >= 2021;
        case 'COROLLA CROSS':
          return year >= 2022;
        case 'BZ4X':
          return year >= 2023;
        case 'COROLLA CROSS HYBRID':
          return year >= 2023;
        default:
          return year >= 2017;
      }
    case 'VOLKSWAGEN':
      switch (model) {
        case 'ARTEON':
          return year >= 2020 && year <= 2021;
        case 'ATLAS':
          return year >= 2020;
        case 'GOLF':
          return year >= 2020 && year <= 2020;
        case 'GOLF':
          return year >= 2020;
        case 'GOLF ALLTRACK':
          return year >= 2020;
        case 'GOLF GTI':
          return year >= 2020;
        case 'GOLF R':
          return year >= 2020;
        case 'JETTA':
          return year >= 2020;
        case 'JETTA GLI':
          return year >= 2020;
        case 'PASSAT':
          return year >= 2020;
        case 'TIGUAN':
          return year >= 2020;
        case 'ID 4':
          return year >= 2021;
        case 'TAOS':
          return year >= 2022;
        case 'TIGUAN PHEV':
          return year >= 2023;
        default:
          return year >= 2020;
      }
    case 'VOLVO':
      switch (model) {
        case 'S80':
          return year >= 2016 && year <= 2016;
        case 'V40':
          return year >= 2016 && year <= 2019;
        case 'V70':
          return year >= 2016 && year <= 2016;
        case 'XC60':
          return year >= 2016 && year <= 2021;
        case 'XC70':
          return year >= 2016 && year <= 2016;
        case 'XC90':
          return year >= 2016 && year <= 2022;
        case 'XC90 PHEV':
          return year >= 2016 && year <= 2022;
        case 'S90':
          return year >= 2017 && year <= 2021;
        case 'V90':
          return year >= 2017 && year <= 2021;
        case 'S60':
          return year >= 2018 && year <= 2022;
        case 'S90 PHEV':
          return year >= 2018 && year <= 2021;
        case 'V60':
          return year >= 2018 && year <= 2022;
        case 'XC40':
          return year >= 2018 && year <= 2022;
        case 'XC60 PHEV':
          return year >= 2018 && year <= 2021;
        case 'S60 PHEV':
          return year >= 2019 && year <= 2022;
        case 'V60 PHEV':
          return year >= 2020 && year <= 2022;
        default:
          return year >= 2016;
      }
  }
  /* eslint-enable */

  return false;
};
