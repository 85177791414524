import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Table from 'components/Table';
import { asyncListAll } from 'utilities/graph';
import {
  getParticipantMileageReports,
} from './queries';

import { useStyles } from '../styles';
import CreateMileageReportDialog from './CreateMileageReportDialog';
import { sortBy } from 'utilities/sorting';
import VehicleDetailsTooltip from 'pages/Admin/components/VehicleDetailsTooltip';
import ImagePreviewDialog from './ImagePreviewDialog';

const ParticipantMileageReports = ({ username, viewer = 'admin', from, to, trips }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [mileageReports, setMileageReports] = useState([]);
  const [activePhotoS3Key, setActivePhotoS3Key] = useState(null);
  const [createMileageReportDialogIsOpen, setCreateMileageReportDialogIsOpen] = useState(false);

  const options = viewer === 'participant' ? {
    download: false,
    search: true,
    print: true,
    viewColumns: true,
    filter: true,
  } : {};

  const columns = [
    {
      name: 'tsReportDate',
      label: 'Report Date',
      type: 'datetime',
      options: {
        filter: false,
      },
    },
    {
      name: 'odoMileage',
      label: 'Odometer Reading',
      type: 'mileage',
      options: {
        filter: false,
      },
    },
    {
      name: 'photos',
      label: 'Photos',
      options: {
        filter: false,
        customBodyRender: (photos) => {
          if (photos && photos.length > 0) {
            return (
              <>
                {photos.map((photo, index) => {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={async () => {
                          setActivePhotoS3Key(photo.storageKey);
                        }}
                      >
                        Photo {index + 1}
                      </Button>
                    </>
                  );
                })}
              </>
            );
          } else {
            return <span>N/A</span>;
          }
        },
      },
    },
    {
      name: 'auditStatus',
      label: 'Submission Status',
      options: {
        customBodyRender: (auditStatus) => {
          return auditStatus.toUpperCase();
        },
      },
    },

    {
      name: 'vehicle.vin',
      label: 'Vehicle Details',
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRenderLite(dataIndex) {
          const data = mileageReports ? mileageReports[dataIndex] : null;
          if (!data || !data.vehicle) {
            return;
          }

          const { vehicle = null, username = null } = data;
          return (
            <VehicleDetailsTooltip
              username={username}
              vehicle={vehicle}
              showLpnState={true}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (trips) {
          const mileageReports = await asyncListAll(
            getParticipantMileageReports,
            {
              username,
              auditStatus: {
                eq: 'approved',
              },
              sortDirection: 'ASC',
            },
          );

          setMileageReports(mileageReports
            .filter(({ odoMileage }) => {
              return trips.find(({ odoMroStart, odoMroEnd }) => parseFloat(odoMroStart) === odoMileage || parseFloat(odoMroEnd) === odoMileage);
            })
            .sort(sortBy('tsReportDate', true)));

          return;
        }

        const mileageReports = await asyncListAll(
          getParticipantMileageReports,
          {
            username,
            filter: from && to ? {
              tsReportDate: {
                between: [from, to],
              },
            } : undefined,
            sortDirection: 'ASC',
          },
        );
        setMileageReports(mileageReports.sort(sortBy('tsReportDate', true)));
      } catch (e) {
        global.logger.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username, viewer, forceRefresh, from, to, trips]);

  const handleClosePhotoDialog = () => {
    setActivePhotoS3Key(null);
  };

  const handleOpenCreateMileageReportDialog = () => {
    setCreateMileageReportDialogIsOpen(true);
  };

  const handleCloseCreateMileageReportDialog = (forceUpdate) => {
    setCreateMileageReportDialogIsOpen(false);
    if (forceUpdate) {
      setForceRefresh(!forceRefresh);
    }
  };

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <>
      <div data-test-id="mileage-reports-table">
        <Table
          columns={columns}
          data={mileageReports}
          onHandleCreateItem={viewer === 'admin' ? handleOpenCreateMileageReportDialog : undefined}
          options={options}
          title={'Mileage Reports'}
          useCacheColumns={viewer === 'admin'}
        />
      </div>
      <CreateMileageReportDialog
        isOpen={createMileageReportDialogIsOpen}
        onClose={handleCloseCreateMileageReportDialog}
        username={username}
      />
      <ImagePreviewDialog
        imgS3Key={activePhotoS3Key}
        onClose={handleClosePhotoDialog}
      />
    </>
  );
};

ParticipantMileageReports.propTypes = {
  username: PropTypes.string,
  viewer: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  trips: PropTypes.arrayOf(PropTypes.object),
};

export default ParticipantMileageReports;
