export const GetTransactionsByStatusByCreatedAt = /* GraphQL */ `
  query GetTransactionsByStatusByCreatedAt(
    $status: TransactionStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        description
        type
        status
        subtotalCents
        discountCents
        amountCents
        tripSegments {
          items {
            id
          }
        }
        participant {
          username
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const GetTransactionsByTypeByCreatedAt = /* GraphQL */ `
  query GetTransactionsByTypeByCreatedAt(
    $type: TransactionType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByTypeByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        paidAt
        paymentMethodId
        description
        type
        status
        subtotalCents
        discountCents
        amountCents
        tripSegments {
          items {
            id
          }
        }
        tripAdjustments {
          items {
            id
          }
        }
        participant {
          username
          firstName
          lastName
          accountNo
        }
        paymentProcessorResponse {
          referenceNumber
          transactionResponse
        }
      }
      nextToken
    }
  }
`;
