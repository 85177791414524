export const COLLECT_BILLING_INFORMATION = false;

export const parseBool = (val) => {
  if (val === 'false') {
    return false;
  }
  return Boolean(val);
};

export const configItem = (storage, key, defaultValue = '') => {
  return storage.getItem(`ruc:configuration:${key}`) || defaultValue;
};

export const systemBillingFeatures = (storage) => {
  return {
    persist: parseBool(configItem(storage, 'PAYMENTS_PERSIST', 'false')),
    registration: parseBool(configItem(storage, 'PAYMENTS_REGISTRATION', 'false')),
    provider: configItem(storage, 'PAYMENTS_PROVIDER', ''),
    allowed: parseBool(configItem(storage, 'PAYMENTS_ALLOWED', 'false')),
    dueDays: configItem(storage, 'BILLING_BALANCE_DUE_DAYS', '14'),
    overdueDays: configItem(storage, 'BILLING_BALANCE_OVERDUE_DAYS', '21'),
    defaultDays: configItem(storage, 'BILLING_BALANCE_DEFAULT_DAYS', '30'),
  };
};

export const tripPaymentStatus = (tripSegments) => {
  const publicTripSegments = tripSegments.filter(({ type }) => type === 'public');
  if (publicTripSegments.length === 0) {
    return 'N/A';
  }

  const segmentStatuses = publicTripSegments.map(({ paymentStatus }) => paymentStatus);
  if (segmentStatuses.includes('failed')) {
    return 'failed';
  }

  const paidSegments = segmentStatuses.filter((status) => status === 'paid');
  if (paidSegments.length === publicTripSegments.length) {
    return 'paid in full';
  }

  // If there is only one trip segment then it is binary and cannot be partial.
  if (publicTripSegments.length > 1 && paidSegments.length < publicTripSegments.length) {
    return 'paid in part';
  }

  return 'pending';
};

export const getTransactionStanding = (status, createdAt) => {
  if (status === 'cancelled') return status;

  const billingFeatures = systemBillingFeatures(localStorage);
  const { dueDays, overdueDays, defaultDays } = billingFeatures;
  const dueMilliseconds = dueDays * 24 * 60 * 60 * 1000;
  const overdueMilliseconds = overdueDays * 24 * 60 * 60 * 1000;
  const defaultMilliseconds = defaultDays * 24 * 60 * 60 * 1000;
  const now = Date.now();
  const ageOfTransaction = now - Date.parse(createdAt);

  if (status !== 'processed' && ageOfTransaction > dueMilliseconds && ageOfTransaction < overdueMilliseconds) {
    return 'due';
  }

  if (status !== 'processed' && ageOfTransaction > overdueMilliseconds && ageOfTransaction < defaultMilliseconds) {
    return 'overdue';
  }

  if (status !== 'processed' && ageOfTransaction > defaultMilliseconds) {
    return 'default';
  }

  return 'current';
};
