import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Table from 'components/Table';
import LinkButton from 'components/Table/LinkButton';

const NotificationsTable = ({
  title = 'Notifications',
  description = '',
  viewer = 'admin',
  data: inData,
  onHandleCreate,
}) => {
  const [data, setData] = useState();
  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
  };
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: 'templateName',
      label: 'Template',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'subject',
      label: 'Subject',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'text',
      label: 'Text',
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: 'statusMsg',
      label: 'Status Message',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'retries',
      label: 'Retries',
      type: 'number',
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: 'shouldBeSentAt',
      label: 'Estimated Delivery Time',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value) => {
          // Cron runs every 5 minutes
          if (value) {
            const date = moment(value).tz('America/Los_Angeles').add(5, 'minutes');
            const zone = date.isDST ? '(PDT)' : '(PST)';
            return `${date.format('YYYY/MM/DD h:mm a')} ${zone}`;
          } else {
            return '';
          }
        },
      },
    },
    {
      name: 'sentAt',
      label: 'Sent At',
      type: 'datetime',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        display: false,
        sort: true,
        filter: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'username',
      label: 'Participant',
      options: {
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}?tab=Notifications`}
              label="View participant details"
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    setData(inData.map((item) => {
      item.email = item.email || (item.participant ? item.participant.email : null);
      return item;
    }));
  }, [inData]);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onHandleCreateItem={onHandleCreate}
    />
  );
};

NotificationsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  viewer: PropTypes.string,
  data: PropTypes.array.isRequired,
  onHandleCreate: PropTypes.func,
};

export default NotificationsTable;
