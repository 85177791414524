import React from 'react';
import { sortBy } from 'utilities/sorting';

import {
  getNotClosedParticipants,
  getAllStatements,
  getAllPilotPrograms,
} from './data';

export default {
  name: 'Statements Check',
  filename: 'Operational_Report_Statements_Check',
  description: (
    <ul>
      <li>
        All not-closed participants should have statements.
      </li>
    </ul>
  ),
  useDateRange: false,
  async process() {
    const [
      notClosedParticipants,
      allStatements,
      allPilotPrograms,
    ] = await Promise.all([
      getNotClosedParticipants(),
      getAllStatements(),
      getAllPilotPrograms(),
    ]);

    const data = await Promise.all(notClosedParticipants.map(async ({
      username,
      accountNo,
      firstName,
      lastName,
      status,
      participantPilotProgramId,
    }) => {
      const pilotProgram = allPilotPrograms.find(({ id }) => id === participantPilotProgramId);
      const statements = allStatements
        .filter((p) => p.username === username)
        .sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
      const {
        month: lastStatementMonth,
        createdAt: lastStatementCreatedAt,
        isProcessed,
        balance: lastStatementBalance,
      } = statements[statements.length - 1] || {};

      return {
        program: pilotProgram?.shortName,
        accountNo,
        firstName,
        lastName,
        status,
        totalStatements: statements.length,
        lastStatementCreatedAt,
        lastStatementMonth,
        lastStatementStatus: isProcessed ? 'processed' : '',
        lastStatementBalance,
        // url: `${window.location.protocol}//${window.location.hostname}/participant/${username}`,
      };
    }));

    return data.sort(sortBy('status'));
  },
};
