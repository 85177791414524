import moment from 'moment-timezone';

import { asyncListAll, asyncListFirstItem } from 'utilities/graph';
import {
  listParticipantStatements,
  listPilotPrograms,
  getParticipantsByPilotProgram,
  listMileageReports,
  listVehicles,
  getTripsByUsernameByCreatedAt,
  getNotificationsByUsernameByCreatedAt,
  getNotificationsByStatusByShouldBeSentAt,
  getTransactionsByStatusByCreatedAt,
} from 'graphql/queries';

import {
  getParticipantsByStatus,
} from './queries';

const cache = {};

const listCacheData = async (func = asyncListAll, operationName, input = {}) => {
  const key = `${JSON.stringify({ operationName, input })}`;
  if (cache[key]) {
    return cache[key];
  }
  const result = await func(operationName, input);
  cache[key] = result;

  return result;
};

export const getManualProgramParticipants = async () => {
  const pilotPrograms = await listCacheData(asyncListAll, listPilotPrograms);

  const { id: manualPilotProgramId } = pilotPrograms.find(({ shortName }) => shortName === 'OB-MBUF');

  const manualProgramParticipants = await listCacheData(asyncListAll, getParticipantsByPilotProgram, {
    participantPilotProgramId: manualPilotProgramId,
  });

  return manualProgramParticipants;
};

export const getCurrentParticipants = async () => {
  const allStatus = [
    // 'created',
    // 'onboarded',
    // 'approved',
    'active',
    'suspended',
    'flagged',
    // 'closed',
  ];

  let participants = [];

  await Promise.all(allStatus.map(async (status) => {
    const result = await listCacheData(asyncListAll, getParticipantsByStatus, { status });
    participants = [...participants, ...result];
  }));

  return participants;
};

export const getNotClosedParticipants = async () => {
  const allStatus = [
    'created',
    'onboarded',
    'approved',
    'active',
    'suspended',
    'flagged',
    // 'closed',
  ];

  let participants = [];

  await Promise.all(allStatus.map(async (status) => {
    const result = await listCacheData(asyncListAll, getParticipantsByStatus, { status });
    participants = [...participants, ...result];
  }));

  return participants;
};

export const getFlaggedParticipants = async () => {
  return listCacheData(asyncListAll, getParticipantsByStatus, { status: 'flagged' });
};

export const getParticipantMileageReports = async (username) => {
  return listCacheData(asyncListAll, listMileageReports, {
    username,
  });
};

export const getAllPilotPrograms = async () => {
  return listCacheData(asyncListAll, listPilotPrograms, {});
};

export const getAllMileageReports = async () => {
  return listCacheData(asyncListAll, listMileageReports, {});
};

export const getAllVehicles = async () => {
  return listCacheData(asyncListAll, listVehicles, {});
};

export const getAllStatements = async () => {
  return listCacheData(asyncListAll, listParticipantStatements, {});
};

export const getAllOpenTransactions = async () => {
  const allStatus = [
    'created',
    'pending',
    // 'processed',
    'failed',
    // 'cancelled',
  ];

  let data = [];

  await Promise.all(allStatus.map(async (status) => {
    const result = await listCacheData(asyncListAll, getTransactionsByStatusByCreatedAt, { status });
    data = [...data, ...result];
  }));

  return data;
};

export const getAllTransactions = async () => {
  const allStatus = [
    'created',
    'pending',
    'processed',
    'failed',
    'cancelled',
  ];

  let data = [];

  await Promise.all(allStatus.map(async (status) => {
    const result = await listCacheData(asyncListAll, getTransactionsByStatusByCreatedAt, { status });
    data = [...data, ...result];
  }));

  return data;
};

export const getParticipantRecentNotifications = async (username, daysAgo = 2) => {
  return listCacheData(asyncListAll, getNotificationsByUsernameByCreatedAt, {
    username,
    createdAt: {
      gt: moment().subtract(daysAgo, 'days').toISOString(),
    },
  });
};

export const getAllRecentNotifications = async (daysAgo = 2) => {
  const statuses = [
    'pending',
    'sent',
    'failed',
    'cancelled',
  ];
  let data = [];

  await Promise.all(statuses.map(async (status) => {
    const result = await listCacheData(asyncListAll, getNotificationsByStatusByShouldBeSentAt, {
      status,
      shouldBeSentAt: {
        gt: moment().subtract(daysAgo, 'days').toISOString(),
      },
    });

    data = [...data, ...result];
  }));

  return data;
};

export const getParticipantLastTrips = async (username) => {
  return listCacheData(asyncListFirstItem, getTripsByUsernameByCreatedAt, {
    username,
    sortDirection: 'DESC',
  });
};
