import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Tupay from '../../Account/components/Tupay';

const NewPaymentMethod = ({
  username,
  classes,
  onPaymentSelected,
  setError,
}) => {
  return (
    <div className={classes.paper} style={{ maxWidth: 480 }}>
      <hr className={classes.divider} />
      <Typography component="h1" variant="h5">
        Or provide info for one time payment
      </Typography>
      <Tupay
        classes={classes}
        user={{ username }}
        title=" "
        submitButtonText="Use for one time payment"
        showAsStored={false} // For one time payment
        setError={setError}
        onCompleted={onPaymentSelected}
        style={{ width: '100%' }} />
    </div>
  );
};

NewPaymentMethod.propTypes = {
  username: PropTypes.string,
  classes: PropTypes.object,
  onPaymentSelected: PropTypes.func,
  setError: PropTypes.func,
};

export default NewPaymentMethod;
