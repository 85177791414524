import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}));

export default function FilterSelector({
  activeFilters,
  filterDefinition,
  onUpdate,
}) {
  global.logger.debug('= render transaction filters');
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          {filterDefinition.filter(({ type }) => type === 'radio').map((definition, index) => {
            return (
              <Grid key={index} item alignItems="center">
                <InputLabel id={definition.key} className={classes.label}>{definition.label}</InputLabel>
                <ButtonGroup labelId={definition.key} color="primary" aria-label="outlined primary button group">
                  {definition.options.map((option, subIndex) => {
                    const isSelected = activeFilters[definition.key] === option;
                    return (
                      <Button
                        key={subIndex}
                        variant={isSelected ? 'contained' : 'outlined'}
                        onClick={() => {
                          onUpdate(definition.key, option);
                        }}
                      >
                        {option}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </div>
  );
}

FilterSelector.propTypes = {
  filterDefinition: PropTypes.array,
  onUpdate: PropTypes.func,
  activeFilters: PropTypes.object,
};
