import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import ControlledInput from 'components/Form/ControlledInput';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { convertMileage } from 'utilities/format';
import { getMileageReportsByAuditStatus } from './queries';
import { listMileageReports } from 'graphql/queries';
import { updateMileageReport } from 'graphql/mutations';
import ImagePreviewDialog from 'pages/Admin/Participant/components/ImagePreviewDialog';

import { getImageUrl } from 'utilities/image';
import { sortBy } from 'utilities/sorting';
import { useStyles } from './styles';

export default function MileageReports() {
  const classes = useStyles();
  const [reportsQueue, setReportsQueue] = useState([]);
  const [activeMileageReport, setActiveMileageReport] = useState(null);
  const [activeMileageReportImage, setActiveMileageReportImage] = useState(null);
  const [activeMileageReportVehicleInactiveDate, setActiveMileageReportVehicleInactiveDate] = useState(null);
  const [previewS3Key, setPreviewS3Key] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // form states
  const { control, errors, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;

  const inputs = [{
    type: 'text',
    name: 'previousReportOdometer',
    label: 'Previous Report Odometer Reading',
    InputProps: {
      disabled: true,
    },
  }, {
    type: 'text',
    name: 'odoMileage',
    label: 'Report Odometer Reading',
    InputProps: {
      disabled: true,
    },
  }, {
    type: 'text',
    name: 'actualMileage',
    label: 'Actual Mileage Override',
    autoFocus: true,
    required: true,
    invalidText: 'Actual mileage is required',
    InputProps: {
      disabled: isLoading,
    },
  }, {
    type: 'text',
    name: 'reason',
    label: 'Reason (optional)',
    multiline: true,
    rows: 4,
    required: false,
    InputProps: {
      disabled: isLoading,
    },
  }];

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      let reports = await asyncListAll(getMileageReportsByAuditStatus, {
        auditStatus: 'pending',
      });

      if (reports.length > 0) {
        reports = reports.sort((a, b) => a.tsReportDate > b.tsReportDate ? 1 : -1).map((report) => {
          return Object.assign({}, report, {
            odoMileage: convertMileage(report.odoMileage, 'mi'),
          });
        });
        const activeReport = reports.shift();
        setReportsQueue(reports);
        setActiveMileageReport(activeReport);
      }

      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (activeMileageReport) {
      (async () => {
        setIsLoading(true);

        const previousMileageReports = await asyncListAll(listMileageReports, {
          username: activeMileageReport.username,
          filter: {
            auditStatus: {
              eq: 'approved',
            },
          },
        });
        const lastApprovedMileageReport = previousMileageReports.sort(sortBy('tsReportDate', true))[0];

        let diff = 0;
        if (lastApprovedMileageReport) {
          const previousReportOdometer = convertMileage(lastApprovedMileageReport.odoMileage, 'mi');
          setValue('previousReportOdometer', previousReportOdometer.toString());
          diff = (activeMileageReport.odoMileage - previousReportOdometer);
        } else {
          setValue('previousReportOdometer', '0');
        }

        const imgUrl = await getImageUrl(activeMileageReport.photos[0].storageKey);
        setActiveMileageReportImage(imgUrl);
        setValue('username', activeMileageReport.username);
        setValue('odoMileage', activeMileageReport.odoMileage.toString() + ` (${diff >= 0 ? '+' : '-'}${Math.abs(diff).toFixed(0)})`);
        setValue('actualMileage', activeMileageReport.odoMileage.toString());

        const vehicleInactiveDate = activeMileageReport.vehicle.status === 'inactive' ? activeMileageReport.vehicle.inactiveDate : null;
        setActiveMileageReportVehicleInactiveDate(vehicleInactiveDate);

        setIsLoading(false);
      })();
    }
  }, [activeMileageReport, setValue]);

  if (!activeMileageReport) {
    return (
      <Grid container justify="center" data-test-id="account-review">
        <Grid item xs={12} sm={9} md={6}>
          <Alert severity="info">
            <AlertTitle>There are no pending mileage reports to audit.</AlertTitle>
          </Alert>
        </Grid>
      </Grid>
    );
  }

  async function handleAuditMileageReport({ actualMileage, auditStatus, reason = undefined }) {
    try {
      setIsLoading(true);

      // update the active mileage report
      await asyncRetryMutation(updateMileageReport, {
        input: {
          auditStatus,
          id: activeMileageReport.id,
          odoMileage: convertMileage(parseInt(actualMileage, 10), 'km'),
          reason,
          tsReportDate: activeMileageReport.tsReportDate,
          username: activeMileageReport.username,
          updatedBy: localStorage.getItem('ruc:username'),
        },
      });

      // update the queue
      if (reportsQueue.length > 0) {
        const activeReport = reportsQueue.shift();
        setReportsQueue(reportsQueue);
        setActiveMileageReport(activeReport);
      } else {
        setActiveMileageReport(null);
      }
    } catch (e) {
      global.logger.warn(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container component="main" maxWidth={'lg'} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={4} md={4} lg={4}>
          <Typography component="h1" variant="h5" color="primary">Report Details:</Typography>
          <Divider className={classes.divider} />
          <form
            className={classes.form}
            noValidate
          >
            <Grid container spacing={2}>
              {inputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting || isLoading}
              onClick={handleSubmit((fields) => {
                handleAuditMileageReport({
                  ...fields,
                  auditStatus: 'approved',
                });
              })}
            >
              Approve
            </Button>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              disabled={isSubmitting || isLoading}
              onClick={handleSubmit((fields) => {
                handleAuditMileageReport({
                  ...fields,
                  auditStatus: 'rejected',
                });
              })}
            >
              Reject
            </Button>
          </form>
        </Grid>
        <Grid item xs={8} md={8} lg={8} container spacing={2}>
          <Grid item xs={12}>
            <strong>User</strong>: {activeMileageReport.username}
          </Grid>
          {activeMileageReportVehicleInactiveDate && (
            <Grid item xs={12} className={classes.warning}>
              <strong>Vehicle Inactive On</strong>: {activeMileageReportVehicleInactiveDate}
            </Grid>
          )}
          <Grid item xs={12} container justify='center'>
            <img
              src={activeMileageReportImage}
              className={classes.imagePreview}
              onClick={() => setPreviewS3Key(activeMileageReport.photos[0].storageKey)}
            />
            <ImagePreviewDialog
              imgS3Key={previewS3Key}
              onClose={() => setPreviewS3Key(null)}
            />
          </Grid>
          <Grid item xs={12}>
            <strong>
              {reportsQueue.length} pending report{(reportsQueue.length === 0 || reportsQueue.length > 1) && (<span>s</span>)} remaining
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
