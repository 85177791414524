export const participantQl = /* GraphQL */ `
query ListParticipants(
  $filter: ModelParticipantFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listParticipants(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      username
      accountNo
      firstName
      lastName
\      pilotProgram {
        shortName
      }
      flags {
        isVIP
      }
      vehicles {
        items {
          id
          vin
          mroType
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export function getCode(mroEvent = {}) {
  const {
    code,
    description,
  } = mroEvent;

  // Internal
  if (description === 'OBDII device has not reported data for a 72-hour period') {
    return 1001;
  }

  // Internal
  if (/Failed to match participant vehicle vin/.test(description)) {
    return 1002;
  }

  // Internal
  if (
    /Failed to match trip vin to the lookup vehicle vin/.test(description) ||
    /Failed to decode the vehicle VIN:/.test(description) ||
    /Failed to match participant vehicle to the vin decoded vehicle: /.test(description)
  ) {
    return 1008;
  }

  // Internal
  if (/^odoMro/.test(description)) {
    // tripRating: odoMro(${odoMro}) is significantly different to distMro(${distMro})
    return 1009;
  }

  // ruc-lambda-golang-mro-events
  if (/^Device Connected/.test(description)) {
    return 3;
  }

  // ruc-lambda-golang-mro-events
  if (/^Device Disconnected/.test(description)) {
    return 4;
  }

  // ruc-lambda-golang-mro-events
  if (code === '5' || /^Vin Change/.test(description)) {
    // ruc-lambda-golang-mro-events
    return 5;
  }

  // ruc-microservice-golang-trip-processing
  // "Trip %s has no mileage"
  if (/has no mileage/.test(description)) {
    return 12;
  }

  // ruc-microservice-golang-trip-processing
  // "Trip 8f45bf7e-de8d-4173-b1bb-f0481f578fe5 is incomplete"
  if (/is incomplete/.test(description)) {
    return 15;
  }

  // ruc-microservice-golang-trip-processing
  // "Device %s reported excessive mileage (%g)"
  if (/reported excessive mileage/.test(description)) {
    return 16;
  }

  // ruc-lambda-golang-mro-events
  // code 4, 5, 99 are considered as health events
  if (/^Device Health/.test(description)) {
    return 17;
  }

  // ruc-lambda-golang-mro-events
  if (/^Device heartbeat/.test(description)) {
    return 98;
  }

  // Internal
  // ruc-microservice-golang-trip-processing
  // "Trip %s could not be posted to rating"
  if (
    /could not be posted to rating/.test(description) ||
    /Failed to process trip/.test(description)
  ) {
    return 99;
  }

  if (
    /Trip processed./.test(description)
  ) {
    return 99999;
  }

  return '';
}
