import React, { useState } from 'react';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import {
  simpleDate,
  convertMileage,
  formatMroType,
  formatVehicleFuelType,
  formatRUCEmployer,
  formatRucGroupName,
} from 'utilities/format';
import {
  listParticipantsWithVehicles,
  getNotificationsByStatusByShouldBeSentAt,
} from './graphql';
import { APP } from 'utilities/constants';
import { checkDuplicateRecordId, listAllForDateRange, getParticipantAppprovedAt } from './helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const getAllApprovalNotifications = async (from, to) => {
  let records = [];

  const statuses = [
    'sent',
    'failed',
    'cancelled',
  ];

  await Promise.all(statuses.map(async (status) => {
    const data = await listAllForDateRange({
      from,
      to,
      gqlQuery: getNotificationsByStatusByShouldBeSentAt,
      params: (from, to) => {
        return {
          status,
          shouldBeSentAt: { between: [from, to] },
          filter: {
            or: [
              { templateName: { eq: 'accountApproval' } },
              { templateName: { eq: 'accountApprovalManualProgram' } },
              { templateName: { eq: 'telematicsConnectReminder1' } },
            ],
          },
        };
      },
    });

    records = [...records, ...data];
  }));

  return records;
};

function mapFields(participant, vehicle, approvedAt) {
  const id = vehicle.id;
  const odo = vehicle.reports == undefined ? [] : vehicle.reports.sort((a, b) => {
    return moment(a.tsReportDate).isBefore(b.tsReportDate) ? -1 : 1;
  });
  const odoStart = vehicle.beginningOdometerReading || (odo.length > 0 ? convertMileage(vehicle.reports[0].odometer, 'mi') : '');
  const odoStartDate = vehicle.firstDataReceivedDate || (odo.length > 0 ? vehicle.reports[0].tsReportDate : '');

  participant.flags = participant.flags || {};

  const activedAt = participant.firstDataReceivedDate;

  return {
    'Record ID': id,
    'Account ID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Address Line 1': participant.address.address1,
    'Address Line 2': participant.address.address2,
    'City': participant.address.city,
    'State': participant.address.state,
    'Zip Code': participant.address.postalCode + (participant.address.extendedPostalCode || ''),
    'Email Address': participant.email,
    'Telephone Number': participant.phoneNumber,
    'Preferred Contact Method': participant.preferredContactType,
    'Employer': formatRUCEmployer(participant),
    'RUC Group': formatRucGroupName(participant.pilotProgram?.shortName),
    'VIP': participant.flags.isVIP ? 'True' : 'False',
    'VIN': vehicle.vin,
    'Vehicle Make': vehicle.make,
    'Vehicle Model': vehicle.model,
    'Vehicle Year': vehicle.year,
    'Vehicle Registration State': vehicle.registrationState,
    'Vehicle Registration Plate': vehicle.licensePlate,
    'Vehicle Fuel Type': formatVehicleFuelType(vehicle.type),
    'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
    'Starting Odometer': odoStart,
    'Starting Odometer Date': odoStartDate ? simpleDate(odoStartDate) : '',
    'CA SB339 T&C Signed Date': participant.createdAt == undefined ? '' : simpleDate(participant.createdAt),
    'Transurban T&C Signed Date': participant.onboardedDate == undefined ? '' : simpleDate(participant.onboardedDate),
    'Account Created Date': participant.createdAt == undefined ? '' : simpleDate(participant.createdAt),
    'Account Onboarded Date': participant.onboardedDate == undefined ? '' : simpleDate(participant.onboardedDate),
    'Account Approved Date': approvedAt ? simpleDate(approvedAt) : '',
    'Account Active Date': activedAt ? simpleDate(activedAt) : '',
    'VIN Added Date': participant.createdAt == undefined ? '' : simpleDate(participant.createdAt),
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': formatMroType(vehicle.mroType),
    'MRO (PID) Ship Date': vehicle.mro && vehicle.mro.shippedDate != undefined ? simpleDate(vehicle.mro.shippedDate) : '',
    'MRO (PID) Shipping Address Line 1': vehicle.mro ? participant.address.address1 : '',
    'MRO (PID) Shipping Address Line 2': vehicle.mro ? participant.address.address2 : '',
    'MRO (PID) Shipping City': vehicle.mro ? participant.address.city : '',
    'MRO (PID) Shipping State': vehicle.mro ? participant.address.state : '',
    'MRO (PID) Shipping Zip Code': vehicle.mro ? participant.address.postalCode + (participant.address.extendedPostalCode || '') : '',
    'MRO (PID) Delivered Date': vehicle.mro && vehicle.mro.deliveredDate ? simpleDate(vehicle.mro.deliveredDate) : '',
    'MRO Trips Reported Date': participant.firstDataReceivedDate == undefined ? '' : simpleDate(participant.firstDataReceivedDate),
  };
}

export default function EnrollmentReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);

      const fromDate = moment(from).tz(APP.TIME_ZONE).startOf('day').toISOString();
      const toDate = moment(to).tz(APP.TIME_ZONE).endOf('day').toISOString();

      const [participants, notifications] = await Promise.all([
        // The report should capture participants in ANY status EXCEPT `created` and `closed`.
        asyncListAll(listParticipantsWithVehicles, {
          filter: {
            onboardedDate: { between: [fromDate, toDate] },
            or: [
              { status: { eq: 'onboarded' } },
              { status: { eq: 'approved' } },
              { status: { eq: 'active' } },
              { status: { eq: 'suspended' } },
              { status: { eq: 'flagged' } },
            ],
          },
        }),
        getAllApprovalNotifications(fromDate, toDate),
      ]);

      const data = participants.map((participant) => {
        const approvedAt = getParticipantAppprovedAt({ participant, notifications });
        return participant.vehicles.items.map((vehicle) => {
          return mapFields(participant, vehicle, approvedAt);
        });
      });

      return data.flat();
    } catch (e) {
      global.logger.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Enrolling Monitoring Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`ENROLLMENT MONITORING_Date ${moment().format('MM/DD/YYYY')}.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                checkDuplicateRecordId(data);

                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
