import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PaymentButton from 'pages/Participant/Payment/components/PaymentButton';
import TransactionsTable from 'pages/Admin/components/TransactionsTable';
import DashboardChart from './DashboardChart';

import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';

import moment from 'moment';

import {
  asyncListAll,
  asyncGet,
} from 'utilities/graph';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';
import { getParticipantStatementsByParticipantByMonth } from './queries';
import {
  getPaymentCard,
  listVehicles,
} from 'graphql/queries';
import { APP } from 'utilities/constants';

import useScreenView from 'utilities/useScreenView';
import {
  formatCurrency,
  formatNumber,
} from 'utilities/format';

import { useStyles } from './styles';

const ParticipantDashboard = ({ user, participant }) => {
  const classes = useStyles();
  const { size } = useScreenView();
  const { username } = user;

  const [isLoading, setIsLoading] = useState(true);
  const [openTransactions, setOpenTransactions] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [activeMonthIndex, setActiveMonthIndex] = useState(0);

  // statement rollups
  const [statements, setStatements] = useState([]);
  const [balance, setBalance] = useState(0);
  const [totalMiles, setTotalMiles] = useState(0);
  const [totalRoadCharge, setTotalRoadCharge] = useState(0);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());

  const billingFeatures = systemBillingFeatures(localStorage);

  const mileageUnit = localStorage.getItem('ruc:configuration:MILEAGE_UNIT') || 'km';

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      // data loading
      try {
        const [
          vehicles,
          allStatements,
        ] = await Promise.all([
          await asyncListAll(listVehicles, {
            username,
            sortDirection: 'DESC',
          }),
          await asyncListAll(getParticipantStatementsByParticipantByMonth, {
            username,
            sortDirection: 'DESC',
            filter: {
              createdBy: {
                eq: 'System',
              },
            },
          }),
        ]);

        setVehicles(vehicles);

        global.logger.debug('allStatements', allStatements);

        if (allStatements.length === 0) return;

        const statementMappings = {};

        allStatements.forEach((statement) => {
          // only keep the eligible transactions for payment
          statement.transactions.items = statement.transactions.items
            .filter(({ type }) => type === 'transaction');

          // only show the latest statement for the same month
          statementMappings[statement.month] = statementMappings[statement.month] || statement;
          if (statementMappings[statement.month].createdAt < statement.createdAt) {
            statementMappings[statement.month] = statement;
          }
        });

        const statements = Object.values(statementMappings);

        const latestStatement = statements[0];

        setStatements(statements);
        setBalance(formatCurrency(latestStatement.balance - latestStatement.paidAmount));
        setTotalMiles(formatNumber(statements.reduce((accMiles, statement) => accMiles + statement.mileage, 0)));
        setTotalRoadCharge(formatCurrency(statements.reduce((accTotal, statement) => accTotal + statement.totalNewChages, 0)));

        const loadedTransactions = statements.reduce((allTransactions, statement) => {
          return [...allTransactions, ...statement.transactions.items];
        }, []);

        // set payment information fields for each transaction
        const allPaymentMethods = loadedTransactions.reduce((acc, next) => {
          const { paymentMethodId = null } = next;
          if (!paymentMethodId) {
            return acc;
          }
          if (acc.indexOf(paymentMethodId) === -1) {
            acc.push(paymentMethodId);
          }
          return acc;
        }, []);

        const paymentCards = (await Promise.all(allPaymentMethods.map((id) => {
          return asyncGet(getPaymentCard, {
            username,
            id,
          });
        }))).map((paymentCardResponse) => {
          return Object.assign({}, paymentCardResponse.data.getPaymentCard);
        });

        for (let i = 0; i < loadedTransactions.length; i++) {
          const paymentCard = paymentCards.find(({ id }) => id === loadedTransactions[i].paymentMethodId);
          if (paymentCard) {
            loadedTransactions[i].paymentType = paymentCard.brand.toUpperCase();
            loadedTransactions[i].paymentInfo = paymentCard.last4.toString().padStart(4, '0');
          }
        }

        setOpenTransactions(loadedTransactions.filter(({ status, amountCents }) => {
          return amountCents > 0 && (status === 'created' || status === 'failed');
        }));
      } catch (e) {
        global.logger.warn(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username, lastUpdatedAt]);

  useEffect(() => {
    if (statements.length === 0) {
      setChartData({});
      return;
    }

    const pilotStartDate = localStorage.getItem('ruc:configuration:PILOT_START_DATE') || '2024-01-01T00:00:00.000Z';
    const pilotStartMonth = moment(pilotStartDate).format('YYYY-MM');
    console.log('pilotStartMonth', pilotStartDate, pilotStartMonth);
    const { month: lastStatementMonth } = statements[0];

    const lastMonth = moment().subtract(1, 'months').format('YYYY-MM');
    const endMonth = lastStatementMonth > lastMonth ? lastStatementMonth : lastMonth;

    const data = {};

    let index = 0;
    let indexMonth = pilotStartMonth;

    while (indexMonth <= endMonth) {
      const {
        transactions: {
          items: statementTransactions,
        },
        fuel = 0,
        mileage = 0,
        totalNewChages = 0,
        trips = 0,
      } = statements.find((s) => s.month === indexMonth) || { transactions: { items: [] } };

      data[index] = {
        name: moment(indexMonth).format('MMM \'YY'),
        data: (totalNewChages / 100),
        extra: {
          trips,
          mileage,
          fuel,
          transactions: statementTransactions,
        },
      };

      index++;
      indexMonth = moment(pilotStartMonth).add(index, 'months').format('YYYY-MM');
    }

    setChartData(data);
    setActiveMonthIndex(Object.keys(data).length - 1);
  }, [statements]);

  if (isLoading || !chartData) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  if (statements.length === 0) {
    return (
      <div className={classes.notFound}>
        <Typography component="h1" variant="h5">
          Official Pilot Start.
        </Typography>
        <Alert color="info">
          The California Road Charge Collection pilot started on August 1st, 2024.
          Billing activity will display here once the first billing cycle for August is run in early September.
          Please contact us if you have any questions and thank you for your participation!
        </Alert>
      </div>
    );
  }

  return (
    <Container component="main" maxWidth={size}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={`${classes.paper} ${classes.dashboardHeading}`}>
            <Avatar variant="circle" className={classes.avatar}>
              <DashboardOutlinedIcon color="inherit" />
            </Avatar>
            <Typography component="h1" variant="h5">
              SB 339 - Road Charge Collection Pilot
            </Typography>
          </div>
          <Alert style={{ display: 'none' }}></Alert>
        </Grid>
        <Grid item xs={12} sm={5} key={`welcomeGridItem-${participant.lastName}`}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.dashboardWelcome} key={classes.dashboardWelcome}>
              <strong>Welcome {participant.firstName} {participant.lastName}</strong>
            </Grid>
            {vehicles.map((vehicle) => {
              return (<React.Fragment key={`${vehicle.id}-year`}>
                <Grid item xs={12} key={`${vehicle.id}-year`}>
                  Vehicle: {vehicle.year} {vehicle.make.substring(0, 10)} {vehicle.model.substring(0, 20)}
                </Grid>
                <Grid item xs={12} key={`${vehicle.id}-vin`}>
                  VIN: {vehicle.vin.toUpperCase()}
                </Grid>
                {mileageUnit === 'km' ?
                  <Grid item xs={12} key={`${vehicle.id}-kpl`}>
                    KPL: {vehicle.epaVehicleCombinedKpl}
                  </Grid> :
                  <Grid item xs={12} key={`${vehicle.id}-mpg`}>
                    MPG: {vehicle.epaVehicleCombinedMpg}
                  </Grid>}
                <Grid item xs={12} key={`${vehicle.id}-blank`}>
                </Grid>
              </React.Fragment>);
            })}
            <Grid item xs={12} className={classes.dashboardBalanceTitle} key={classes.dashboardBalanceTitle}>
              Total Account Balance:
            </Grid>
            <Grid
              item xs={7}
              className={`${classes.dashboardBalance} ${classes.dashboardBalanceText}`}
              key={`${classes.dashboardBalance} ${classes.dashboardBalanceText}`}
            >
              {balance}
            </Grid>
            <Grid item xs={5} className={classes.dashboardBalance} key={classes.dashboardBalance}>
              {billingFeatures.allowed && openTransactions.length > 0 && (
                <PaymentButton
                  user={user}
                  balance={balance}
                  transactions={openTransactions}
                  onUpdate={() => setLastUpdatedAt(Date.now())}
                />
              )}
            </Grid>
            <Grid item xs={12} key='links'>
              <React.Fragment>
                View current statement for road charges billed.
                <ul>
                  <li><a href={`${APP.FAQ_LINK}`} rel="noopener noreferrer" target="_blank">Pilot FAQs</a></li>
                  <li><a href={`${APP.LINK_PARTICIPANT_AGREEMENT}`} rel="noopener noreferrer" target="_blank">
                    Pilot Participant Agreement</a></li>
                  <li><a href={`${APP.LINK_PRIVACY_POLICY}`} rel="noopener noreferrer" target="_blank">Pilot Privacy Policy</a></li>
                </ul>
              </React.Fragment>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6} key='dashboardTotalsMiles'>
                <div className={classes.dashboardTotals}>
                  Total Pilot Miles
                  <br />
                  {totalMiles}
                </div>
              </Grid>
              <Grid item xs={6} key='dashboardTotalsCharge'>
                <div className={classes.dashboardTotals}>
                  Total Pilot Road Charge
                  <br />
                  {totalRoadCharge}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7} key='dashboardChart'>
          <DashboardChart
            setIndex={setActiveMonthIndex}
            chartData={chartData}
            mroDevicePreference={participant.mroDevicePreference}
          />
        </Grid>
        <Grid item xs={12} sm={12} key='dashboardTransactionsTable'>
          <div>
            <TransactionsTable
              title={`${chartData[activeMonthIndex]?.name || ''} Transactions`}
              data={Object.keys(chartData).length > 0 ? chartData[activeMonthIndex].extra.transactions : []}
              viewer="participant"
              mroDevicePreference={participant.mroDevicePreference}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

ParticipantDashboard.propTypes = {
  participant: PropTypes.object,
  user: PropTypes.shape({
    username: PropTypes.string,
    attributes: PropTypes.shape({
      email: PropTypes.string,
      given_name: PropTypes.string,
      family_name: PropTypes.string,
    }),
  }),
};

export default ParticipantDashboard;
