import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

function VehicleDetailsTooltip({ username, vehicle, showLpnState = false, clickable = false }) {
  const {
    vin,
    make = null,
    model = null,
    year = null,
    licensePlate = null,
    registrationState = null,
  } = vehicle;
  return (
    <Tooltip
      title={
        <table>
          <tr>
            <td>VIN</td>
            <td>{vin}</td>
          </tr>
          <tr>
            <td>Make</td>
            <td>{make}</td>
          </tr>
          <tr>
            <td>Model</td>
            <td>{model}</td>
          </tr>
          <tr>
            <td>Year</td>
            <td>{year}</td>
          </tr>
          <tr>
            <td>License Plate</td>
            <td>{licensePlate}</td>
          </tr>
          <tr>
            <td>Registration State</td>
            <td>{registrationState}</td>
          </tr>
        </table>
      }
      placement="right"
    >
      {clickable ? <Link
        to={`/participant/${username}?tab=Vehicles`}
        component={RouteLink}
        target="_blank"
        rel="noopener"
      >
        {showLpnState ?
          <Button size={'small'} startIcon={<DirectionsCarIcon />}>
            {licensePlate}-{registrationState}
          </Button> :
          <IconButton
            size={'small'}
          >
            <DirectionsCarIcon />
          </IconButton>}
      </Link> :
        <DirectionsCarIcon />}
    </Tooltip>
  );
}

VehicleDetailsTooltip.propTypes = {
  username: PropTypes.string,
  vehicle: PropTypes.object,
  showLpnState: PropTypes.bool,
  clickable: PropTypes.bool,
};

export default VehicleDetailsTooltip;
