import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Logo from 'components/Logo';

import { APP } from 'utilities/constants';

const PilotClosed = () => {
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div>
          <Grid container justify="center">
            <Grid item xs={6}>
              <Box marginBottom={4} marginTop={6}>
                <Logo width={250} fullColor display='block' margin='auto' />
              </Box>
            </Grid>
          </Grid>
          <Typography component="h5" variant="h5" color="primary" gutterBottom>
            Enrollment for the California Road Charge Collection Pilot is now closed.
          </Typography>
          <p>
            Thank you for your interest in participating in the study, powered by
            Transurban. The Pilot has reached its goal of participants and
            enrollment is now closed. If you would like to hear about the progress of this Pilot
            and other Road Charge news, please email us
            at <Link href={`mailto:${APP.SUPPORT_EMAIL}`} variant="body2" target="_blank">info@caroadcharge.com</Link> and
            ask to join our newsletter or visit the Pilot page
            at <Link href='https://caroadcharge.com/' variant="body2" target="_blank">caroadcharge.com</Link>
          </p>
          <p>
            If you are already enrolled and have questions,
            please contact us via email
            at <Link href={`mailto:${APP.SUPPORT_EMAIL}`} variant="body2" target="_blank">support@ruc-caltrans.drive-tu.com</Link> or
            phone at <Link href={`tel:${APP.PHONE_DIGITS}`} variant="body2">{APP.PHONE_FORMAT}</Link>.
          </p>
        </div>
      </Container>
    </>
  );
};

PilotClosed.propTypes = {};
export default PilotClosed;
