import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from '@mobiletoll/mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../utilities/tables';

function BasicTable({ title, data, columns, options, themeProps }) {
  return (
    <MuiThemeProvider theme={theme(themeProps)}>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </MuiThemeProvider>
  );
}

BasicTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  options: PropTypes.object,
  nested: PropTypes.bool,
  themeProps: PropTypes.object,
  maxHeight: PropTypes.string,
};

export default BasicTable;
