import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import BasicTable from 'components/Table/BasicTable';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';
import LinkButton from 'components/Table/LinkButton';

const title = 'Events';
const description = '';

const ignoreDiffKey = [
  'createdAt', 'updatedAt',
  // 'updatedBy', 'createdBy',
];

const renderObjectData = (dataString = '') => {
  try {
    const data = typeof dataString === 'string' ? JSON.parse(dataString) : dataString;
    if (Array.isArray(data)) {
      return (<div>
        {data.map((item) => renderObjectData(item))}
      </div>);
    } else
    if (typeof data === 'object') {
      return (<div>
        {Object.keys(data).map((key)=>(
          <div key={key}><code>{key}: {`${data[key]}`}</code></div>
        ))}
      </div>);
    } else {
      throw new Error('Use plaing string renderering');
    }
  } catch (e) {
    // global.logger.debug(dataString, e);
    return <code>{`${dataString}`.replace(/"/g, '')}</code>;
  }
};

const parseEventData = (data = []) => {
  return data
    .sort(sortBy('timestamp', true))
    .filter(({ diff }) => {
      const validDiffs = diff.filter(({ key }) => !ignoreDiffKey.includes(key));
      return validDiffs.length > 0;
    })
    .map((item) => {
      const [table, partitionKey, sortKey] = item.key.split('__');
      item.table = table.split('-')[0];
      item.partitionKey = partitionKey;
      item.sortKey = sortKey;
      return item;
    });
};

const eventNames = ['INSERT', 'MODIFY', 'REMOVE'];

export default function EventTable({ data: inData, viewer = 'admin' }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
  };

  const columns = [
    {
      name: 'timestamp',
      label: 'Timestamp',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'key',
      label: 'Key',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'table',
      label: 'Table',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'partitionKey',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'sortKey',
      label: 'Sort Key',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'eventName',
      label: 'Event Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: 'Updated By',
      options: {
        filter: true,
        sort: true,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}?tab=Trips`}
              label="View participant details"
            />
          );
        },
      },
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        filter: false,
        sort: true,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}?tab=Trips`}
              label="View participant details"
            />
          );
        },

      },
    },
    {
      name: 'diff',
      label: 'Diff',
      options: {
        filter: false,
        sort: false,
        customBodyRender(value = []) {
          const diffs = value
            .filter(({ key }) => !ignoreDiffKey.includes(key))
            .sort(sortBy('key'));

          if (diffs.length === 0) return null;

          return (
            <BasicTable
              columns={[
                {
                  name: 'key',
                  label: 'Field',
                  options: {
                    filter: false,
                    sort: true,
                  },
                },
                {
                  name: 'old',
                  label: 'Old',
                  options: {
                    filter: false,
                    sort: true,
                    customBodyRender: renderObjectData,
                  },
                },
                {
                  name: 'new',
                  label: 'New',
                  options: {
                    filter: false,
                    sort: true,
                    customBodyRender: renderObjectData,
                  },
                },
              ]}
              options={{
                download: false,
                print: false,
                filter: false,
                resizableColumns: false,
                selectableRows: undefined,
                expandableRows: false,
                expandableRowsHeader: false,
                isRowExpandable: () => false,
                isRowSelectable: () => false,
                rowsPerPage: 100,
                rowsPerPageOptions: [100, 1000],
              }}
              data={diffs}
            />);
        },
      },
    },
  ];

  const onSubmit = async ({ from, to }) => {
    global.logger.debug('EventTable onSubmit');
    setIsLoading(true);

    let events = [];
    await Promise.all(eventNames.map(async (eventName) => {
      const data = await asyncListAll( /* GraphQL */ `
        query GetEventsByEventNameByTimestamp(
          $eventName: String
          $timestamp: ModelStringKeyConditionInput
          $sortDirection: ModelSortDirection
          $filter: ModelEventFilterInput
          $limit: Int
          $nextToken: String
        ) {
          getEventsByEventNameByTimestamp(
            eventName: $eventName
            timestamp: $timestamp
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              key
              timestamp
              eventId
              eventName
              diff {
                key
                old
                new
              }
              note
              username
              updatedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      `, {
        eventName,
        timestamp: {
          between: [from, to],
        },
      });

      events = [...events, ...data];
    }));


    setData(parseEventData(events));
    setIsLoading(false);
  };

  useEffect(() => {
    if (inData) {
      setData(parseEventData(inData));
      return;
    }
  }, [inData]);

  return (
    <React.Fragment>
      {!inData &&
      <DateRangeSelector
        unit='day'
        onSubmit={onSubmit}
        submitOnLoad={true}
        disabled={isLoading}
      />}

      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        themeProps={{ cell: { } }}
      />
    </React.Fragment>
  );
}

EventTable.propTypes = {
  data: PropTypes.array,
  viewer: PropTypes.string,
};
