/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

export default function DeviceExplanation({ preference, showTeslaAlert }) {
  return (
    <Box marginTop={4}>
      <Box marginBottom={2}>
        <Typography component="p" variant="body1" gutterBottom>
          Please select one of the mileage reporting options below.  If an icon is gray and can’t be chosen then the option is not available for the vehicle enrolled in the Pilot.
        </Typography>
        <ul>
          <li><Typography component="p" variant="body1" gutterBottom>Device with Location</Typography></li>
          <ul>
            <Typography component="p" variant="body1" gutterBottom>
              A device plugged into the vehicle dashboard that automatically reports the location and miles that you drive.  Public and private roads are known using GPS and only California public roads will be charged for this Pilot.  No trip routes are shared with California agencies or other third parties.
            </Typography>
          </ul>
          <li><Typography component="p" variant="body1" gutterBottom>Device without Location</Typography></li>
          <ul>
            <Typography component="p" variant="body1" gutterBottom>
              A device plugged into the vehicle dashboard that automatically reports total miles but does not report the location of where you drive.  All miles will be charged as California public roads for this Pilot.
            </Typography>
          </ul>
          <li><Typography component="p" variant="body1" gutterBottom>Vehicle Telematics</Typography></li>
          <ul>
            <Typography component="p" variant="body1" gutterBottom>
              You may be able to authorize the Road Charge Collection Pilot to automatically collect miles from your vehicle if you have an account with your auto maker (such as Tesla, GM OnStar, and others). You will be prompted to log in to your auto maker account and give odometer permissions. No location data is collected and all miles will be charged as California public roads for this Pilot.
            </Typography>
          </ul>
          <li>
            <Typography component="p" variant="body1" gutterBottom>Manual Odometer Entry</Typography>
          </li>
          <ul>
            <Typography component="p" variant="body1" gutterBottom>
              Participants who choose this option will submit a photo of the vehicle’s odometer once a month. All miles will be charged as California public roads for this Pilot.
            </Typography>
          </ul>
        </ul>
        {showTeslaAlert &&
          <Alert
            severity="info"
          >
            <Typography component="p" variant="body1" gutterBottom>The vehicle you have chosen to use for this program is manufactuered by <strong>Tesla Motors, Inc.</strong></Typography>
            <Typography component="p" variant="body1" gutterBottom>Tesla Motors, Inc. may require your consent to allow this program to collect the data required for participation.  If prompted, you must select only the &quot;Vehicle Information&quot; option and then you will be further prompted to restrict data access to VIN and Odometer data collection.</Typography>
          </Alert>
        }
      </Box>
    </Box>
  );
}

DeviceExplanation.propTypes = {
  preference: PropTypes.string,
  showTeslaAlert: PropTypes.bool,
};
