/* eslint-disable react/jsx-key */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import EnrollmentReport from './EnrollmentReport';
import CustomerInteractionReport from './CustomerInteractionReport';
import CustomerInteractionMetricsReport from './CustomerInteractionMetricsReport';
import PilotCloseoutReport from './PilotCloseoutReport';
import SummaryReport from './SummaryReportRevised';
import PaidTransactionsReport from './PaidTransactionsReport';
import RefundTransactionsReport from './RefundTransactionsReport';
// import MileageDiscrepancyReport from './MileageDiscrepancyReport/MileageDiscrepancyReport';

import CaltransReportTemplate from './caltransReports/ReportTemplate';
import MROErrorReport from './caltransReports/MROErrorReport';
import BillingPaymentAuditReport from './caltransReports/BillingPaymentAuditReport';
import BillingSummaryMonthlyTotalsReport from './caltransReports/BillingSummaryMonthlyTotalsReport';
import BillingSummaryMonthTripDetailsReport from './caltransReports/BillingSummaryMonthTripDetailsReport';
import MileageDiscrepancyReport from './caltransReports/MileageDiscrepancyReport';

import OperationalReportTemplate from './operationalReports/ReportTemplate';
import operationalReportManualProgram from './operationalReports/operationalReportManualProgram';
import operationalReportStatement from './operationalReports/operationalReportStatement';
import operationalReportAccountStatus from './operationalReports/operationalReportAccountStatus';
import operationalReportBillingTransactions from './operationalReports/operationalReportBillingTransactions';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    paddingTop: 16,
  },
}));

const caltransReports = [
  BillingPaymentAuditReport,
  BillingSummaryMonthlyTotalsReport,
  BillingSummaryMonthTripDetailsReport,
  MROErrorReport,
  MileageDiscrepancyReport,
];

const operationalReports = [
  operationalReportManualProgram,
  operationalReportStatement,
  operationalReportAccountStatus,
  operationalReportBillingTransactions,
];

export default function Report() {
  const classes = useStyles();

  const fixedReports = [
    <EnrollmentReport />,
    <CustomerInteractionReport />,
    <CustomerInteractionMetricsReport />,
    <PilotCloseoutReport />,
    <SummaryReport />,
    <PaidTransactionsReport />,
    <RefundTransactionsReport />,
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant='h5' component='h5'>
            Caltrans Reports
          </Typography>
        </Grid>

        {fixedReports.map((report, index) => (
          <Grid item xs={12} md={4} key={index}>
            {report}
          </Grid>
        ))}

        {caltransReports.map((props, index) => (
          <Grid item xs={12} md={4} key={index}>
            <CaltransReportTemplate {...props} />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant='h5' component='h5'>
            Operational Reports
          </Typography>
        </Grid>

        {operationalReports.map((props, index) => (
          <Grid item xs={12} md={4} key={index}>
            <OperationalReportTemplate {...props} />
          </Grid>
        ))}
      </Grid>

    </div>
  );
}
