import pLimit from 'p-limit';

import { asyncGet, asyncListAll, asyncListFirstItem } from 'utilities/graph';
import {
  listEvents,
  listPaymentCards,
  getTransactionsByTypeByStatus,
} from 'graphql/queries';
import {
  listEvent,
  getMroEventsByIsProcessedByDateTime,
  listParticipantStatements,
  listVehicles,
  listParticipants,
  listMileageReportingOptions,
  getMroEventsByMroDeviceByDateTime,
  listParticipantVehiclesWithReports,
} from './queries';

import {
  getQueryDates,
  getQueryDatesForMonths,
} from './helpers';

import { sortBy } from 'utilities/sorting';

const cache = {};

const listCacheData = async (func = asyncListAll, operationName, input = {}) => {
  const key = `${JSON.stringify({ operationName, input })}`;
  if (cache[key]) {
    return cache[key];
  }
  const result = await func(operationName, input);
  cache[key] = result;

  return result;
};


const listAllForDateRange = async ({ gqlQuery = '', params = () => { }, from, to }) => {
  const queryDates = getQueryDates(from, to);

  const limit = pLimit(10);

  let results = [];

  const process = queryDates.map(({ from, to }) => {
    return limit(async () => {
      const data = await listCacheData(asyncListAll, gqlQuery, params(from, to));
      results = [...results, ...data];
    });
  });

  await Promise.all(process);

  return results;
};

export const getTrip = async (tripId) => {
  const {
    data: {
      getTrip: tripData,
    },
  } = await listCacheData(asyncGet, /* GraphQL */ `
    query GetTrip($id: ID!) {
      getTrip(id: $id) {
        id
        tsStart
        tsEnd
        fuel
        fuelFeeCents
        mileageFeeCents
        mroDeviceSerialNumber
        mroId
        logs
      }
    }
  `, { id: tripId });

  return tripData;
};

export const getAllMroDevices = async () => {
  return listCacheData(asyncListAll, listMileageReportingOptions, {});
};

export const getAllPaymentCards = async () => {
  return listCacheData(asyncListAll, listPaymentCards, {});
};

export const getAllVehicles = async () => {
  return listCacheData(asyncListAll, listVehicles, {});
};

export const getAllParticipantVehiclesWithReports = async (username) => {
  return listCacheData(asyncListAll, listParticipantVehiclesWithReports, {
    username,
  });
};

export const getUnprocessedMroEvents = async (fromDate, toDate) => {
  return listCacheData(asyncListAll, getMroEventsByIsProcessedByDateTime, {
    isProcessed: 0,
    dateTime: { between: [fromDate, toDate] },
  });
};

export const getProcessedMroEvents = async (fromDate, toDate) => {
  return listAllForDateRange({
    from: fromDate,
    to: toDate,
    gqlQuery: getMroEventsByIsProcessedByDateTime,
    params: (from, to) => {
      return {
        isProcessed: 1,
        dateTime: { between: [from, to] },
      };
    },
  });
};

export const getParticipants = async (inQl, from, to) => {
  const participantQl = inQl || listParticipants;

  return listCacheData(asyncListAll, participantQl, {});
};

export const getMRODeviceEvents = async (input) => {
  return listCacheData(asyncListAll, getMroEventsByMroDeviceByDateTime, input);
};

// Since the report only needs the first and last data of the month.
// Query data backward from the end of month to improve performance
export const getMRODeviceFirstAndLastMroEventsOfEachMonths = async (deviceSerialNumber, startDate, endDate) => {
  const monthDates = getQueryDatesForMonths(startDate, endDate, true);

  const allMroEvents = [];
  await Promise.all(monthDates.map(async ({ dates = [] }) => {
    // First data of the month
    let hasFirstDataOfMonth = false;
    let firstDataQueryIndex = 0;

    while (!hasFirstDataOfMonth && firstDataQueryIndex < dates.length) {
      const { from, to } = dates[firstDataQueryIndex];

      const mroEvents = await getMRODeviceEvents({
        mroDeviceSerialNumber: deviceSerialNumber,
        dateTime: {
          between: [from, to],
        },
        filter: {
          source: { eq: 'api' },
          odometerReading: { ge: 0 },
        },
      });

      if (mroEvents.length > 0) {
        const [firstRecord] = mroEvents.sort(sortBy('dateTime'));
        allMroEvents.push(firstRecord);

        hasFirstDataOfMonth = true;
      } else {
        firstDataQueryIndex++;
      }
    }

    // Last data of the month
    let hasLastDataOfMonth = false;
    let lastDataQueryIndex = dates.length - 1;

    while (!hasLastDataOfMonth && lastDataQueryIndex >= 0) {
      const { from, to } = dates[lastDataQueryIndex];

      const mroEvents = await getMRODeviceEvents({
        mroDeviceSerialNumber: deviceSerialNumber,
        dateTime: {
          between: [from, to],
        },
        filter: {
          code: { eq: '99' }, // health report
          type: { eq: 'INFO' },
        },
      });

      if (mroEvents.length > 0) {
        const [lastRecord] = mroEvents.sort(sortBy('dateTime', true));
        allMroEvents.push(lastRecord);

        hasLastDataOfMonth = true;
      } else {
        lastDataQueryIndex--;
      }
    }
  }));

  return allMroEvents.sort(sortBy('dateTime'));
};

// return participant and vehicle events for each participant
export const getParticipantWithVehicleEvents = async (inQl, from, to) => {
  const item = await listCacheData(asyncListFirstItem, listEvents, {});

  const [, hash, env] = item.key.split('__')[0].split('-');
  const tableSuffix = `${hash}-${env}`;

  const participantQl = inQl || /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        status
        accountNo
        firstName
        lastName
        firstDataReceivedDate
        closedDate
        closedReason
        pilotProgram {
          shortName
        }
        flags {
          hasIntegrityViolation
          isBillingOverdue
          isBillingDefault
          isInactive
          isLegislator
          isVinMismatch
          isVIP
          isGovernmentEmployee
          isCaliforniaElected
          agreeGlobalParticipantAgreement
          agreeGlobalPrivacyPolicy
        }
        vehicles {
          items {
            id
            vin
            mroType
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
  const allParticipantsWithVehicles = await listCacheData(asyncListAll, participantQl, {});

  const limit = pLimit(20);

  const process = allParticipantsWithVehicles.map((participant) => {
    const { username, vehicles: { items: vehicles } } = participant;

    return limit(async () => {
      const participantEventKey = `Participant-${tableSuffix}__${username}__undefined`;

      let vehicleEvents = [];

      const [
        participantEvents,
      ] = await Promise.all([
        listCacheData(asyncListAll, listEvent, {
          key: participantEventKey,
          timestamp: {
            between: [from, to],
          },
        }),
        ...vehicles.map(async ({ id: vehicleId }) => {
          const vehicleEventKey = `Vehicle-${tableSuffix}__${username}__${vehicleId}`;
          const events = await listCacheData(asyncListAll, listEvent, {
            key: vehicleEventKey,
            timestamp: {
              between: [from, to],
            },
          });
          vehicleEvents = [...vehicleEvents, ...events];
        }),
      ]);

      return {
        ...participant,
        username,
        participantEvents,
        vehicles,
        vehicleEvents,
      };
    });
  });

  return Promise.all(process);
};

export const getAllTransactions = async () => {
  return listCacheData(asyncListAll, getTransactionsByTypeByStatus, {
    type: 'transaction',
  });
};


export const getAllSystemStatements = async () => {
  return listCacheData(asyncListAll, listParticipantStatements, {
    filter: { createdBy: { eq: 'System' } },
  });
};

export const getTripsByStatusByQueryDates = async (gqlQuery, processStatus, queryDates = []) => {
  let trips = [];

  const limit = pLimit(10);

  const process = queryDates.map(({ from, to }) => {
    return limit(async () => {
      const data = await listCacheData(asyncListAll, gqlQuery, {
        processStatus,
        createdAt: { between: [from, to] },
      });


      trips = [...trips, ...data];
    });
  });

  await Promise.all(process);

  return trips;
};

export const getTripSegmentsByPaymentStatusByQueryDates = async (gqlQuery, paymentStatus, queryDates = []) => {
  let tripSegments = [];

  const limit = pLimit(10);

  const process = queryDates.map(({ from, to }) => {
    return limit(async () => {
      const data = await listCacheData(asyncListAll, gqlQuery, {
        paymentStatus,
        createdAt: { between: [from, to] },
      });

      tripSegments = [...tripSegments, ...data];
    });
  });

  await Promise.all(process);

  return tripSegments;
};

export const getTripAdjustmentsByPaymentStatusByQueryDates = async (gqlQuery, paymentStatus, queryDates = []) => {
  let tripAdjustments = [];
  await Promise.all(queryDates.map(async ({ from, to }) => {
    const data = await listCacheData(asyncListAll, gqlQuery, {
      paymentStatus,
      createdAt: { between: [from, to] },
    });

    tripAdjustments = [...tripAdjustments, ...data];
  }));

  return tripAdjustments;
};

export const listAllTripsForDateRange = async (gqlQuery, from, to) => {
  const queryDates = getQueryDates(from, to);

  const processStatus = ['pending', 'processed'];

  const results = await Promise.all(processStatus.map((status) => {
    return getTripsByStatusByQueryDates(gqlQuery, status, queryDates);
  }));

  return results.reduce((arr, items) => {
    return [...arr, ...items];
  }, []);
};

const paymentStatuses = ['pending', 'billed', 'paid', 'failed', 'void'];

export const listAllTripSegmentsForDateRange = async (gqlQuery, from, to) => {
  const queryDates = getQueryDates(from, to);

  const results = await Promise.all(paymentStatuses.map((status) => {
    return getTripSegmentsByPaymentStatusByQueryDates(gqlQuery, status, queryDates);
  }));

  return results.reduce((arr, items) => {
    return [...arr, ...items];
  }, []);
};

export const listAllTripAdjustmentsForDateRange = async (gqlQuery, from, to) => {
  const results = await Promise.all(paymentStatuses.map((status) => {
    // adjustments are relatively low amount
    return listCacheData(asyncListAll, gqlQuery, {
      paymentStatus: status,
      createdAt: { between: [from, to] },
    });
  }));

  return results.reduce((arr, items) => {
    return [...arr, ...items];
  }, []);
};


