import React, { useState } from 'react';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import {
  simpleDate,
  formatMroType,
  formatVehicleFuelType,
  formatAccountStatus,
  formatRucGroupName,
} from 'utilities/format';
import {
  getParticipantInquirysByStatusByCreatedAt,
  getEventsByEventNameByTimestamp,
} from './graphql';
import {
  checkDuplicateRecordId,
  listAllForDateRange,
  getAllApprovalNotifications,
  getParticipantAppprovedAt,
} from './helpers';
import { APP } from 'utilities/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

// RUC-1158 Hard coded this for report purpose only.
const CSR_ID = '76295243-385c-49a2-9b42-fa21dc523d08';

const getInquiryFirstComment = (inquiry) => {
  const comments = inquiry.comments.items.sort((a, b) => {
    return moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1;
  });

  const firstComment = comments[0];
  return firstComment;
};

const getInquiryClosureDateAndComment = (inquiry) => {
  let closureComment = '';
  let closureDate = '';
  if (inquiry.status === 'closed') {
    const commentsReversed = inquiry.comments.items.sort((a, b) => {
      return moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1;
    });
    const lastAdminComment = commentsReversed.find((comment) => comment.author === 'admin');
    closureComment = lastAdminComment == undefined ? '' : lastAdminComment.body;
    closureDate = lastAdminComment == undefined ? '' : simpleDate(lastAdminComment.createdAt);

    closureDate = closureDate || inquiry.updatedAt;
  }

  return {
    closureDate,
    closureComment,
  };
};

export const getInquiryMode = (inquiry) => {
  if (inquiry.category === 'General Email Inquiry') {
    return 'email';
  }

  return 'UAP';
};

function mapFields(inquiry, participant, vehicle, approvedAt, vehicleEvent) {
  const firstComment = getInquiryFirstComment(inquiry);
  const mode = getInquiryMode(inquiry);
  const {
    closureDate,
    closureComment,
  } = getInquiryClosureDateAndComment(inquiry);

  return {
    'RecordID': inquiry.id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Address Line 1': participant.address.address1,
    'Address Line 2': participant.address.address2,
    'City': participant.address.city,
    'State': participant.address.state,
    'Zip Code': participant.address.postalCode + (participant.address.extendedPostalCode || ''),
    'Email Address': participant.email,
    'Telephone Number': participant.phoneNumber,
    'RUC Group': formatRucGroupName(participant.pilotProgram?.shortName),
    'VIP': participant?.flags?.isVIP || false,
    'Account Status': formatAccountStatus(participant.status),
    'Account Created Date': simpleDate(participant.createdAt),
    'Account Onboarded Date': simpleDate(participant.onboardedDate),
    'Account Approved Date': simpleDate(approvedAt),
    'Account Active Date': simpleDate(participant.firstDataReceivedDate),
    'VIN': vehicle.vin,
    'Vehicle Make': vehicle.make,
    'Vehicle Model': vehicle.model,
    'Vehicle Year': vehicle.year,
    'Vehicle Fuel Type': formatVehicleFuelType(vehicle.type),
    'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
    'VIN Added Date': simpleDate(vehicle.createdAt),
    'VIN Delete Date': simpleDate(vehicleEvent?.createdAt),
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': formatMroType(vehicle.mroType),
    'MRO Ship Date': simpleDate(vehicle.mro?.shippedDate),
    'MRO Trips Reported Date': simpleDate(participant.firstDataReceivedDate),
    'Issue Original ID': firstComment.id,
    'Issue ID': inquiry.id,
    'Issue Creator ID': CSR_ID,
    'Issue Created Date': simpleDate(inquiry.createdAt),
    'Issue Updater ID': CSR_ID,
    'Issue Initiation Mode': mode,
    'Issue Type': inquiry.category,
    'Issue Subject': inquiry.subject,
    'Issue Description': inquiry.body,
    'Issue Status': inquiry.status === 'closed' ? 'closed' : 'open',
    'Issue Close Date': simpleDate(closureDate),
    'Issue Resolution Comments': closureComment,
  };
}

function mapNoUserFields(inquiry) {
  const firstComment = getInquiryFirstComment(inquiry);
  const mode = getInquiryMode(inquiry);
  const {
    closureDate,
    closureComment,
  } = getInquiryClosureDateAndComment(inquiry);

  return {
    'RecordID': inquiry.id,
    'AccountID': '',
    'Last Name': '',
    'First Name': inquiry.name || '',
    'Address Line 1': '',
    'Address Line 2': '',
    'City': '',
    'State': '',
    'Zip Code': '',
    'Email Address': inquiry.email || '',
    'Telephone Number': '',
    'RUC Group': '',
    'VIP': '',
    'Account Status': '',
    'Account Created Date': '',
    'Account Onboarded Date': '',
    'Account Approved Date': '',
    'Account Active Date': '',
    'VIN': '',
    'Vehicle Make': '',
    'Vehicle Model': '',
    'Vehicle Year': '',
    'Vehicle Fuel Type': '',
    'Vehicle Fuel Economy': '',
    'VIN Added Date': '',
    'VIN Delete Date': '',
    'MRO ID': '',
    'MRO Type': '',
    'MRO Ship Date': '',
    'MRO Trips Reported Date': '',
    'Issue Original ID': firstComment.id,
    'Issue ID': inquiry.id,
    'Issue Creator ID': CSR_ID,
    'Issue Created Date': simpleDate(inquiry.createdAt),
    'Issue Updater ID': CSR_ID,
    'Issue Initiation Mode': mode,
    'Issue Type': inquiry.category,
    'Issue Subject': inquiry.subject,
    'Issue Description': inquiry.body,
    'Issue Status': inquiry.status === 'closed' ? 'closed' : 'open',
    'Issue Close Date': simpleDate(closureDate),
    'Issue Resolution Comments': closureComment,
  };
}

export default function CustomerInteractionReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);

      const fromDate = moment(from).tz(APP.TIME_ZONE).startOf('day').toISOString();
      const toDate = moment(to).tz(APP.TIME_ZONE).endOf('day').toISOString();

      const [
        newInquires,
        inProgressInquires,
        awaitingCustomerResponseInquires,
        responseReceivedInquires,
        awaitingInternalInquires,
        closedInquires,
        notifications,
        vehicleEventsRemoved,
      ] = await Promise.all([
        // get new inquiries to csr
        asyncListAll(getParticipantInquirysByStatusByCreatedAt, {
          status: 'new',
          createdAt: { between: [fromDate, toDate] },
        }),
        // get inProgress inquiries to csr
        asyncListAll(getParticipantInquirysByStatusByCreatedAt, {
          status: 'inProgress',
          createdAt: { between: [fromDate, toDate] },
        }),
        // get awaitingCustomerResponse inquiries to csr
        asyncListAll(getParticipantInquirysByStatusByCreatedAt, {
          status: 'awaitingCustomerResponse',
          createdAt: { between: [fromDate, toDate] },
        }),
        // get responseReceived inquiries to csr
        asyncListAll(getParticipantInquirysByStatusByCreatedAt, {
          status: 'responseReceived',
          createdAt: { between: [fromDate, toDate] },
        }),
        // get awaitingInternal inquiries to csr
        asyncListAll(getParticipantInquirysByStatusByCreatedAt, {
          status: 'awaitingInternal',
          createdAt: { between: [fromDate, toDate] },
        }),
        // get closed inquiries to csr
        asyncListAll(getParticipantInquirysByStatusByCreatedAt, {
          status: 'closed',
          createdAt: { between: [fromDate, toDate] },
        }),
        // get all update participant events
        getAllApprovalNotifications(fromDate, toDate),
        // get all delete vehicle events
        listAllForDateRange({
          from,
          to,
          gqlQuery: getEventsByEventNameByTimestamp,
          params: (from, to) => {
            return {
              eventName: 'REMOVE',
              timestamp: { between: [from, to] },
              filter: {
                key: { contains: 'Vehicle' },
              },
            };
          },
        }),
      ]);

      // combine all inquires
      const inquires = newInquires.concat(
        inProgressInquires,
        awaitingCustomerResponseInquires,
        responseReceivedInquires,
        awaitingInternalInquires,
        closedInquires);

      // store participant events
      const events = {};

      const data = inquires.map((inquiry) => {
        if (inquiry.participant) {
          const participant = inquiry.participant;
          const participantEvent = events[participant.username] || {};

          const approvedAt = getParticipantAppprovedAt({ participant, notifications });

          // only use the first vehicle since this is an inquiry-based report
          const vehicle = participant.vehicles.items[0] || {
            id: 'N/A',
            vin: '',
            make: '',
            model: '',
            year: '',
            type: '',
            epaVehicleCombinedMpg: '',
            createdAt: '',
            mro: { shippedDate: '' },
          };

          participantEvent[vehicle.id] = participantEvent[vehicle.id] ||
            vehicleEventsRemoved
              .filter(({ username }) => username === participant.username)
              .find(({ diff }) => {
                return diff.find(({ key, old }) => key === 'id' && old === `"${vehicle.id}"`);
              });

          events[participant.username] = participantEvent;

          return mapFields(inquiry, participant, vehicle, approvedAt, participantEvent[vehicle.id]);
        } else {
          return mapNoUserFields(inquiry);
        }
      });

      return data.flat();
    } catch (e) {
      global.logger.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Customer Interaction Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`CUSTOMER INTERACTION (Help Desk)_Date ${moment().format('MM/DD/YYYY')}.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                checkDuplicateRecordId(data);
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
