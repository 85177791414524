/* eslint-disable no-unreachable */

import { minus } from 'number-precision';
import pLimit from 'p-limit';
import moment from 'moment';

import {
  simpleDate,
  formatMroType,
  formatRucGroupName,
  formatMroTypeCertID,
  formatVehicleFuelType,
  formatMileage,
} from 'utilities/format';

import {
  getAllMroDevices,
  getMRODeviceFirstAndLastMroEventsOfEachMonths,
  getAllParticipantVehiclesWithReports,
} from './data';

const MILEAGE_UNIT = 'mi';

const QUERY_START_DATE = '2024-07-01T07:00:00.000Z';

const currentMonth = moment().format('YYYY-MM');
const lastDayOfPreviousMonth = moment().subtract(1, 'month').endOf('month').toISOString();

const months = ['2024-07', '2024-08', '2024-09', '2024-10', '2024-11', '2024-12', '2025-01', '2025-02']
  .filter((month) => month < currentMonth);

const targetAccountNos = [];

const getMonthData = () => {
  const monthData = {};
  months.forEach((month) => {
    monthData[month] = {
      month,
      mroStartingMileage: 0,
      mroStartingDate: '',
      mroEndingMileage: 0,
      mroEndingDate: '',
      vehicleStartingMileage: 0,
      vehicleStartingDate: '',
      vehicleEndingMileage: 0,
      vehicleEndingDate: '',
    };
  });

  return monthData;
};

function mapFields(vehicle, participant, mro, mroEvents) {
  vehicle.reports = vehicle.reports || [];
  const firstVehicleReport = vehicle.reports[0];
  const lastVehicleReport = vehicle.reports[vehicle.reports.length - 1];

  const reportData = {
    'RecordID': mro.id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'RUC Group': formatRucGroupName(participant.pilotProgram?.shortName),
    'VIP': participant.flags.isVIP ? 'True' : 'False',
    'VIN': vehicle.vin,
    'Vehicle Make': vehicle.make.toUpperCase(),
    'Vehicle Model': vehicle.model.toUpperCase(),
    'Vehicle Year': `${vehicle.year}`,
    'Vehicle Fuel Type': formatVehicleFuelType(vehicle.type),
    'VIN Addition Date': vehicle.createdAt ? simpleDate(vehicle.createdAt) : '',
    'Starting Odometer': vehicle.beginningOdometerReading, // some of the first report has odometer off
    'Starting Odometer Date': firstVehicleReport ? simpleDate(firstVehicleReport.tsReportDate) : '',
    'Final Odometer': vehicle.currentOdometerReading,
    'Final Odometer Date': lastVehicleReport ? simpleDate(lastVehicleReport.tsReportDate) : '',
    'VIN Delete Date': '',
    'MRO ID': mro.deviceSerialNumber,
    'MRO Type': formatMroType(vehicle.mroType),
    'MRO Cert ID': formatMroTypeCertID(vehicle.mroType),
  };

  const monthData = getMonthData();

  mroEvents
    .filter((x) => QUERY_START_DATE <= x.dateTime && x.dateTime <= lastDayOfPreviousMonth)
    .sort((a, b) => a.dateTime > b.dateTime ? 1 : -1)
    .forEach((mroEvent) => {
      const {
        dateTime,
        odometerReading,
        odometerReadingStart,
      } = mroEvent;
      const month = moment(dateTime).format('YYYY-MM');

      const data = monthData[month];

      data.mroStartingMileage = data.mroStartingMileage || odometerReadingStart || odometerReading || 0;
      data.mroStartingDate = data.mroStartingDate || dateTime;

      data.mroEndingMileage = odometerReading || 0;
      data.mroEndingDate = dateTime;

      monthData[month] = data;
    });

  const filteredVehicleReports = vehicle.reports.filter((x) => x.tsReportDate >= QUERY_START_DATE);

  filteredVehicleReports
    .filter((x) => QUERY_START_DATE <= x.tsReportDate && x.tsReportDate <= lastDayOfPreviousMonth)
    .sort((a, b) => a.tsReportDate > b.tsReportDate ? 1 : -1)
    .forEach((vehicleReport, index) => {
      const { odometer, tsReportDate } = vehicleReport;

      const previousOdometer = index === 0 ? odometer : filteredVehicleReports[index - 1].odometer;
      const month = moment(tsReportDate).format('YYYY-MM');
      const data = monthData[month];

      // use the previous month starting point
      data.vehicleStartingMileage = parseFloat(data.vehicleStartingMileage || previousOdometer);
      data.vehicleStartingDate = data.vehicleStartingDate || tsReportDate;

      data.vehicleEndingMileage = parseFloat(odometer);
      data.vehicleEndingDate = tsReportDate;

      monthData[month] = data;
    });

  const currentMonth = moment().format('YYYY-MM');

  Object.values(monthData).forEach((data, index) => {
    const num = index;

    const {
      month,
      mroStartingMileage,
      mroStartingDate,
      mroEndingMileage,
      mroEndingDate,
      vehicleStartingMileage,
      vehicleStartingDate,
      vehicleEndingMileage,
      vehicleEndingDate,
    } = data;

    const mroTotal = minus(mroEndingMileage, mroStartingMileage);
    const odoTotal = minus(vehicleEndingMileage, vehicleStartingMileage);
    const offsetTotal = minus(odoTotal, mroTotal);

    // console.log({
    //   month,
    //   mroTotal,
    //   odoTotal,
    //   offsetTotal,
    //   vehicle, participant, mro, mroEvents, data,
    // });

    const issueDate = month === currentMonth ? moment() :
      moment(month).add(1, 'months').startOf('month').add(1, 'days');

    Object.assign(reportData, {
      [`M${num}_Stmt Issue Date`]: simpleDate(issueDate),
      [`M${num}_MRO (PID) Starting Date`]: mroStartingDate ? simpleDate(mroStartingDate) : '',
      [`M${num}_MRO (PID) Ending Date`]: mroEndingDate ? simpleDate(mroEndingDate) : '',
      // [`M${num}_MRO Starting Mileage`]: formatMileage(mroStartingMileage, MILEAGE_UNIT), // debug use
      // [`M${num}_MRO Ending Mileage`]: formatMileage(mroEndingMileage, MILEAGE_UNIT), // debug use
      [`M${num}_MRO (PID) Total Miles`]: formatMileage(mroTotal, MILEAGE_UNIT),
      [`M${num}_ODO (VEH) Starting ODO Reading`]: formatMileage(vehicleStartingMileage, MILEAGE_UNIT),
      [`M${num}_ODO (VEH) Starting Date`]: vehicleStartingDate ? simpleDate(vehicleStartingDate) : '',
      [`M${num}_ODO (VEH) Ending ODO Reading`]: formatMileage(vehicleEndingMileage, MILEAGE_UNIT),
      [`M${num}_ODO (VEH) Ending Date`]: vehicleEndingDate ? simpleDate(vehicleEndingDate) : '',
      [`M${num}_ODO (VEH) Total Miles`]: formatMileage(odoTotal, MILEAGE_UNIT),
      [`M${num}_Offset Miles`]: formatMileage(offsetTotal, MILEAGE_UNIT),
    });
  });

  return reportData;
}

export default {
  name: 'Mileage Discrepancy Report',
  filename: 'MILEAGE DISCREPANCY',
  useDateRange: false,
  async process() {
    const [
      mros,
      allVehicles,
    ] = await Promise.all([
      getAllMroDevices(),
      getAllParticipantVehiclesWithReports(),
    ]);

    const toQueryData = [];

    mros.forEach((mro) => {
      const { username, participant } = mro;

      if (targetAccountNos.length > 0 && !targetAccountNos.includes(participant.accountNo)) {
        return null;
      }

      // do not include closed account
      if (participant.status === 'closed') {
        return null;
      }

      // RUC-1298 The same device could be attached to another vehicle before.
      const vehicles = allVehicles.filter((v) => v.username === username);

      vehicles.forEach((vehicle) => {
        if (!vehicle.reports || vehicle.reports.length === 0) return;

        toQueryData.push({
          mro,
          vehicle,
          participant,
        });
      });
    });

    const result = [];

    const limit = pLimit(10);

    const handleProcess = async ({ mro, vehicle, participant }) => {
      return limit(async () => {
        try {
          const { deviceSerialNumber } = mro;

          const { firstDataReceivedDate, lastReportedVinDate } = vehicle;

          // compare to the data in vehicle reports
          const { tsReportDate: firstDateFromReport } = vehicle.reports[0];
          const { tsReportDate: lastDateFromReport } = vehicle.reports[vehicle.reports.length - 1];

          let startDate = firstDataReceivedDate || firstDateFromReport;
          let endDate = lastReportedVinDate || lastDateFromReport;

          if (firstDateFromReport < startDate) {
            startDate = firstDateFromReport;
          }

          if (lastDateFromReport > endDate) {
            endDate = lastDateFromReport;
          }

          if (endDate <= startDate || !startDate || !endDate) {
            console.error({ deviceSerialNumber, mro, vehicle, startDate, endDate });
          }

          const mroEvents = await getMRODeviceFirstAndLastMroEventsOfEachMonths(
            deviceSerialNumber,
            startDate,
            endDate,
          );

          // console.log({
          //   mro, vehicle,
          //   deviceSerialNumber,
          //   mroEvents,
          //   startDate,
          //   endDate,
          // });

          if (mroEvents.length > 0) {
            const data = mapFields(vehicle, participant, mro, mroEvents);
            result.push(data);
          }
        } catch (e) {
          console.log(e);
          global.logger.error(e, mro);
        }
      });
    };

    await Promise.all(toQueryData.map(handleProcess));

    return result.filter((x) => x);
  },
};
