import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import VerticalTabs from 'components/Tab/VerticalTabs';

import {
  getTransactionsByStatementIdByCreatedAt,
  getTripSegmentsByUsernameByCreatedAt,
  getTripAdjustmnentsByParticipantByCreatedAt,
} from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import TransactionsTable from './TransactionsTable';
import TripsTable from './TripsTable';
import TripSegmentsTable from './TripSegmentsTable';
import TripAdjustmentsTable from './TripAdjustmentsTable';

import {
  getTripsByUsernameByCreatedAt,
} from '../Participant/components/queries';

function StatementDetails({ statement, viewer, nested }) {
  const [isLoading, setIsLoading] = useState(false);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (!statement || !statement.id) return;

    (async () => {
      setIsLoading(true);

      const {
        id: statementId,
        username,
        periodFrom,
        periodTo,
      } = statement;

      const [
        transactions,
        trips,
        tripSegments,
        tripAdjustments,
      ] = await Promise.all([
        asyncListAll(getTransactionsByStatementIdByCreatedAt, { statementId }, { bypassCache: true }),
        asyncListAll(getTripsByUsernameByCreatedAt, {
          username,
          createdAt: {
            between: [periodFrom, periodTo],
          },
        }, { bypassCache: true }),
        asyncListAll(getTripSegmentsByUsernameByCreatedAt, {
          username,
          createdAt: {
            between: [periodFrom, periodTo],
          },
        }, { bypassCache: true }),
        asyncListAll(getTripAdjustmnentsByParticipantByCreatedAt, {
          username,
          createdAt: {
            between: [periodFrom, periodTo],
          },
        }, { bypassCache: true }),
      ]);

      const inclusiveSegments = tripSegments.filter(({ transactionId }) => {
        return transactions.find(({ id }) => {
          return id === transactionId;
        }) !== undefined;
      });

      const inclusiveTrips = trips.filter(({ id }) => {
        return inclusiveSegments.find(({ tripId }) => {
          return tripId === id;
        }) !== undefined;
      });

      setTabs([
        {
          label: 'Transactions',
          component: <TransactionsTable
            data={transactions}
            viewer={viewer}
            nested={nested}
          />,
        },
        {
          label: 'Trips',
          component: <TripsTable
            data={inclusiveTrips}
            viewer={viewer}
            nested={nested}
          />,
        },
        {
          label: 'Trip Segments',
          component: <TripSegmentsTable
            data={inclusiveSegments}
            viewer={viewer}
            nested={nested}
          />,
        },
        {
          label: 'Trip Adjustments',
          component: <TripAdjustmentsTable
            data={tripAdjustments}
            viewer={viewer}
            nested={nested}
          />,
        },
      ]);

      setIsLoading(false);
    })();
  }, [statement]);

  if (isLoading) {
    return (
      <Grid container justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <VerticalTabs
      tabs={tabs}
      isNested={nested}
    />
  );
}

StatementDetails.propTypes = {
  statement: PropTypes.object,
  viewer: PropTypes.string,
  nested: PropTypes.bool,
};

export default StatementDetails;
