import { Logger } from 'aws-amplify';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

const DEBUG_LEVEL = localStorage.getItem('DEBUG_LEVEL');

const logLevel = DEBUG_LEVEL || (process.env.NODE_ENV === 'development' ? 'DEBUG' : 'ERROR');

global.logger = new Logger('ruc-caltrans', logLevel);

// Disable warning message
console.disableYellowBox = true; /* eslint-disable-line no-console */
console.ignoredYellowBox = ['Setting a timer']; /* eslint-disable-line no-console */

// Disabled react dev tools
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}
