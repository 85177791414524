import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import { asyncRetryMutation } from 'utilities/graph';
import { previewEmail } from 'graphql/mutations';

export default function EmailTemplatePreviewButton({ template = {}, onUpdate }) {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (!isOpen) {
      setData();
      return;
    }

    (async () => {
      const { data: { previewEmail: { result } } } = await asyncRetryMutation(previewEmail, {
        input: {
          emailTemplateKey: template.key,
          // participantUsername,
        },
      });
      const data = JSON.parse(result);

      setData(data);
    })();
  }, [isOpen, template]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogContent>
          {data &&
          <Grid container spacing={2} style={{ midWith: 480, padding: 16 }}>
            <div><strong>Subject: {data.subject}</strong></div>
            <div dangerouslySetInnerHTML={{ __html: data.html.replace('cid:header', '/images/caltrans-logo.png') }} />
          </Grid>}
        </DialogContent>
      </Dialog>
      <Tooltip
        title={'Preview Email Template Withe Data'}
        placement="bottom"
      >
        <IconButton
          aria-label={'flag'}
          size={'small'}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

EmailTemplatePreviewButton.propTypes = {
  template: PropTypes.object,
  onUpdate: PropTypes.func,
};
