import { createMuiTheme } from '@material-ui/core/styles';

export const simpleDataTable = {
  filter: false,
  pagination: false,
  print: false,
  download: false,
  search: false,
  viewColumns: false,
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  fixedHeader: false,
  fixedSelectColumn: false,
  responsive: 'stacked',
};

export const theme = (props = {}) => {
  const { cell = {} } = props;
  const cellStyle = Object.assign({
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    minWidth: 64,
  }, cell);

  return createMuiTheme({
    overrides: {
      MUIDataTable: {},
      MUIDataTableHeadCell: {
        root: {
          ...cellStyle,
          fontWeight: 'bold',
        },
      },
      MUIDataTableBodyCell: {
        root: cellStyle,
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: 'hidden',
        },
      },
      MUIDataTableFilter: {
        root: {
          minWidth: 400,
        },
      },
    },
  });
};
