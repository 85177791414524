import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DriveEtaSharpIcon from '@material-ui/icons/DriveEtaSharp';
import CompareArrowsSharpIcon from '@material-ui/icons/CompareArrowsSharp';
import ReceiptSharpIcon from '@material-ui/icons/ReceiptSharp';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function TransactionsSummary({
  transactions,
}) {
  const classes = useStyles();
  const aggregate = transactions.reduce((acc, next) => {
    const {
      amountCents = 0, tripSegments = [], tripAdjustments = [] } = next;
    return {
      totalBalance: acc.totalBalance += amountCents,
      totalSegments: acc.totalSegments += tripSegments.length,
      totalAdjustments: acc.totalAdjustments += tripAdjustments.length,
    };
  }, {
    totalBalance: 0,
    totalSegments: 0,
    totalAdjustments: 0,
  });
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item>
            <Chip
              icon={<AttachMoneyIcon />}
              label={'Total Fees: ' + (aggregate.totalBalance / 100).toFixed(2)}
              color="primary"
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<ReceiptSharpIcon />}
              label={'Total Transactions: ' + transactions.length}
              color="secondary"
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<DriveEtaSharpIcon />}
              label={'Total Segments: ' + aggregate.totalSegments}
              color="secondary"
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<CompareArrowsSharpIcon />}
              label={'Total Adjustments: ' + aggregate.totalAdjustments}
              color="secondary"
            />
          </Grid>
        </Grid>
      </Paper>
    </div >
  );
}

TransactionsSummary.propTypes = {
  transactions: PropTypes.array,
};
