import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import LinkButton from 'components/Table/LinkButton';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import Trip from 'pages/Admin/Trip';
import TripReprocessButton from 'pages/Admin/components/TripReprocessButton';
import TripReviewButton from './TripReviewButton';

import { auditReasons } from 'utilities/constants';

function TripsReviewTable({
  data: inData,
  title = 'Trips Review',
  description = '',
  viewer = 'admin',
  nested = false,
}) {
  const [data, setData] = useState(inData);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'reviewStatus',
      label: 'Audit',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite(dataIndex) {
          const trip = data ? data[dataIndex] : null;
          return (
            <TripReviewButton
              trip={trip}
              onUpdate={(updatedTrip) => {
                data[dataIndex] = updatedTrip;
                setData([...data]);
              }}
            />
          );
        },
      },
    },
    {
      name: 'reviewReason',
      label: 'Reason',
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender(reason) {
          return auditReasons[reason];
        },
      },
    },
    {
      name: 'mroId',
      label: 'MRO ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'mroDeviceSerialNumber',
      label: 'MRO Device #',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'vehicleVin',
      label: 'VIN',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'vehicleId',
      label: 'Vehicle ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'processStatus',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'processMsg',
      label: 'Status Msg',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'tsStart',
      label: 'Start',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'tsEnd',
      label: 'End',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'distMro',
      label: 'MRO: Distance',
      type: 'mileage',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'odoMro',
      label: 'MRO: Odometer',
      type: 'mileage',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'distGps',
      label: 'GPS: Distance',
      type: 'mileage',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'distRoute',
      label: 'Route: Distance',
      type: 'mileage',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fuel',
      label: 'Fuel',
      type: 'fuel',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'Participant',
      options: {
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}?tab=Trips`}
              label="View participant details"
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Details',
      options: {
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/trip/${id}`}
              label="View trip details"
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Review',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          const trip = data ? data[dataIndex] : null;
          return (
            <TripReprocessButton
              trip={trip}
              onUpdate={(updatedTrip) => {
                data[dataIndex] = updatedTrip;
                setData([...data]);
              }}
            />
          );
        },
      },
    },
  ];

  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
    expandableRows: true,
    isRowExpandable: () => nested ? false : true,
    renderExpandableRow(rowData, rowMeta) {
      const { id } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <Trip id={id} viewer={viewer} />
        </NestedTableContainer>
      );
    },
  };

  return (
    <Table
      title={title}
      description={description}
      data={inData}
      columns={columns}
      options={options}
    />
  );
}

TripsReviewTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  viewer: PropTypes.string,
  nested: PropTypes.bool,
};

export default TripsReviewTable;
