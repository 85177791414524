import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import { inquiryStatuses } from 'utilities/constants';
import { updateParticipantInquiry } from 'graphql/mutations';
import { listParticipantInquirys } from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  kanban: {
    width: '1800px',
  },
  dropContainer: {
    height: 'calc(100vh - 180px)',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dropContainerHeading: {
    fontSize: '1rem',
    fontWeight: '600',
  },
  inquirySubject: {
    fontSize: '13px',
    fontWeight: '700',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  inquiryUpdated: {
    fontSize: '11px',
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function Inquiries() {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [inquiries, setInquiries] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const inquiries = await asyncListAll(listParticipantInquirys);
        setInquiries(inquiries.map((inquiry) => {
          return inquiry;
        }).sort(sortBy('updatedAt', true)));
      } catch (e) {
        global.logger.warn(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  async function updateInquiryStatus(result) {
    const {
      draggableId: inquiryId,
      source,
      destination,
    } = result;

    if (!destination) {
      return;
    }

    const {
      droppableId: sourceStatus,
    } = source;
    const {
      droppableId: destinationStatus,
    } = destination;

    if (source.droppableId !== destination.droppableId) {
      // move it move it
      global.logger.debug(inquiryId, 'from', sourceStatus, 'to', destinationStatus);
      setInquiries(inquiries.map((inquiry) => {
        if (inquiry.id === inquiryId) {
          inquiry.status = destinationStatus;
        }
        return inquiry;
      }));

      try {
        await asyncRetryMutation(updateParticipantInquiry, {
          input: {
            id: inquiryId,
            status: destinationStatus,
            updatedBy: localStorage.getItem('ruc:username'),
          },
        });
      } catch (e) {
        global.logger.warn(e);
      }
    }
  }

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.kanban}>
        <DragDropContext onDragEnd={updateInquiryStatus}>
          {Object.keys(inquiryStatuses).map((inquiryStatus, index) => {
            return (
              <Grid item xs={2} key={index}>
                <Droppable
                  key={index}
                  droppableId={inquiryStatus}
                  direction="horizontal"
                >
                  {(provided, snapshot) => (
                    <Paper
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={classes.dropContainer}
                      style={{ backgroundColor: snapshot.isDraggingOver ? '#FFEBE6' : '#E3E3E3' }}
                    >
                      <Typography variant="h6" className={classes.dropContainerHeading}>{inquiryStatuses[inquiryStatus]}</Typography>
                      {inquiries.filter(({ status }) => status === inquiryStatus).map((inquiry, index) => {
                        return (
                          <Draggable
                            key={inquiry.id}
                            draggableId={inquiry.id}
                            index={index}
                            disableInteractiveElementBlocking={true}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card className={classes.card} onClick={() => {
                                  history.push(`/inquiry/${inquiry.id}`);
                                }}>
                                  <CardContent>
                                    <Grid container alignItems="center">
                                      <Grid item xs={8}>
                                        <Typography gutterBottom variant="h6" className={classes.inquirySubject}>
                                          {inquiry.subject || 'no subject'}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography gutterBottom color="textSecondary" variant="body2" className={classes.inquiryUpdated}>
                                          {moment(inquiry.updatedAt).fromNow()}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Divider variant="middle" className={classes.divider} />
                                    <Typography color="textSecondary" variant="body2">
                                      {`${inquiry.body.slice(0, 120)}${inquiry.body.length > 120 ? '...' : ''}`}
                                    </Typography>
                                    <Divider variant="middle" className={classes.divider} />
                                    <Chip
                                      size="small"
                                      color="primary"
                                      label={inquiry.category}
                                      className={classes.chip}
                                    />
                                    <Chip
                                      size="small"
                                      label={moment(inquiry.createdAt).format('MM/DD/YYYY h:mm a')}
                                      className={classes.chip}
                                    />
                                  </CardContent>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Paper>
                  )}
                </Droppable>
              </Grid>
            );
          })}
        </DragDropContext>
      </Grid>
    </div>
  );
}
