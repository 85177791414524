import React from 'react';
import { sortBy } from 'utilities/sorting';

import {
  simpleDate,
  convertMileage,
  formatMroType,
  formatRucGroupName,
  formatMroTypeCertID,
} from 'utilities/format';

import {
  getParticipants,
  getUnprocessedMroEvents,
  getProcessedMroEvents,
  getAllMroDevices,
} from './data';

import {
  participantQl,
  getCode,
} from './MROErrorReport.helpers';

const ignoreCodes = [
  99999,
];

export default {
  name: 'MRO Errors Report',
  filename: 'MRO ERRORS',
  description: (
    <span>
      Monthly report summarizing errors pertaining to MROs logged by the system
      and the corresponding details for each account and vehicle enrolled and
      active in the CA SB339 RCC pilot.
    </span>
  ),
  useDateRange: true,
  async process(fromDate, toDate) {
    const [
      allParticipantsWithVehicleEvents,
      unprocessedMroEvents,
      processedMroEvents,
      allMroDevices,
    ] = await Promise.all([
      getParticipants(participantQl),
      getUnprocessedMroEvents(fromDate, toDate),
      getProcessedMroEvents(fromDate, toDate),
      getAllMroDevices(),
    ]);

    const mroEvents = processedMroEvents.concat(unprocessedMroEvents);

    const data = mroEvents
      .sort(sortBy('createdAt'))
      .map((mroEvent) => {
        const {
          id,
          username,
          vehicleId,
          createdAt,
          description,
          tripId,
          mileage,
          // source,
          mroDeviceSerialNumber,
        } = mroEvent;

        const code = getCode(mroEvent);

        if (!code) console.error(mroEvent.description, mroEvent);

        if (ignoreCodes.includes(code)) return;

        const participant = allParticipantsWithVehicleEvents.find((p) => p.username === username);
        const matchedMro = allMroDevices.find(({ deviceSerialNumber }) => deviceSerialNumber === mroDeviceSerialNumber);

        if (!participant) {
          // ignore if not on the mro device list
          if (!matchedMro) return null;

          return {
            'Record ID': id,
            'Account ID': '',
            'Last Name': '',
            'First Name': '',
            'RUC Group': '',
            'VIP': false,
            'VIN': '',
            'MRO ID': mroDeviceSerialNumber,
            'MRO Type': '',
            'MRO Cert ID': '',
            'Triggering Entity': tripId ? 'Trip' : '',
            'Triggering Entity ID': tripId || '',
            'Status Change ID': '',
            'Error-Event ID': id || '',
            'Error-Event Date': simpleDate(createdAt),
            'Error-Event Code': code,
            'Error-Event Description': description || '',
            'Error-Event Total Miles on Date': mileage ? convertMileage(mileage, 'mi') : '',
          };
        }

        const {
          accountNo,
          lastName,
          firstName,
          flags: {
            isVIP,
          },
          vehicles: {
            items: vehicles,
          },
        } = participant;

        let vehicle = vehicles.find(({ id }) => id === vehicleId);
        if (!vehicle) {
          if (matchedMro) {
            vehicle = vehicles.find(({ id }) => id === matchedMro.vehicleId);
          }
        }

        if (!vehicle) {
          if (vehicles[0]) {
            vehicle = vehicles[0];
          } else {
            console.log('no matched vehicle', mroEvent);
          }
        }

        const {
          vin,
          mroType,
        } = vehicle || {};

        return {
          'Record ID': id,
          'Account ID': accountNo,
          'Last Name': lastName,
          'First Name': firstName,
          'RUC Group': formatRucGroupName(participant.pilotProgram.shortName),
          'VIP': isVIP || false,
          'VIN': vin || '',
          'MRO ID': mroDeviceSerialNumber,
          'MRO Type': formatMroType(mroType),
          'MRO Cert ID': formatMroTypeCertID(mroType),
          'Triggering Entity': tripId ? 'Trip' : '',
          'Triggering Entity ID': tripId || '',
          'Status Change ID': '',
          'Error-Event ID': id || '',
          'Error-Event Date': simpleDate(createdAt),
          'Error-Event Code': code,
          'Error-Event Description': description || '',
          'Error-Event Total Miles on Date': mileage ? convertMileage(mileage, 'mi') : '',
        };
      });

    return data.filter((x) => x);
  },
};
