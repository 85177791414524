import { Storage } from 'aws-amplify';
import heic2any from 'heic2any';

const cache = {};

export const getImageUrl = async (imgS3Key) => {
  if (cache[imgS3Key]) {
    return cache[imgS3Key];
  }

  const result = await Storage.get(imgS3Key, { level: 'public' });

  if (imgS3Key.endsWith('.heic')) {
    try {
      const res = await fetch(result);
      const blob = await res.blob();
      const conversionResult = await heic2any({ blob });
      const url = URL.createObjectURL(conversionResult);

      cache[imgS3Key] = url;

      return url;
    } catch (e) {
      global.logger.debug(e);
      return null;
    }
  } else {
    cache[imgS3Key] = result;
    return result;
  }
};
