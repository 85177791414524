import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SelectPaymentMethod from './components/SelectPaymentMethod';
import NewPaymentMethod from './components/NewPaymentMethod';
import { getTransaction } from 'graphql/queries';
import { updateTransaction } from './graphql';
import { asyncGet, asyncRetryMutation } from 'utilities/graph';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';
import { formatCurrency } from 'utilities/format';
import { useStyles } from './styles';

const Payment = ({
  user,
  computedMatch,
  transactionIds: inTransactionIds,
  onComplete,
}) => {
  const classes = useStyles();
  const history = useHistory();

  // states
  const [error, setError] = useState(false);
  const [transactionIds, setTransactionIds] = useState(inTransactionIds);
  const [transactions, setTransactions] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();

  const [isCompleted, setIsCompleted] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState();

  const { username } = user;

  useEffect(() => {
    if (computedMatch) {
      const { params: { transactionIds: computedTransactionIds } } = computedMatch;
      setTransactionIds(computedTransactionIds.split(';'));
    }
  }, [computedMatch]);

  useEffect(() => {
    if (!transactionIds) return;

    (async () => {
      try {
        const loadedTransactions = await Promise.all(transactionIds.map((id) => {
          return asyncGet(getTransaction, { id }, { bypassCache: true });
        }));

        setTransactions(loadedTransactions.map((loadedTransaction) => {
          return loadedTransaction.data.getTransaction;
        }));
      } catch (e) {
        global.logger.warn(e);
        setError(e.message);
      }
    })();
  }, [transactionIds]);

  // is billing enabled
  const billingFeatures = systemBillingFeatures(localStorage);
  if (!billingFeatures.allowed) {
    return history.push('/dashboard');
  }

  const reset = () => {
    setPaymentMethod();
    setIsPaymentFailed();
    setIsCompleted(false);
  };

  const handleCloseError = () => {
    setError(false);
  };

  const handleClose = () => {
    if (computedMatch) {
      return history.push('/dashboard');
    }

    if (onComplete) {
      onComplete();
    }
  };

  const confirmPayment = async () => {
    setLoading(true);

    const unpaidTransactions = transactions.filter(({ status }) => status === 'created' || status === 'failed');
    if (!unpaidTransactions.length) {
      setError('There are no unpaid transactions');
      return;
    }

    try {
      await Promise.all(unpaidTransactions.map((transaction) => {
        delete transaction.participant;
        delete transaction.tripAdjustments;
        delete transaction.tripSegments;
        return asyncRetryMutation(updateTransaction, {
          input: Object.assign({ ...transaction }, {
            status: 'pending',
            paymentMethodId: paymentMethod.id,
          }),
        });
      }));

      // wait for the result to be completed
      let isPaymentStillProcessing = true;
      let isPaymentFailed = false;
      while (isPaymentStillProcessing) {
        await new Promise((resolve) => setTimeout(resolve, 2000));

        const updatedTransactions = await Promise.all(unpaidTransactions.map(({ id }) => {
          return asyncGet(getTransaction, { id }, { bypassCache: true });
        }));

        isPaymentStillProcessing = updatedTransactions.some((tx) => {
          return tx.data.getTransaction.status === 'pending';
        });

        isPaymentFailed = updatedTransactions.some((tx) => {
          return tx.data.getTransaction.status === 'failed';
        });
      }

      setIsCompleted(true);
      setIsPaymentFailed(isPaymentFailed);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getUnpaidBalance = () => {
    const amountInCents = transactions.filter(({ status }) => status === 'created' || status === 'failed').reduce((acc, next) => {
      return acc + next.amountCents;
    }, 0);

    return formatCurrency(amountInCents);
  };

  if (!transactionIds || !transactions) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <Container component="main">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <CreditCardOutlinedIcon color="inherit" />
        </Avatar>
        <Typography component="h1" variant="h5">Make A Payment</Typography>
        <Typography component="h6" variant="h6"><strong>Balance: {getUnpaidBalance()}</strong></Typography>
        {!loading && !paymentMethod && (
          <>
            <SelectPaymentMethod
              username={username}
              billingFeatures={billingFeatures}
              classes={classes}
              onPaymentSelected={(method) => setPaymentMethod(method)}
            />
            <NewPaymentMethod
              username={username}
              billingFeatures={billingFeatures}
              classes={classes}
              onPaymentSelected={(method) => setPaymentMethod(method)}
            />
          </>
        )}
        {paymentMethod &&
          <Grid container spacing={2} justify="center" style={{ padding: 32 }}>
            <Grid item xs={12} container justify="center">
              <Typography component="h6" variant="h6">
                {paymentMethod.alias || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} container justify="center">
              <Typography component="h6" variant="h6">
                {`${paymentMethod.brand.toUpperCase()} **** **** **** ${paymentMethod.last4.toString().padStart(4, '0')}`}
              </Typography>
            </Grid>
            <Grid item xs={12} container justify="center">
              <Typography component="h6" variant="h6">
                {`Expires on ${paymentMethod.expirationMonth}/${paymentMethod.expirationYear}`}
              </Typography>
            </Grid>
            {!isCompleted && <Grid item xs={12} container justify="center">
              <Button onClick={() => setPaymentMethod()} disabled={loading}>
                Cancel
              </Button>
              <div style={{ width: 50 }}></div>
              <Button variant="contained" color="primary" onClick={confirmPayment} disabled={loading}>
                Confirm
              </Button>
            </Grid>}

            {isPaymentFailed === true &&
              <Grid item xs={12} container justify="center" spacing={2}>
                <Grid item xs={12} container justify="center">
                  <Alert severity="error">
                    <Typography component="h6" variant="h6" color="primary">
                      Your payment failed.
                    </Typography>
                  </Alert>
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Button onClick={() => reset()} disabled={loading}>
                    Change payment
                  </Button>
                  <div style={{ width: 50 }}></div>
                  <Button variant="contained" color="primary" onClick={handleClose} disabled={loading}>
                    Return to dashboard
                  </Button>
                </Grid>
              </Grid>
            }

            {isPaymentFailed === false &&
              <Grid item xs={12} container justify="center" spacing={2}>
                <Grid item xs={12} container justify="center">
                  <Alert color="info">
                    <Typography component="h6" variant="h6" color="primary">
                      Your payment was successful.
                    </Typography>
                  </Alert>
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Button variant="contained" color="primary" onClick={handleClose} disabled={loading}>
                    Return to dashboard
                  </Button>
                </Grid>
              </Grid>}
          </Grid>}

        <Snackbar
          open={error != false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

Payment.propTypes = {
  user: PropTypes.object,
  computedMatch: PropTypes.object,
  transactionIds: PropTypes.array,
  onComplete: PropTypes.func,
};

export default Payment;
