import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  secondaryAction: {
    margin: theme.spacing(0, 0, 2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));
