import React from 'react';

import { sortBy } from 'utilities/sorting';

import {
  getAllTransactions,
} from './data';

import { getTxDueInDays } from './helpers';

export default {
  name: 'Billing Transactions',
  filename: 'Operational_Report_Billing_Transactions',
  description: (
    <ul>
      <li>
        Billing: Verify the status and amount matched
      </li>
    </ul>
  ),
  useDateRange: false,
  async process() {
    const [
      allTransactions,
    ] = await Promise.all([
      getAllTransactions(),
    ]);

    const data = await Promise.all(allTransactions.map(async ({
      id,
      statementId,
      status,
      statusMessage,
      type,
      amountCents,
      carryoverTransactionId,
      createdAt,
      paidAt,
    }) => {
      const { dueInDays, dueDate } = getTxDueInDays(createdAt);

      const issues = [];

      if (carryoverTransactionId && status !== 'cancelled') {
        issues.push('Tx should be cancelled due to carryover.');
      }

      if (amountCents === 0 && status !== 'cancelled') {
        issues.push('Tx should be cancelled due to $0.');
      }

      return {
        id,
        statementId,
        carryoverTransactionId,
        type,
        status,
        statusMessage,
        amountCents,
        dueDate,
        dueInDays,
        createdAt,
        paidAt,
      };
    }));

    return data
      .sort(sortBy('amountCents'))
      .sort(sortBy('type'))
      .sort(sortBy('status'));
  },
};
