import React from 'react';
import { sortBy } from 'utilities/sorting';

import {
  getManualProgramParticipants,
  getAllVehicles,
  getAllMileageReports,
  getParticipantLastTrips,
  getAllRecentNotifications,
} from './data';

import {
  formatMileageReportData,
  formatTripData,
  formatVehicles,
  formatNotifications,
} from './helpers';

export default {
  name: 'Manual Program Participants',
  filename: 'Operational_Report_Manual_Program_Participants',
  description: (
    <ul>
      <li>
        [1st] Check to manual reports flagged did not upload an approved milage report by the 1st.
      </li>
      <li>
        [5th] Check manual accounts suspended did not upload an approved milage report by the 5th
      </li>
      <li>
        [10th, 20th] Ensure the Count of notifications for manual participants not Closed
      </li>
    </ul>
  ),
  useDateRange: false,
  async process() {
    const [
      manualProgramParticipants,
      allVehicles,
      allMileageReports,
      allNotifications,
    ] = await Promise.all([
      getManualProgramParticipants(),
      getAllVehicles(),
      getAllMileageReports(),
      getAllRecentNotifications(3),
    ]);

    const data = await Promise.all(manualProgramParticipants.map(async ({
      username,
      accountNo,
      firstName,
      lastName,
      status,
    }) => {
      const [
        lastTrip,
      ] = await Promise.all([
        getParticipantLastTrips(username),
      ]);

      const vehicles = allVehicles
        .filter((x) => x.username === username);

      const mileageReports = allMileageReports
        .filter((x) => x.username === username)
        .sort(sortBy('tsReportDate', true));

      const approvedMileageReports = mileageReports
        .filter(({ auditStatus }) => auditStatus === 'approved');

      const recentNotifications = allNotifications
        .filter((x) => x.username === username)
        .sort(sortBy('shouldBeSentAt', true));

      const lastReport = mileageReports[0];
      const lastApprovedReport = approvedMileageReports[0];
      const lastTripCreatedAt = formatTripData(lastTrip);

      const issues = [];

      if (lastTrip && !lastReport) {
        issues.push(`User has trips but no mileage report.`);
      }

      if (lastApprovedReport && approvedMileageReports.length > 1) {
        if (!lastTrip || lastTrip.tsEnd !== lastApprovedReport.tsReportDate) {
          issues.push(`The last trip is not constructed from the last approved report.`);
        }
      }

      return {
        program: 'OB-MBUF',
        accountNo,
        firstName,
        lastName,
        status,
        totalReports: mileageReports.length,
        vehicles: formatVehicles(vehicles),
        lastReport: formatMileageReportData(lastReport),
        lastApprovedReport: formatMileageReportData(lastApprovedReport),
        lastTripCreatedAt,
        recentNotifications: formatNotifications(recentNotifications),
        issues: issues.join(','),
      };
    }));

    return data.sort(sortBy('status'));
  },
};
