import React, { useEffect, useState } from 'react';

import Table from 'components/Table';
import { listEmailTemplates } from 'graphql/queries';
import { updateEmailTemplate } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import EmailTemplatePreviewButton from './EmailTemplatePreviewButton';

const title = 'Email Templates';
const description = '';
const LIST_EMAILTEMPLATES_CACHE_KEY = 'EmailTemplateTable-ListEmail';

export default function EmailTemplateTable() {
  const [data, setData] = useState([]);

  const options = {};

  const columns = [
    {
      name: 'sortOrder',
      label: 'Sort Order',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'key',
      label: 'Key',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'enabled',
      label: 'Enabled',
      type: 'boolean',
      edit: {
        type: 'select',
        menu: [{ label: 'yes', value: true }, { label: 'no', value: false }].map(({ label, value }) => {
          return { label, value };
        }),
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'scenario',
      label: 'Scenario',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'subject',
      label: 'Subject',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'text',
      label: 'Text',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'html',
      label: 'Html',
      type: 'html',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: 'Update By',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'key',
      label: 'Preview',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          const template = data ? data[dataIndex] : null;
          return (
            <EmailTemplatePreviewButton
              template={template}
              onUpdate={(updatedData) => {
                data[dataIndex] = updatedData;
                setData([...data]);
              }}
            />
          );
        },
      },
    },

  ];

  const onUpate = async (item, dataIndex) => {
    global.logger.debug('Old data', data, data[dataIndex]);
    global.logger.debug('onUpdate', item);

    const {
      sortOrder,
      enabled,
      key,
      name,
      scenario,
      subject,
      text,
      html,
    } = item;

    const input = {
      sortOrder: parseInt(sortOrder),
      enabled,
      key,
      name,
      scenario,
      subject,
      text,
      html,
      updatedBy: localStorage.getItem('ruc:username'),
    };

    await asyncRetryMutation(
      updateEmailTemplate,
      { input },
      { clearCacheKeys: [LIST_EMAILTEMPLATES_CACHE_KEY] },
    );

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };

  useEffect(() => {
    (async () => {
      try {
        const records = await asyncListAll(listEmailTemplates, undefined, { cacheKey: LIST_EMAILTEMPLATES_CACHE_KEY });
        setData(records.sort(sortBy('sortOrder')));
      } catch (e) {
        global.logger.debug(e);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        onUpdateItem={onUpate}
      />
    </React.Fragment>
  );
}

EmailTemplateTable.propTypes = {};
