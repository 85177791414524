import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Table from 'components/Table';
import { sortBy } from 'utilities/sorting';
import {
  listTripBins,
} from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';

function TripBinsTable({ title = 'Bins', description = '', viewer = 'admin', tripId }) {
  const [data, setData] = useState([]);
  const columns = [
    {
      name: 'tripId',
      label: 'Trip ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'timeZone',
      label: 'Time Zone',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'timestamp',
      label: 'Timestamp @ Time Zone',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if (!data[dataIndex]) return '';
          const { timeZone, timestamp } = data[dataIndex];
          return moment(timestamp).tz(timeZone).format('YYYY/MM/DD HH:mm:ss');
        },
      },
    },
    {
      name: 'lat',
      label: 'Latitude',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lng',
      label: 'Longitude',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
  };

  useEffect(() => {
    (async () => {
      const data = await asyncListAll(listTripBins, { tripId });
      setData(data);
    })();
  }, [tripId]);

  return (
    <Table
      title={title}
      description={description}
      data={data.sort(sortBy('timestamp', true))}
      columns={columns}
      options={options}
    />
  );
}

TripBinsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  viewer: PropTypes.string,
  tripId: PropTypes.string,
};

export default TripBinsTable;
