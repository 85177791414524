import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { theme } from 'index.js';
import { useStyles } from './commonStyles';

import { ThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import ControlledInput from 'components/Form/ControlledInput';

import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import {
  listParticipants,
  listVehicles,
} from 'graphql/queries';
import {
  tripAssociate,
} from 'graphql/mutations';

const AssociateTripsDialog = ({ isOpen, onClose, trips }) => {
  // styles
  const classes = useStyles();

  // state
  const [allParticipants, setAllParticipants] = useState([]);
  const [participantVehicles, setParticipantVehicles] = useState([]);

  // form states
  const { control, errors, handleSubmit, formState, watch, setValue } = useForm();
  const { isSubmitting } = formState;
  const watchParticipant = watch('participant');

  useEffect(() => {
    (async () => {
      const participants = await asyncListAll(listParticipants);
      setAllParticipants(participants.map((participant) => {
        return Object.assign({}, participant, {
          fullName: `${participant.lastName}, ${participant.firstName}`,
        });
      }));
    })();
  }, []);

  const inputs = [{
    type: 'select',
    name: 'participant',
    defaultValue: '',
    label: 'Participant',
    required: true,
    options: allParticipants.map((participant) => {
      return {
        value: participant.username,
        label: participant.fullName,
      };
    }),
    inputProps: {
      onChange: async (event) => {
        const username = event.target.value;
        const vehicles = await asyncListAll(listVehicles, { username });
        setParticipantVehicles(vehicles);
      },
    },
    invalidText: 'Participant is required',
    setValue,
  }, {
    type: 'select',
    name: 'vehicle',
    defaultValue: '',
    label: 'Vehicle',
    required: true,
    disabled: !watchParticipant,
    options: participantVehicles.map((vehicle) => {
      return {
        value: vehicle.id,
        label: `${vehicle.year} ${vehicle.make} ${vehicle.model} (${vehicle.vin})`,
      };
    }),
    setValue,
  }];

  async function handleAssociateTrips({
    participant,
    vehicle,
  }) {
    const input = {
      trips,
      username: participant,
      vehicle_id: vehicle,
    };

    const { data: { tripAssociate: response } } = await asyncRetryMutation(
      tripAssociate,
      { input },
    );

    onClose(response);
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="associate-trips-dialog"
        open={isOpen}
        onClose={onClose}
        disableBackdropClick={true}
      >
        <DialogContent>
          <Alert
            severity="info"
            variant="filled"
          >
            <p>
              Associating one or more trips with any participant will destroy
              the existing trip segment records and reprocess the trip with the
              new participant&apos;s pilot settings.
            </p>
            <p>
              Any existing participant statements or transactions will not be
              changed.  It is recommended only associating trips that fall under
              the current billing cycle.
            </p>
          </Alert>
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleAssociateTrips)}
            noValidate
          >
            <Grid container spacing={2}>
              {inputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} sm={6}>
                <Button
                  type="button"
                  size="large"
                  fullWidth
                  variant="contained"
                  color="inherit"
                  className={classes.secondaryAction}
                  onClick={onClose}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Associate
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

AssociateTripsDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  trips: PropTypes.array,
};

export default AssociateTripsDialog;
