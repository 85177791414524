import React from 'react';
import PropTypes from 'prop-types';

import StatementsTable from 'pages/Admin/components/StatementsTable';

const ParticipantStatements = ({ username }) => {
  return (
    <StatementsTable
      username={username}
    />
  );
};

ParticipantStatements.propTypes = {
  username: PropTypes.string,
};

export default ParticipantStatements;
