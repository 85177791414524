import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';
import { APP } from 'utilities/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export default function ReportTemplate({
  name,
  filename,
  description = '',
  useDateRange = true,
  process,
}) {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const fromDate = moment(from).tz(APP.TIME_ZONE).startOf('day').toISOString();
      const toDate = moment(to).tz(APP.TIME_ZONE).endOf('day').toISOString();

      const data = await process(fromDate, toDate);
      const options = data.length > 0 ? {} : { fields: ['no-data-in-range'] };
      const parser = new Parser(options);
      const csv = parser.parse(data);
      setCsvData(csv);
      await new Promise((resolve) => setTimeout(resolve, 300));
      csvLinkRef.current.link.click();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" component="p">
              {description || ''}
            </Typography>
          </Grid>

          {useDateRange && <>
            <Grid item xs={12} sm={6}>
              <TextField
                name="from"
                type="date"
                variant="outlined"
                fullWidth
                label="From"
                defaultValue={from}
                onChange={(e) => setFrom(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
              <TextField
                name="to"
                type="date"
                variant="outlined"
                fullWidth
                label="To"
                defaultValue={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </Grid>
          </>}
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`${filename || name}_Date ${moment().format('MM/DD/YYYY')}.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

ReportTemplate.propTypes = {
  name: PropTypes.string,
  filename: PropTypes.string,
  description: PropTypes.string,
  process: PropTypes.func,
  useDateRange: PropTypes.bool,
};
