import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { asyncListAll } from 'utilities/graph';

import { useStyles } from '../styles';
import { sortBy } from 'utilities/sorting';
import TripAdjustmentsTable from 'pages/Admin/components/TripAdjustmentsTable';
import { getTripAdjustmnentsByParticipantByCreatedAt } from 'graphql/queries';

const ParticipantTripAdjustments = ({ username, viewer = 'admin' }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const data = await asyncListAll(
          getTripAdjustmnentsByParticipantByCreatedAt,
          {
            username,
          },
        );
        setData(data.sort(sortBy('createdAt', true)));
      } catch (e) {
        global.logger.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username]);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <>
      <TripAdjustmentsTable
        data={data}
        showAddButton={false}
      />
    </>
  );
};

ParticipantTripAdjustments.propTypes = {
  username: PropTypes.string,
  viewer: PropTypes.string,
};

export default ParticipantTripAdjustments;
