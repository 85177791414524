import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from './styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { mroSupportedDevices } from 'utilities/constants';

import { listMileageReportingOptions } from 'graphql/queries';
import { getParticipantByStatus } from './graphql';

import {
  createMileageReportingOption,
  updateVehicle,
} from 'graphql/mutations';
import {
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';

const CreateMRODevice = () => {
  const classes = useStyles();
  const history = useHistory();

  const [error, setError] = useState(false);

  const [deviceSerialNumber, setDeviceSerialNumber] = useState('');
  const [allDevices, setAllDevices] = useState([]);
  const [eligibleParticipantVehicles, setEligibleParticipantVehicles] = useState([]);
  const [associatedParticipant, setAssociatedParticipant] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const [
          activeParticipants,
          approvedParticipants,
          devices,
        ] = await Promise.all([
          asyncListAll(getParticipantByStatus, {
            status: 'active',
          }, {
            bypassCache: true,
          }),
          asyncListAll(getParticipantByStatus, {
            status: 'approved',
          }, {
            bypassCache: true,
          }),
          asyncListAll(listMileageReportingOptions),
        ]);

        const participants = [].concat(activeParticipants, approvedParticipants);
        const eligibleVehicles = participants.reduce((acc, next) => {
          const { vehicles: { items: participantVehicles = [] }, username, lastName, firstName } = next;
          const eligible = participantVehicles.filter((vehicle) => {
            const { mroId = null, mroType = null } = vehicle;
            return (
              (mroType === 'automatedWithLocation' || mroType === 'automatedWithoutLocation') &&
              (!mroId || mroId === 'N/A')
            );
          });
          if (eligible.length) {
            acc = acc.concat(eligible.map((item) => {
              return Object.assign({}, item, {
                username,
                lastName,
                firstName,
              });
            }));
          }
          return acc;
        }, []);

        setEligibleParticipantVehicles(eligibleVehicles);
        setAllDevices(devices);
      } catch (e) {
        global.logger.debug(e);
      }
    })();
  }, []);

  function validateAssociation() {
    if (!deviceSerialNumber || !associatedParticipant) {
      setError('Please fill out all required fields');
      return {
        isValid: false,
        errors: [],
      };
    }

    const existingDevice = allDevices.find(({ deviceSerialNumber: knownSerial }) => {
      return knownSerial === deviceSerialNumber;
    });

    if (existingDevice) {
      setError('The provided device serial number has already been registered');
      return {
        isValid: false,
      };
    }

    return { isValid: true };
  }

  async function handleCreateMRO() {
    const { isValid } = validateAssociation();
    if (!isValid) {
      return;
    }

    // get vehicles by username
    const { username, mroType, id: vehicleId } = associatedParticipant;
    try {
      const deviceDetails = mroSupportedDevices[0];
      const gpsEnabled = (mroType === 'automatedWithLocation') ? true : false;
      const {
        data: {
          createMileageReportingOption: result,
        },
      } = await asyncRetryMutation(createMileageReportingOption, {
        input: {
          id: uuidv4(),
          username,
          deviceSerialNumber,
          vehicleId,
          gpsEnabled,
          ...deviceDetails,
          createdBy: localStorage.getItem('ruc:username'),
          updatedBy: localStorage.getItem('ruc:username'),
        },
      });

      await asyncRetryMutation(updateVehicle, {
        input: {
          username,
          id: vehicleId,
          mroId: result.id,
          updatedBy: localStorage.getItem('ruc:username'),
        },
      });

      history.push('/mro-devices');
    } catch (e) {
      global.logger.error(e);
      setError(e.message);
      return;
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Associate MRO Device
        </Typography>
        <p>Locate a participant and associate a device by serial number.</p>
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateMRO();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="search-participant"
                options={eligibleParticipantVehicles}
                disableClearable={true}
                getOptionLabel={(option) => {
                  return `${option.lastName}, ${option.firstName} (VIN: ${option.vin})`;
                }}
                onChange={(e, newValue) => {
                  setAssociatedParticipant(newValue);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      id="participant"
                      label="Participant"
                      fullWidth
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="deviceSerialNumber"
                id="deviceSerialNumber"
                name="deviceSerialNumber"
                variant="outlined"
                label="Device Serial Number"
                required
                fullWidth
                autoFocus
                disabled={Object.keys(associatedParticipant).length === 0}
                onChange={(e) => {
                  setDeviceSerialNumber(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Complete
          </Button>
        </form>
        <Snackbar
          open={error != false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container >
  );
};

export default CreateMRODevice;
