import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  paperFull: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '100%',
  },
  list: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
  },
  registeredDevices: {
    textAlign: 'center',
  },
  notFound: {
    textAlign: 'left',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  secondaryAction: {
    margin: theme.spacing(0, 0, 2),
  },
  imagePreview: {
    marginTop: theme.spacing(2),
  },
  hyperlink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  tupay: {
    width: '100%',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '80%',
    height: '1px',
    borderTop: 'none',
  },
  connectionList: {
    width: '100%',
    backgroundColor: theme.palette.action.selected,
    borderRadius: '5px',
  },
  connectionHeading: {
    marginBottom: theme.spacing(1),
  },
}));
