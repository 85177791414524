import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { getPilotProgram, getParticipantsByPilotProgram } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import ParticipantsTable from 'pages/Admin/components/ParticipantsTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function PilotProgram({ id: inId, computedMatch, hideHeader }) {
  const classes = useStyles();
  const [id, setId] = useState();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
    } else
    if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
    }
  }, [inId, computedMatch]);


  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);
        try {
          const { data: { getPilotProgram: data } } = await API.graphql(graphqlOperation(getPilotProgram, { id }));
          setData(data);

          const records = await asyncListAll(getParticipantsByPilotProgram, { participantPilotProgramId: id });
          setParticipants(records);
        } catch (e) {
          global.logger.debug(e);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id]);

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      {!hideHeader &&
        <Paper className={classes.content} elevation={2}>
          <Typography component="h1" variant="h5">
            {data.name}
          </Typography>
          <Typography component="p" variant="body1">
            {data.description}
          </Typography>
        </Paper>}

      <ParticipantsTable data={participants} />
    </div>
  );
}

PilotProgram.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  hideHeader: PropTypes.bool,
};
