import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import {
  states,
} from 'utilities/constants';
import { listTolls } from 'graphql/queries';
import { updateToll } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';

const title = 'Tolls';
const description = '';

function TollTable({ viewer = 'admin' }) {
  const [data, setData] = useState([]);
  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
        customBodyRender(item) {
          const fullName = states[item];
          return fullName ? `${fullName} (${item})` : '';
        },
      },
    },
    {
      name: 'name',
      label: 'Name',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const onUpate = async (item, dataIndex) => {
    const input = {
      id: item.id,
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    await asyncRetryMutation(updateToll, { input }, { clearCacheKeys: ['listTolls'] });

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };


  useEffect(() => {
    (async () => {
      try {
        const records = (await asyncListAll(listTolls));
        setData(records);
      } catch (e) {
        global.logger.debug(e);
      }
    })();
  }, []);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onUpdateItem={onUpate}
    />
  );
}

TollTable.propTypes = {
  viewer: PropTypes.string,
};

export default TollTable;
