import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TripsReviewTable from 'pages/Admin/components/TripsReviewTable';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';
import { getTripsByReviewStatusByCreatedAt } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function TripsReview() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [trips, setTrips] = useState([]);

  const onSubmit = async ({ from, to }) => {
    setIsLoading(true);

    const queryParams = {
      reviewStatus: 'pending',
      createdAt: {
        between: [from, to],
      },
    };
    console.log('queryParams', queryParams);
    const trips = await asyncListAll(getTripsByReviewStatusByCreatedAt, queryParams, { bypassCache: true });

    setTrips(trips.sort(sortBy('createdAt', true)));

    setIsLoading(false);
  };

  return (
    <div className={classes.root}>
      <DateRangeSelector
        onSubmit={onSubmit}
        submitOnLoad={true}
        disabled={isLoading}
      />

      <TripsReviewTable
        data={trips}
      />
    </div>
  );
}
