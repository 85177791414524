import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import ParticipantVehicles from 'pages/Admin/Participant/components/ParticipantVehicles';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';

const VehicleInfo = ({ user }) => {
  const { username } = user;

  return (
    <Container component="main">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ParticipantVehicles
            username={username}
            title="My Vehicles"
            viewer="participant"
          />
        </Grid>
        <Grid item xs={12}>
          <Alert color="info">
            Changing Vehicles: In order to change or update vehicles, please click on the Support link above to communicate
            your request to our Customer Service Representatives.
          </Alert>
        </Grid>
      </Grid>
    </Container >
  );
};

VehicleInfo.propTypes = {
  user: PropTypes.object,
};

export default VehicleInfo;
