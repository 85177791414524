export const surveyVehicleStyles = [
  'Sedan/Compact',
  'SUV/Crossover',
  'Pick-up truck',
  'Van/Wagon/Minivan',
  'Coupe/Convertible/Sport car',
  'Luxury car',
  'Commercial van/truck',
  // 'Motorcycle',
  'I don\'t drive',
];

export const surveyVehicleEligibilityYears = [
  'Up to 2005',
  '2006 and later',
];

export const surveyVehicleTypes = [
  'Gasoline',
  'Hybrid',
  'Diesel',
  'Electric',
];

export const surveyComfortability = [
  'Very uncomfortable',
  'Somewhat uncomfortable',
  'Neutral',
  'Somewhat comfortable',
  'Very comfortable',
  'Don\'t know',
];

export const surveyAreas = [
  'Northern Virginia',
  'Maryland',
  'D.C.',
  'A combination of the three',
  'None of the above',
];

export const surveyAreaFrequencies = [
  '3 times a week and more',
  '1 - 2 times a week',
  '3 - 4 times a month',
  '1 - 2 times a month',
  'Less than once a month',
];

export const surveyTimesOfDay = [
  'Weekday - 6am-10am',
  'Weekday - 10am-3pm',
  'Weekday - 3pm-7pm',
  'Weekday - 7pm-6am',
  'Weekends',
  'All of the above',
];
