import React from 'react';

import { sortBy } from 'utilities/sorting';

import {
  getCurrentParticipants,
  getAllPilotPrograms,
  getAllOpenTransactions,
} from './data';

import { getTxDueInDays } from './helpers';

export default {
  name: 'Accounts Status Check',
  filename: 'Operational_Report_Account_Status_Check',
  description: (
    <ul>
      <li>
        Billing: Validate those flagged still owe a balance, check transaction status to make sure it is not failed
      </li>
    </ul>
  ),
  useDateRange: false,
  async process() {
    const [
      allCurrentParticipants,
      allPilotPrograms,
      allOpenTransactions,
    ] = await Promise.all([
      getCurrentParticipants(),
      getAllPilotPrograms(),
      getAllOpenTransactions(),
    ]);

    const data = await Promise.all(allCurrentParticipants.map(async ({
      username,
      accountNo,
      firstName,
      lastName,
      status,
      participantPilotProgramId,
      flags,
    }) => {
      const pilotProgram = allPilotPrograms.find(({ id }) => id === participantPilotProgramId);
      const {
        hasIntegrityViolation,
        isBillingOverdue,
        isBillingDefault,
        isInactive,
        isVinMismatch,
      } = flags || {};

      // Technically there should only be one open tx per participant
      const txs = allOpenTransactions.filter((t) => t.username === username);

      let dueInDays = 0;
      let dueDate = '';
      const overdueTxs = txs
        .filter((t) => {
          ({ dueInDays, dueDate } = getTxDueInDays(t.createdAt));
          return getTxDueInDays <= 0;
        });

      return {
        program: pilotProgram?.shortName,
        accountNo,
        firstName,
        lastName,
        status,
        hasIntegrityViolation: hasIntegrityViolation ? 'x' : '',
        isBillingOverdue: isBillingOverdue ? 'x' : '',
        isBillingDefault: isBillingDefault ? 'x' : '',
        isInactive: isInactive ? 'x' : '',
        isVinMismatch: isVinMismatch ? 'x' : '',
        totalOpenTxs: txs.length,
        overdueTxs: overdueTxs.length,
        dueDate,
        dueInDays,
        // createdTxs: createdTxs.length,
        // failedTxs: failedTxs.length,
        // url: `${window.location.protocol}//${window.location.hostname}/participant/${username}`,
      };
    }));

    return data.sort(sortBy('status'));
  },
};
