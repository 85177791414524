import React from 'react';
import PropTypes from 'prop-types';

import MRODevicesAdmin from 'pages/Admin/MRODevices';
import MRODevicesParticipant from 'pages/Admin/MRODevices/indexParticipants';

const ParticipantMros = ({ username, viewer = 'admin' }) => {
  if (viewer === 'admin') {
    return (
      <React.Fragment>
        < MRODevicesAdmin
          username={username}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        < MRODevicesParticipant
          username={username}
        />
      </React.Fragment>
    );
  }
};

ParticipantMros.propTypes = {
  username: PropTypes.string,
  viewer: PropTypes.string,
};

export default ParticipantMros;
