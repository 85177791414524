import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  shellParticipant,
} from 'utilities/constants/shellModels';
import {
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';
import {
  listPilotPrograms,
} from 'graphql/queries';
import { updateParticipant } from 'graphql/mutations';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Logo from 'components/Logo';

import ControlledInput from 'components/Form/ControlledInput';

import {
  states,
  genders,
  ageGroups,
  regex,
} from 'utilities/constants';
import {
  getCityStateByZip,
  validateAddress,
} from 'utilities/usps';

const RegisterParticipant = ({
  cognitoUser,
  participant = shellParticipant,
  onCompleteStep,
}) => {
  const classes = useStyles();
  const history = useHistory();

  // props
  const { username } = cognitoUser;

  // form states
  const { control, errors, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;
  const [error, setError] = useState(false);

  // pilot program
  const [pilotPrograms, setPilotPrograms] = useState([]);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const pilot = query.get('pilot') || 'MBUF';

  // pilot programs
  const [participantPilotProgram, setParticipantPilotProgram] = useState({});

  useEffect(() => {
    (async () => {
      const result = await asyncListAll(listPilotPrograms, null, { bypassCache: true });
      setPilotPrograms(result);
    })();
  }, []);

  useEffect(() => {
    if (!pilotPrograms || pilotPrograms.length < 1) {
      return;
    }

    const participantPilot = pilotPrograms.find(({ shortName }) => {
      return shortName.toUpperCase() === pilot.toUpperCase();
    });

    setParticipantPilotProgram(participantPilot);
  }, [pilotPrograms, pilot, history]);

  const inputs = [{
    type: 'text',
    name: 'firstName',
    defaultValue: participant.firstName,
    label: 'First Name',
    autoFocus: true,
    required: true,
    invalidText: 'First name is required and must contain only letters, spaces and hyphens',
    pattern: regex.name,
    setValue,
  }, {
    type: 'text',
    name: 'lastName',
    defaultValue: participant.lastName,
    label: 'Last Name',
    required: true,
    invalidText: 'Last name is required and must contain only letters, spaces and hyphens',
    pattern: regex.name,
    setValue,
  }, {
    type: 'text',
    name: 'phoneNumber',
    defaultValue: participant.phoneNumber,
    label: 'Phone Number',
    required: true,
    mask: '(999) 999-9999',
    pattern: regex.phoneNumber,
    invalidText: 'A phone number is required',
    setValue,
  }, {
    type: 'text',
    name: 'address1',
    defaultValue: participant.address.address1,
    label: 'Address Line 1',
    required: true,
    invalidText: 'Address is required',
    setValue,
  }, {
    type: 'text',
    name: 'address2',
    defaultValue: participant.address.address2,
    label: 'Address Line 2',
    setValue,
  }, {
    type: 'number',
    name: 'postalCode',
    defaultValue: participant.address.postalCode.toString(),
    label: 'Zip',
    minLength: 5,
    maxLength: 5,
    required: true,
    invalidText: 'Zip is required',
    inputProps: {
      onBlur: async (event) => {
        try {
          const result = await getCityStateByZip(event.target.value);
          const { city = '', state = '' } = result;
          setValue('city', city);
          setValue('state', state);
        } catch (e) {
          // swallow invalid zip
        }
      },
    },
  }, {
    type: 'text',
    name: 'city',
    defaultValue: participant.address.city,
    label: 'City',
    required: true,
    invalidText: 'City is required',
    setValue,
  }, {
    type: 'select',
    name: 'state',
    defaultValue: participant.address.state,
    label: 'State',
    options: Object.keys(states).map((state) => {
      return {
        value: state,
        label: state,
      };
    }),
    required: true,
    invalidText: 'State is required',
  }, {
    type: 'select',
    name: 'gender',
    defaultValue: participant.gender,
    label: 'Gender',
    options: Object.keys(genders).map((gender) => {
      return {
        value: gender,
        label: genders[gender],
      };
    }),
    required: false,
  }, {
    type: 'select',
    name: 'ageGroup',
    defaultValue: participant.ageGroup,
    label: 'Age Group',
    options: Object.keys(ageGroups).map((group) => {
      return {
        value: group,
        label: ageGroups[group],
      };
    }),
    required: false,
  }];

  function handleCloseError() {
    setError(false);
  }

  async function handleRegister({
    firstName, lastName, phoneNumber,
    address1, address2, city, state, postalCode,
    gender, ageGroup,
  }) {
    try {
      const response = await validateAddress({
        address1,
        address2,
        city,
        state,
        postalCode,
      });

      if (
        postalCode !== response.zip ||
        city.toLowerCase() !== response.city.toLowerCase() ||
        state.toLowerCase() !== response.state.toLowerCase()
      ) {
        setError('The provided address is not valid.');
        return;
      }

      if (response.street1.indexOf('PO BOX') !== -1) {
        setError('Post Office Boxes are not allowed.');
        return;
      }
    } catch (e) {
      // api returned errors
      const error = e.toString();
      if (
        error.indexOf('Invalid Zip Code') !== -1 ||
        error.indexOf('Address Not Found') !== -1
      ) {
        setError('The provided address is not valid.');
      }
      return;
    }

    try {
      await asyncRetryMutation(updateParticipant, {
        input: {
          username,
          firstName,
          lastName,
          phoneNumber,
          address: {
            address1,
            address2,
            city,
            state,
            postalCode,
          },
          gender,
          ageGroup,
          preferredContactType: 'email',
          updatedBy: localStorage.getItem('ruc:username'),
          participantPilotProgramId: participantPilotProgram.id,
        },
      });

      onCompleteStep({
        firstName,
        lastName,
        phoneNumber,
        address1,
        address2,
        city,
        state,
        postalCode,
        participantPilotProgramId: participantPilotProgram.id,
      }, participantPilotProgram);
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  return (
    <div className={classes.paper}>
      <Logo width={250} fullColor display='block' margin='auto' />
      <Typography component="h1" variant="h5">Your Information</Typography>
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleRegister)}
        noValidate
      >
        <Grid container spacing={2}>
          {inputs.splice(0, 2).map((input, index) => {
            // first / last name
            return (
              <Grid item xs={12} sm={6} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}

          {inputs.splice(0, 3).map((input, index) => {
            // phone, address 1, address 2
            return (
              <Grid item xs={12} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}

          {inputs.splice(0, 1).map((input, index) => {
            // postal code
            return (
              <Grid item xs={12} sm={4} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}

          {inputs.splice(0, 1).map((input, index) => {
            // city
            return (
              <Grid item xs={12} sm={5} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}

          {inputs.splice(0, 1).map((input, index) => {
            // state
            return (
              <Grid item xs={12} sm={3} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}

          {/*
          {inputs.splice(0, 2).map((input, index) => {
            // gender, age group demographics
            return (
              <Grid item xs={12} sm={6} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}

          {inputs.map((input, index) => {
            // ...rest
            return (
              <Grid item xs={12} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}
          */}
        </Grid>
        <Button
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
        >
          Continue
        </Button>
      </form>
      <Snackbar
        open={error != false}
        autoHideDuration={10000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

RegisterParticipant.propTypes = {
  cognitoUser: PropTypes.object,
  participant: PropTypes.object,
  onCompleteStep: PropTypes.func,
};

export default RegisterParticipant;
