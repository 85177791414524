import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import PaymentIcon from '@material-ui/icons/Payment';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Payment from '../index';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00ac6f',
      contrastText: '#fff',
    },
  },
});

const PaymentButton = ({
  user,
  balance,
  transactions,
  text = '',
  showText = true,
  onUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!text || text === '') {
    text = `Pay ${balance}`;
  }

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullScreen
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent>
          {isOpen &&
            <Payment
              user={user}
              transactionIds={transactions.map(({ id }) => id)}
              onComplete={() => {
                onClose();
                onUpdate();
              }}
            />}
        </DialogContent>
      </Dialog>
      <Button
        variant="contained"
        color="primary"
        size="large"
        aria-label="pay"
        // component={RouteLink}
        // to={`/payment/${transactions.map(({ id }) => id).join(';')}`}
        title={text}
        endIcon={<PaymentIcon />}
        onClick={() => setIsOpen(true)}
      >
        {showText && (<Typography component="span">{text}</Typography>)}
      </Button>
    </ThemeProvider>
  );
};

PaymentButton.propTypes = {
  user: PropTypes.object,
  transactions: PropTypes.array,
  balance: PropTypes.string,
  text: PropTypes.string,
  showText: PropTypes.bool,
  onUpdate: PropTypes.func,
};

export default PaymentButton;
