
import moment from 'moment-timezone';
import pLimit from 'p-limit';

import { APP } from 'utilities/constants';
import { asyncListAll, asyncListFirstItem } from 'utilities/graph';

import {
  listEvents,
} from 'graphql/queries';

import {
  listEvent,
  getNotificationsByStatusByShouldBeSentAt,
} from './graphql';

export const checkDuplicateRecordId = (data = []) => {
  const mappings = {};

  data.forEach((record) => {
    const key = record['RecordID'] || record['Record ID'] || 'N/A';
    if (mappings[key]) {
      global.logger.debug('Duplicate Record ID', key);
    } else {
      mappings[key] = true;
    }
  });
};

export const getQueryDates = (from, to) => {
  const fromDate = moment(from).tz(APP.TIME_ZONE).startOf('day').toISOString();
  const toDate = moment(to).tz(APP.TIME_ZONE).endOf('day').toISOString();

  let currentFrom = fromDate;
  let currentTo = moment(fromDate).tz(APP.TIME_ZONE).endOf('day').toISOString();

  const dates = [];

  while (currentTo <= toDate) {
    dates.push({
      from: currentFrom,
      to: currentTo,
    });

    currentFrom = moment(currentFrom).tz(APP.TIME_ZONE).add(1, 'days').startOf('day').toISOString();
    currentTo = moment(currentTo).tz(APP.TIME_ZONE).add(1, 'days').endOf('day').toISOString();
  }

  return dates;
};

export const getTripsByStatusByQueryDates = async (gqlQuery, processStatus, queryDartes = []) => {
  let trips = [];
  await Promise.all(queryDartes.map(async ({ from, to }) => {
    const data = await asyncListAll(gqlQuery, {
      processStatus,
      createdAt: { between: [from, to] },
    });

    trips = [...trips, ...data];
  }));

  return trips;
};

export const listAllTripsForDateRange = async (gqlQuery, from, to) => {
  const queryDates = getQueryDates(from, to);

  const [tripsProcessed, tripsPending, tripsFailed] = await Promise.all([
    getTripsByStatusByQueryDates(gqlQuery, 'processed', queryDates),
    getTripsByStatusByQueryDates(gqlQuery, 'pending', queryDates),
    getTripsByStatusByQueryDates(gqlQuery, 'failed', queryDates),
  ]);

  return tripsProcessed.concat(tripsPending, tripsFailed);
};

export const listAllForDateRange = async ({ gqlQuery = '', params = () => { }, from, to }) => {
  const queryDates = getQueryDates(from, to);

  const limit = pLimit(10);

  let results = [];

  const process = queryDates.map(({ from, to }) => {
    return limit(async () => {
      const data = await asyncListAll(gqlQuery, params(from, to));
      results = [...results, ...data];
    });
  });

  await Promise.all(process);

  return results;
};

export const getAllApprovalNotifications = async (from, to) => {
  let records = [];

  const statuses = [
    'sent',
    'failed',
    'cancelled',
  ];

  await Promise.all(statuses.map(async (status) => {
    const data = await listAllForDateRange({
      from,
      to,
      gqlQuery: getNotificationsByStatusByShouldBeSentAt,
      params: (from, to) => {
        return {
          status,
          shouldBeSentAt: { between: [from, to] },
          filter: {
            or: [
              { templateName: { eq: 'accountApproval' } },
              { templateName: { eq: 'accountApprovalManualProgram' } },
              { templateName: { eq: 'telematicsConnectReminder1' } },
            ],
          },
        };
      },
    });

    records = [...records, ...data];
  }));

  return records;
};

export const getParticipantAppprovedAt = ({
  participant,
  notifications,
}) => {
  const participantApprovalNotification = notifications.find(({ username, templateName }) => {
    return username === participant.username && templateName !== 'telematicsConnectReminder1';
  });

  // fallback for early registered telematic users
  const participantReminderNotification = notifications.find(({ username, templateName }) => {
    return username === participant.username && templateName === 'telematicsConnectReminder1';
  });

  const { createdAt: notificationCreatedAt } = participantApprovalNotification || participantReminderNotification || {};

  const activedAt = participant.firstDataReceivedDate;

  // Make sure the approved date not exceed the active date
  let approvedAt = notificationCreatedAt || activedAt;
  if (approvedAt > activedAt) {
    approvedAt = activedAt;
  }

  return approvedAt || '';
};

// return participant and vehicle events for each participant
export const getParticipantWithVehicleEvents = async (from, to) => {
  const item = await asyncListFirstItem(listEvents, {});

  const [, hash, env] = item.key.split('__')[0].split('-');
  const tableSuffix = `${hash}-${env}`;

  const allParticipantsWithVehicles = await asyncListAll(/* GraphQL */ `
    query ListParticipants(
      $filter: ModelParticipantFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listParticipants(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          username
          status
          accountNo
          firstName
          lastName
          firstDataReceivedDate
          closedDate
          closedReason
          pilotProgram {
            shortName
          }
          # flags {
          #   hasIntegrityViolation
          #   isBillingOverdue
          #   isBillingDefault
          #   isInactive
          #   isLegislator
          #   isVinMismatch
          #   isVIP
          #   isGovernmentEmployee
          #   isCaliforniaElected
          #   agreeGlobalParticipantAgreement
          #   agreeGlobalPrivacyPolicy
          # }
          vehicles {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
    }
  `, {});

  const limit = pLimit(20);

  const process = allParticipantsWithVehicles.map((participant) => {
    const { username, vehicles: { items: vehicles } } = participant;

    return limit(async () => {
      const participantEventKey = `Participant-${tableSuffix}__${username}__undefined`;

      let vehicleEvents = [];

      const [
        participantEvents,
      ] = await Promise.all([
        asyncListAll(listEvent, {
          key: participantEventKey,
          timestamp: {
            between: [from, to],
          },
        }),
        ...vehicles.map(async ({ id: vehicleId }) => {
          const vehicleEventKey = `Vehicle-${tableSuffix}__${username}__${vehicleId}`;
          const events = await asyncListAll(listEvent, {
            key: vehicleEventKey,
            timestamp: {
              between: [from, to],
            },
          });
          vehicleEvents = [...vehicleEvents, ...events];
        }),
      ]);

      return {
        ...participant,
        username,
        participantEvents,
        vehicles,
        vehicleEvents,
      };
    });
  });

  return Promise.all(process);
};
