import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import InquiriesTable from 'pages/Admin/components/InquiriesTable';

import { asyncListAll } from 'utilities/graph';
import { getInquiriesByUsernameByStatus } from 'graphql/queries';

import { useStyles } from './styles';

function Inquiries({ username }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [inquiries, setInquiries] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const inquiries = await asyncListAll(getInquiriesByUsernameByStatus, {
          username,
          sortDirection: 'DESC',
        }, {
          bypassCache: true,
        });

        setInquiries(inquiries);
      } catch (e) {
        global.logger.warn(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username]);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <Container component="main">
      <InquiriesTable
        title="Inquiries"
        data={inquiries}
        viewer="participant"
      />
    </Container>
  );
}

Inquiries.propTypes = {
  username: PropTypes.string,
};

export default Inquiries;
