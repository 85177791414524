import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { getTripsByUsernameByCreatedAt } from './queries';
import { asyncListAll } from 'utilities/graph';
import TripsTable from 'pages/Admin/components/TripsTable';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';
import InvalidateTripsSelector from 'pages/Admin/components/InvalidateTripsSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

const InvalidateTrips = ({ username }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [to, setTo] = useState();
  const [from, setFrom] = useState();

  const onSubmitDate = async ({ from, to }) => {
    setIsLoading(true);

    const processStatus = ['pending', 'processed'];
    const queryParams = {
      username,
      createdAt: {
        between: [from, to],
      },
      sortDirection: 'DESC',
    };

    const results = await asyncListAll(getTripsByUsernameByCreatedAt, queryParams, { bypassCache: true });
    const trips = results.filter((trip) => processStatus.includes(trip.processStatus));

    setTo(to);
    setFrom(from);
    setTrips(trips);
    setIsLoading(false);
  };

  const onSubmitInvalid = async () => {
    if (from && to) {
      await onSubmitDate({ from, to });
    }
  };

  useEffect(() => { }, []);

  return (
    <div className={classes.root}>
      <DateRangeSelector
        unit='week'
        onSubmit={onSubmitDate}
        submitOnLoad={true}
        disabled={isLoading}
      />
      <InvalidateTripsSelector
        disabled={isLoading}
        data={trips}
        onSubmit={onSubmitInvalid}
      />
      <TripsTable data={trips} />
    </div>
  );
};

InvalidateTrips.propTypes = {
  username: PropTypes.string,
};

export default InvalidateTrips;
