import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Logo from 'components/Logo';

import BillingExplanation from './BillingExplanation';
import BillingInfo from 'pages/Participant/Account/components/BillingInfo';

import {
  systemBillingFeatures,
} from 'utilities/constants/paymentStatus';

const RegisterBilling = ({
  participant,
  username,
  onPreviousStep,
  onCompleteStep,
}) => {
  const classes = useStyles();
  const billingFeatures = systemBillingFeatures(localStorage);

  // overall state
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);

  function handleCloseError() {
    setError(false);
  }

  function handleCompleted() {
    setCompleted(true);
  }

  // make this skippable or save the tokenized payment information
  return (
    <>
      <div className={classes.paper} style={{ width: '100%' }}>
        <Logo width={250} fullColor display='block' margin='auto' />
        <Typography component="h1" variant="h5">Billing Information</Typography>
        <Grid container spacing={2} justify="center" align="center">
          <Grid item xs={12}>
            {billingFeatures.provider === 'tupay' ? (
              !completed ? (
                <div>
                  <p>
                    <strong>This Pilot requires that participants pay their monthly
                    Road Charge on miles driven from August 2024 through January 2025. </strong>
                    Save your credit or debit card below for quicker payment each month.
                    Please note that saving your payment information does NOT sign you up for autopay.
                    Autopay is not allowed for this Pilot. You will be required to log in to your Pilot account each month and pay your Road Charge.
                    If you decide to save your payment information to your Pilot account,
                    you will receive a $1 temporary pre-authorization charge to your credit or debit card account.
                    This temporary pre-authorization charge will automatically be reversed after your account is verified.
                  </p>
                  <Button
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      onCompleteStep();
                    }}
                  >
                        Skip for now
                  </Button>
                </div>
              ) : null
            ) : <BillingExplanation />}
          </Grid>
        </Grid>
      </div>

      <div className={classes.paper} style={{ width: '100%', padding: 0 }} >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {completed ? (
              <div className={classes.paper}>
                <CheckCircleOutlineIcon className={classes.billingSuccess} />
                <p>
                  Successfully added payment method!
                </p>
              </div>
            ) : (
              <BillingInfo
                user={{ username: username }}
                showTitle={false}
                showSaved={false}
                onCompleted={handleCompleted} />
            )}
          </Grid>
        </Grid>
      </div>

      <div className={classes.paper} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              onClick={() => {
                onPreviousStep();
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!completed}
              onClick={() => {
                onCompleteStep();
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>

        <Snackbar
          open={error!=false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div >
    </>
  );
};

RegisterBilling.propTypes = {
  participant: PropTypes.object,
  username: PropTypes.string,
  onPreviousStep: PropTypes.func,
  onCompleteStep: PropTypes.func,
};

export default RegisterBilling;
