/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const tripRating = /* GraphQL */ `
  mutation TripRating($input: TripRatingRequest!) {
    tripRating(input: $input) {
      message
      trips {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        invalid
        routeEngine
        routeEngineVersion
        notes
        updatedAt
      }
    }
  }
`;
export const eventMessage = /* GraphQL */ `
  mutation EventMessage($input: EventMessageRequest!) {
    eventMessage(input: $input) {
      message
    }
  }
`;
export const tripArchive = /* GraphQL */ `
  mutation TripArchive($input: TripArchiveRequest!) {
    tripArchive(input: $input) {
      message
    }
  }
`;
export const tripAssociate = /* GraphQL */ `
  mutation TripAssociate($input: TripAssociateRequest!) {
    tripAssociate(input: $input) {
      message
    }
  }
`;
export const vinReport = /* GraphQL */ `
  mutation VinReport($input: VinReportRequest!) {
    vinReport(input: $input) {
      message
    }
  }
`;
export const generateStatements = /* GraphQL */ `
  mutation GenerateStatements($input: GenerateStatementsRequest!) {
    generateStatements(input: $input) {
      message
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($input: SendEmailRequest!) {
    sendEmail(input: $input) {
      message
    }
  }
`;
export const agreeToTerms = /* GraphQL */ `
  mutation AgreeToTerms($input: AgreeToTermsRequest!) {
    agreeToTerms(input: $input) {
      message
    }
  }
`;
export const bulkImportMroDevices = /* GraphQL */ `
  mutation BulkImportMroDevices($input: BulkImportMroDevicesRequest!) {
    bulkImportMroDevices(input: $input) {
      errors {
        message
        username
        mroSerialNumber
        vehicleId
      }
      notifications {
        message
        username
        mroSerialNumber
      }
      registrations
      duplicates
    }
  }
`;
export const storePaymentCardByToken = /* GraphQL */ `
  mutation StorePaymentCardByToken($input: StorePaymentCardByTokenRequest!) {
    storePaymentCardByToken(input: $input) {
      paymentMethodId
    }
  }
`;
export const emailCheck = /* GraphQL */ `
  mutation EmailCheck($input: EmailCheckRequest!) {
    emailCheck(input: $input) {
      eligible
      closed
    }
  }
`;
export const vinCheck = /* GraphQL */ `
  mutation VinCheck($input: VinCheckRequest!) {
    vinCheck(input: $input) {
      vinExists
      smartcarCompatible
    }
  }
`;
export const previewEmail = /* GraphQL */ `
  mutation PreviewEmail($input: PreviewEmailRequest!) {
    previewEmail(input: $input) {
      result
    }
  }
`;
export const createConfiguration = /* GraphQL */ `
  mutation CreateConfiguration(
    $input: CreateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    createConfiguration(input: $input, condition: $condition) {
      key
      name
      description
      value
      options {
        name
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConfiguration = /* GraphQL */ `
  mutation UpdateConfiguration(
    $input: UpdateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    updateConfiguration(input: $input, condition: $condition) {
      key
      name
      description
      value
      options {
        name
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfiguration = /* GraphQL */ `
  mutation DeleteConfiguration(
    $input: DeleteConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    deleteConfiguration(input: $input, condition: $condition) {
      key
      name
      description
      value
      options {
        name
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCordon = /* GraphQL */ `
  mutation CreateCordon(
    $input: CreateCordonInput!
    $condition: ModelCordonConditionInput
  ) {
    createCordon(input: $input, condition: $condition) {
      id
      state
      name
      description
      zones {
        name
        description
        color
        polyline
      }
      entryFeeCents
      exitFeeCents
      centsPerMileageUnit
      days
      internalStart
      internalEnd
      minimumDistanceInMileageUnit
      minimumTotalDistanceInMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const updateCordon = /* GraphQL */ `
  mutation UpdateCordon(
    $input: UpdateCordonInput!
    $condition: ModelCordonConditionInput
  ) {
    updateCordon(input: $input, condition: $condition) {
      id
      state
      name
      description
      zones {
        name
        description
        color
        polyline
      }
      entryFeeCents
      exitFeeCents
      centsPerMileageUnit
      days
      internalStart
      internalEnd
      minimumDistanceInMileageUnit
      minimumTotalDistanceInMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const deleteCordon = /* GraphQL */ `
  mutation DeleteCordon(
    $input: DeleteCordonInput!
    $condition: ModelCordonConditionInput
  ) {
    deleteCordon(input: $input, condition: $condition) {
      id
      state
      name
      description
      zones {
        name
        description
        color
        polyline
      }
      entryFeeCents
      exitFeeCents
      centsPerMileageUnit
      days
      internalStart
      internalEnd
      minimumDistanceInMileageUnit
      minimumTotalDistanceInMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const createCounter = /* GraphQL */ `
  mutation CreateCounter(
    $input: CreateCounterInput!
    $condition: ModelCounterConditionInput
  ) {
    createCounter(input: $input, condition: $condition) {
      countername
      count
      createdAt
      updatedAt
    }
  }
`;
export const updateCounter = /* GraphQL */ `
  mutation UpdateCounter(
    $input: UpdateCounterInput!
    $condition: ModelCounterConditionInput
  ) {
    updateCounter(input: $input, condition: $condition) {
      countername
      count
      createdAt
      updatedAt
    }
  }
`;
export const deleteCounter = /* GraphQL */ `
  mutation DeleteCounter(
    $input: DeleteCounterInput!
    $condition: ModelCounterConditionInput
  ) {
    deleteCounter(input: $input, condition: $condition) {
      countername
      count
      createdAt
      updatedAt
    }
  }
`;
export const createEmailTemplate = /* GraphQL */ `
  mutation CreateEmailTemplate(
    $input: CreateEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    createEmailTemplate(input: $input, condition: $condition) {
      key
      enabled
      sortOrder
      name
      scenario
      subject
      text
      html
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateEmailTemplate = /* GraphQL */ `
  mutation UpdateEmailTemplate(
    $input: UpdateEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    updateEmailTemplate(input: $input, condition: $condition) {
      key
      enabled
      sortOrder
      name
      scenario
      subject
      text
      html
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteEmailTemplate = /* GraphQL */ `
  mutation DeleteEmailTemplate(
    $input: DeleteEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    deleteEmailTemplate(input: $input, condition: $condition) {
      key
      enabled
      sortOrder
      name
      scenario
      subject
      text
      html
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createEpaVehicle = /* GraphQL */ `
  mutation CreateEpaVehicle(
    $input: CreateEpaVehicleInput!
    $condition: ModelEpaVehicleConditionInput
  ) {
    createEpaVehicle(input: $input, condition: $condition) {
      id
      make
      model
      year
      cylinders
      trany
      fuelType
      fuelType1
      fuelType2
      mpgCombined
      mpgCity
      mpgHighway
      kplCombined
      kplCity
      kplHighway
      displacement
      createdAt
      updatedAt
    }
  }
`;
export const updateEpaVehicle = /* GraphQL */ `
  mutation UpdateEpaVehicle(
    $input: UpdateEpaVehicleInput!
    $condition: ModelEpaVehicleConditionInput
  ) {
    updateEpaVehicle(input: $input, condition: $condition) {
      id
      make
      model
      year
      cylinders
      trany
      fuelType
      fuelType1
      fuelType2
      mpgCombined
      mpgCity
      mpgHighway
      kplCombined
      kplCity
      kplHighway
      displacement
      createdAt
      updatedAt
    }
  }
`;
export const deleteEpaVehicle = /* GraphQL */ `
  mutation DeleteEpaVehicle(
    $input: DeleteEpaVehicleInput!
    $condition: ModelEpaVehicleConditionInput
  ) {
    deleteEpaVehicle(input: $input, condition: $condition) {
      id
      make
      model
      year
      cylinders
      trany
      fuelType
      fuelType1
      fuelType2
      mpgCombined
      mpgCity
      mpgHighway
      kplCombined
      kplCity
      kplHighway
      displacement
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      key
      timestamp
      eventId
      eventName
      diff {
        key
        old
        new
      }
      note
      username
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      key
      timestamp
      eventId
      eventName
      diff {
        key
        old
        new
      }
      note
      username
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      key
      timestamp
      eventId
      eventName
      diff {
        key
        old
        new
      }
      note
      username
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createMROEvent = /* GraphQL */ `
  mutation CreateMROEvent(
    $input: CreateMROEventInput!
    $condition: ModelMROEventConditionInput
  ) {
    createMROEvent(input: $input, condition: $condition) {
      code
      odometerReadingStart
      odometerReading
      mileage
      type
      description
      dateTime
      source
      processLogs
      flagged
      isProcessed
      id
      mroDeviceSerialNumber
      username
      vehicleId
      tripId
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMROEvent = /* GraphQL */ `
  mutation UpdateMROEvent(
    $input: UpdateMROEventInput!
    $condition: ModelMROEventConditionInput
  ) {
    updateMROEvent(input: $input, condition: $condition) {
      code
      odometerReadingStart
      odometerReading
      mileage
      type
      description
      dateTime
      source
      processLogs
      flagged
      isProcessed
      id
      mroDeviceSerialNumber
      username
      vehicleId
      tripId
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMROEvent = /* GraphQL */ `
  mutation DeleteMROEvent(
    $input: DeleteMROEventInput!
    $condition: ModelMROEventConditionInput
  ) {
    deleteMROEvent(input: $input, condition: $condition) {
      code
      odometerReadingStart
      odometerReading
      mileage
      type
      description
      dateTime
      source
      processLogs
      flagged
      isProcessed
      id
      mroDeviceSerialNumber
      username
      vehicleId
      tripId
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMRORate = /* GraphQL */ `
  mutation CreateMRORate(
    $input: CreateMRORateInput!
    $condition: ModelMRORateConditionInput
  ) {
    createMRORate(input: $input, condition: $condition) {
      mroType
      state
      centsPerMileageUnit
      centsPerFuelUnit
      centsPerFuelUnitForDiesel
      createdAt
      updatedAt
    }
  }
`;
export const updateMRORate = /* GraphQL */ `
  mutation UpdateMRORate(
    $input: UpdateMRORateInput!
    $condition: ModelMRORateConditionInput
  ) {
    updateMRORate(input: $input, condition: $condition) {
      mroType
      state
      centsPerMileageUnit
      centsPerFuelUnit
      centsPerFuelUnitForDiesel
      createdAt
      updatedAt
    }
  }
`;
export const deleteMRORate = /* GraphQL */ `
  mutation DeleteMRORate(
    $input: DeleteMRORateInput!
    $condition: ModelMRORateConditionInput
  ) {
    deleteMRORate(input: $input, condition: $condition) {
      mroType
      state
      centsPerMileageUnit
      centsPerFuelUnit
      centsPerFuelUnitForDiesel
      createdAt
      updatedAt
    }
  }
`;
export const createMileageReport = /* GraphQL */ `
  mutation CreateMileageReport(
    $input: CreateMileageReportInput!
    $condition: ModelMileageReportConditionInput
  ) {
    createMileageReport(input: $input, condition: $condition) {
      auditStatus
      id
      odoMileage
      photos {
        mimeType
        size
        storageKey
      }
      reason
      tsReportDate
      createdBy
      createdAt
      updatedBy
      updatedAt
      username
      vehicleId
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const updateMileageReport = /* GraphQL */ `
  mutation UpdateMileageReport(
    $input: UpdateMileageReportInput!
    $condition: ModelMileageReportConditionInput
  ) {
    updateMileageReport(input: $input, condition: $condition) {
      auditStatus
      id
      odoMileage
      photos {
        mimeType
        size
        storageKey
      }
      reason
      tsReportDate
      createdBy
      createdAt
      updatedBy
      updatedAt
      username
      vehicleId
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const deleteMileageReport = /* GraphQL */ `
  mutation DeleteMileageReport(
    $input: DeleteMileageReportInput!
    $condition: ModelMileageReportConditionInput
  ) {
    deleteMileageReport(input: $input, condition: $condition) {
      auditStatus
      id
      odoMileage
      photos {
        mimeType
        size
        storageKey
      }
      reason
      tsReportDate
      createdBy
      createdAt
      updatedBy
      updatedAt
      username
      vehicleId
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createMileageReportingOption = /* GraphQL */ `
  mutation CreateMileageReportingOption(
    $input: CreateMileageReportingOptionInput!
    $condition: ModelMileageReportingOptionConditionInput
  ) {
    createMileageReportingOption(input: $input, condition: $condition) {
      username
      id
      vehicleId
      deviceSerialNumber
      deviceImei
      manufacturer
      model
      hardwareVersion
      firmwareVersion
      gpsEnabled
      configurableParameters
      shippedDate
      shippingCarrier
      shippingTrackingNumber
      deliveredDate
      activationDate
      firstReportedDate
      lastReportedDate
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
export const updateMileageReportingOption = /* GraphQL */ `
  mutation UpdateMileageReportingOption(
    $input: UpdateMileageReportingOptionInput!
    $condition: ModelMileageReportingOptionConditionInput
  ) {
    updateMileageReportingOption(input: $input, condition: $condition) {
      username
      id
      vehicleId
      deviceSerialNumber
      deviceImei
      manufacturer
      model
      hardwareVersion
      firmwareVersion
      gpsEnabled
      configurableParameters
      shippedDate
      shippingCarrier
      shippingTrackingNumber
      deliveredDate
      activationDate
      firstReportedDate
      lastReportedDate
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
export const deleteMileageReportingOption = /* GraphQL */ `
  mutation DeleteMileageReportingOption(
    $input: DeleteMileageReportingOptionInput!
    $condition: ModelMileageReportingOptionConditionInput
  ) {
    deleteMileageReportingOption(input: $input, condition: $condition) {
      username
      id
      vehicleId
      deviceSerialNumber
      deviceImei
      manufacturer
      model
      hardwareVersion
      firmwareVersion
      gpsEnabled
      configurableParameters
      shippedDate
      shippingCarrier
      shippingTrackingNumber
      deliveredDate
      activationDate
      firstReportedDate
      lastReportedDate
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      username
      type
      templateName
      email
      subject
      text
      html
      shouldBeSentAt
      retries
      status
      statusMsg
      sentAt
      createdBy
      createdAt
      logs
      inquiryId
      emailMessageId
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      username
      type
      templateName
      email
      subject
      text
      html
      shouldBeSentAt
      retries
      status
      statusMsg
      sentAt
      createdBy
      createdAt
      logs
      inquiryId
      emailMessageId
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      username
      type
      templateName
      email
      subject
      text
      html
      shouldBeSentAt
      retries
      status
      statusMsg
      sentAt
      createdBy
      createdAt
      logs
      inquiryId
      emailMessageId
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      updatedAt
    }
  }
`;
export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant(
    $input: CreateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    createParticipant(input: $input, condition: $condition) {
      accountNo
      address {
        address1
        address2
        city
        state
        postalCode
        extendedPostalCode
      }
      ageGroup
      closedDate
      closedReason
      closedBy
      createdAt
      createdBy
      email
      firstDataReceivedDate
      firstName
      gender
      governmentAffiliation
      flags {
        hasIntegrityViolation
        isBillingOverdue
        isBillingDefault
        isInactive
        isVinMismatch
        isLegislator
        isVIP
        isGovernmentEmployee
        isCaliforniaElected
        agreeGlobalParticipantAgreement
        agreeGlobalPrivacyPolicy
      }
      lastName
      middleName
      mroDataProviderPreference
      mroDevicePreference
      mroManualCapture
      onboardedDate
      participantPilotProgramId
      phoneNumber
      pilotProgram {
        id
        category
        name
        shortName
        isDeviceRequired
        isGpsRequired
        description
        createdAt
        updatedAt
      }
      preferredContactType
      roadChargeCreditCents
      status
      statusMessage
      sumFuelTaxCreditCents
      sumMileageUserFeeCents
      updatedBy
      updatedAt
      username
      vehicles {
        nextToken
      }
    }
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant(
    $input: UpdateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    updateParticipant(input: $input, condition: $condition) {
      accountNo
      address {
        address1
        address2
        city
        state
        postalCode
        extendedPostalCode
      }
      ageGroup
      closedDate
      closedReason
      closedBy
      createdAt
      createdBy
      email
      firstDataReceivedDate
      firstName
      gender
      governmentAffiliation
      flags {
        hasIntegrityViolation
        isBillingOverdue
        isBillingDefault
        isInactive
        isVinMismatch
        isLegislator
        isVIP
        isGovernmentEmployee
        isCaliforniaElected
        agreeGlobalParticipantAgreement
        agreeGlobalPrivacyPolicy
      }
      lastName
      middleName
      mroDataProviderPreference
      mroDevicePreference
      mroManualCapture
      onboardedDate
      participantPilotProgramId
      phoneNumber
      pilotProgram {
        id
        category
        name
        shortName
        isDeviceRequired
        isGpsRequired
        description
        createdAt
        updatedAt
      }
      preferredContactType
      roadChargeCreditCents
      status
      statusMessage
      sumFuelTaxCreditCents
      sumMileageUserFeeCents
      updatedBy
      updatedAt
      username
      vehicles {
        nextToken
      }
    }
  }
`;
export const deleteParticipant = /* GraphQL */ `
  mutation DeleteParticipant(
    $input: DeleteParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    deleteParticipant(input: $input, condition: $condition) {
      accountNo
      address {
        address1
        address2
        city
        state
        postalCode
        extendedPostalCode
      }
      ageGroup
      closedDate
      closedReason
      closedBy
      createdAt
      createdBy
      email
      firstDataReceivedDate
      firstName
      gender
      governmentAffiliation
      flags {
        hasIntegrityViolation
        isBillingOverdue
        isBillingDefault
        isInactive
        isVinMismatch
        isLegislator
        isVIP
        isGovernmentEmployee
        isCaliforniaElected
        agreeGlobalParticipantAgreement
        agreeGlobalPrivacyPolicy
      }
      lastName
      middleName
      mroDataProviderPreference
      mroDevicePreference
      mroManualCapture
      onboardedDate
      participantPilotProgramId
      phoneNumber
      pilotProgram {
        id
        category
        name
        shortName
        isDeviceRequired
        isGpsRequired
        description
        createdAt
        updatedAt
      }
      preferredContactType
      roadChargeCreditCents
      status
      statusMessage
      sumFuelTaxCreditCents
      sumMileageUserFeeCents
      updatedBy
      updatedAt
      username
      vehicles {
        nextToken
      }
    }
  }
`;
export const createParticipantConnection = /* GraphQL */ `
  mutation CreateParticipantConnection(
    $input: CreateParticipantConnectionInput!
    $condition: ModelParticipantConnectionConditionInput
  ) {
    createParticipantConnection(input: $input, condition: $condition) {
      accessToken
      authorizationCode
      externalVehicleId
      refreshToken
      resourceProvider
      id
      username
      vehicleId
      createdAt
      updatedAt
      forceRestoreConnection
    }
  }
`;
export const updateParticipantConnection = /* GraphQL */ `
  mutation UpdateParticipantConnection(
    $input: UpdateParticipantConnectionInput!
    $condition: ModelParticipantConnectionConditionInput
  ) {
    updateParticipantConnection(input: $input, condition: $condition) {
      accessToken
      authorizationCode
      externalVehicleId
      refreshToken
      resourceProvider
      id
      username
      vehicleId
      createdAt
      updatedAt
      forceRestoreConnection
    }
  }
`;
export const deleteParticipantConnection = /* GraphQL */ `
  mutation DeleteParticipantConnection(
    $input: DeleteParticipantConnectionInput!
    $condition: ModelParticipantConnectionConditionInput
  ) {
    deleteParticipantConnection(input: $input, condition: $condition) {
      accessToken
      authorizationCode
      externalVehicleId
      refreshToken
      resourceProvider
      id
      username
      vehicleId
      createdAt
      updatedAt
      forceRestoreConnection
    }
  }
`;
export const createParticipantInquiry = /* GraphQL */ `
  mutation CreateParticipantInquiry(
    $input: CreateParticipantInquiryInput!
    $condition: ModelParticipantInquiryConditionInput
  ) {
    createParticipantInquiry(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      name
      email
      emailOriginalMessageId
      emailS3Key
      bodyHtml
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      comments {
        nextToken
      }
    }
  }
`;
export const updateParticipantInquiry = /* GraphQL */ `
  mutation UpdateParticipantInquiry(
    $input: UpdateParticipantInquiryInput!
    $condition: ModelParticipantInquiryConditionInput
  ) {
    updateParticipantInquiry(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      name
      email
      emailOriginalMessageId
      emailS3Key
      bodyHtml
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      comments {
        nextToken
      }
    }
  }
`;
export const deleteParticipantInquiry = /* GraphQL */ `
  mutation DeleteParticipantInquiry(
    $input: DeleteParticipantInquiryInput!
    $condition: ModelParticipantInquiryConditionInput
  ) {
    deleteParticipantInquiry(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      name
      email
      emailOriginalMessageId
      emailS3Key
      bodyHtml
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      comments {
        nextToken
      }
    }
  }
`;
export const createParticipantInquiryComment = /* GraphQL */ `
  mutation CreateParticipantInquiryComment(
    $input: CreateParticipantInquiryCommentInput!
    $condition: ModelParticipantInquiryCommentConditionInput
  ) {
    createParticipantInquiryComment(input: $input, condition: $condition) {
      id
      inquiryId
      username
      author
      body
      type
      status
      bodyHtml
      emailPayload {
        from
        to
        subject
        text
        inReplyTo
        html
      }
      emailS3Key
      emailAttachments {
        s3Key
        filename
        contentType
        size
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateParticipantInquiryComment = /* GraphQL */ `
  mutation UpdateParticipantInquiryComment(
    $input: UpdateParticipantInquiryCommentInput!
    $condition: ModelParticipantInquiryCommentConditionInput
  ) {
    updateParticipantInquiryComment(input: $input, condition: $condition) {
      id
      inquiryId
      username
      author
      body
      type
      status
      bodyHtml
      emailPayload {
        from
        to
        subject
        text
        inReplyTo
        html
      }
      emailS3Key
      emailAttachments {
        s3Key
        filename
        contentType
        size
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteParticipantInquiryComment = /* GraphQL */ `
  mutation DeleteParticipantInquiryComment(
    $input: DeleteParticipantInquiryCommentInput!
    $condition: ModelParticipantInquiryCommentConditionInput
  ) {
    deleteParticipantInquiryComment(input: $input, condition: $condition) {
      id
      inquiryId
      username
      author
      body
      type
      status
      bodyHtml
      emailPayload {
        from
        to
        subject
        text
        inReplyTo
        html
      }
      emailS3Key
      emailAttachments {
        s3Key
        filename
        contentType
        size
      }
      createdAt
      updatedAt
    }
  }
`;
export const createParticipantStatement = /* GraphQL */ `
  mutation CreateParticipantStatement(
    $input: CreateParticipantStatementInput!
    $condition: ModelParticipantStatementConditionInput
  ) {
    createParticipantStatement(input: $input, condition: $condition) {
      id
      username
      month
      periodFrom
      periodTo
      filename
      mileageUnit
      fuelUnit
      rifFee
      mileage
      fuel
      trips
      fuelTaxCredit
      totalNewMileageFee
      totalNewAdjCharges
      totalNewChages
      carryoverBalance
      balance
      paidAmount
      paidAt
      note
      isProcessed
      shouldSendEmailToParticipant
      tripsSummary {
        vehicleId
        privateFuelFee
        privateFuels
        privateMileage
        privateMileageFee
        privateNet
        privateTaxDifference
        publicFuelFee
        publicFuels
        publicMileage
        publicMileageFee
        publicTaxDifference
        stateCode
      }
      adjustmentsSummary {
        vehicleId
        adjMileage
        adjMileageFee
        adjFuel
        adjFuelFee
        shortDate
      }
      vehiclesSummary {
        vehicleId
        fuel
        fuelFee
        mileage
        mileageFee
        taxDifference
      }
      transactions {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateParticipantStatement = /* GraphQL */ `
  mutation UpdateParticipantStatement(
    $input: UpdateParticipantStatementInput!
    $condition: ModelParticipantStatementConditionInput
  ) {
    updateParticipantStatement(input: $input, condition: $condition) {
      id
      username
      month
      periodFrom
      periodTo
      filename
      mileageUnit
      fuelUnit
      rifFee
      mileage
      fuel
      trips
      fuelTaxCredit
      totalNewMileageFee
      totalNewAdjCharges
      totalNewChages
      carryoverBalance
      balance
      paidAmount
      paidAt
      note
      isProcessed
      shouldSendEmailToParticipant
      tripsSummary {
        vehicleId
        privateFuelFee
        privateFuels
        privateMileage
        privateMileageFee
        privateNet
        privateTaxDifference
        publicFuelFee
        publicFuels
        publicMileage
        publicMileageFee
        publicTaxDifference
        stateCode
      }
      adjustmentsSummary {
        vehicleId
        adjMileage
        adjMileageFee
        adjFuel
        adjFuelFee
        shortDate
      }
      vehiclesSummary {
        vehicleId
        fuel
        fuelFee
        mileage
        mileageFee
        taxDifference
      }
      transactions {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteParticipantStatement = /* GraphQL */ `
  mutation DeleteParticipantStatement(
    $input: DeleteParticipantStatementInput!
    $condition: ModelParticipantStatementConditionInput
  ) {
    deleteParticipantStatement(input: $input, condition: $condition) {
      id
      username
      month
      periodFrom
      periodTo
      filename
      mileageUnit
      fuelUnit
      rifFee
      mileage
      fuel
      trips
      fuelTaxCredit
      totalNewMileageFee
      totalNewAdjCharges
      totalNewChages
      carryoverBalance
      balance
      paidAmount
      paidAt
      note
      isProcessed
      shouldSendEmailToParticipant
      tripsSummary {
        vehicleId
        privateFuelFee
        privateFuels
        privateMileage
        privateMileageFee
        privateNet
        privateTaxDifference
        publicFuelFee
        publicFuels
        publicMileage
        publicMileageFee
        publicTaxDifference
        stateCode
      }
      adjustmentsSummary {
        vehicleId
        adjMileage
        adjMileageFee
        adjFuel
        adjFuelFee
        shortDate
      }
      vehiclesSummary {
        vehicleId
        fuel
        fuelFee
        mileage
        mileageFee
        taxDifference
      }
      transactions {
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPaymentCard = /* GraphQL */ `
  mutation CreatePaymentCard(
    $input: CreatePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    createPaymentCard(input: $input, condition: $condition) {
      alias
      billingCountryCode
      billingZip
      brand
      createdBy
      createdAt
      expirationMonth
      expirationYear
      externalId
      fingerprint
      id
      isDefault
      last4
      nameOnCard
      updatedBy
      updatedAt
      username
      showAsStored
      serviceProviderKey
      token
    }
  }
`;
export const updatePaymentCard = /* GraphQL */ `
  mutation UpdatePaymentCard(
    $input: UpdatePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    updatePaymentCard(input: $input, condition: $condition) {
      alias
      billingCountryCode
      billingZip
      brand
      createdBy
      createdAt
      expirationMonth
      expirationYear
      externalId
      fingerprint
      id
      isDefault
      last4
      nameOnCard
      updatedBy
      updatedAt
      username
      showAsStored
      serviceProviderKey
      token
    }
  }
`;
export const deletePaymentCard = /* GraphQL */ `
  mutation DeletePaymentCard(
    $input: DeletePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    deletePaymentCard(input: $input, condition: $condition) {
      alias
      billingCountryCode
      billingZip
      brand
      createdBy
      createdAt
      expirationMonth
      expirationYear
      externalId
      fingerprint
      id
      isDefault
      last4
      nameOnCard
      updatedBy
      updatedAt
      username
      showAsStored
      serviceProviderKey
      token
    }
  }
`;
export const createPilotProgram = /* GraphQL */ `
  mutation CreatePilotProgram(
    $input: CreatePilotProgramInput!
    $condition: ModelPilotProgramConditionInput
  ) {
    createPilotProgram(input: $input, condition: $condition) {
      id
      category
      name
      shortName
      isDeviceRequired
      isGpsRequired
      description
      details {
        participantsSelected
        assetsAndRoadScope
        participantStatement
        minimumDataRequired
        locationGPS
      }
      participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePilotProgram = /* GraphQL */ `
  mutation UpdatePilotProgram(
    $input: UpdatePilotProgramInput!
    $condition: ModelPilotProgramConditionInput
  ) {
    updatePilotProgram(input: $input, condition: $condition) {
      id
      category
      name
      shortName
      isDeviceRequired
      isGpsRequired
      description
      details {
        participantsSelected
        assetsAndRoadScope
        participantStatement
        minimumDataRequired
        locationGPS
      }
      participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePilotProgram = /* GraphQL */ `
  mutation DeletePilotProgram(
    $input: DeletePilotProgramInput!
    $condition: ModelPilotProgramConditionInput
  ) {
    deletePilotProgram(input: $input, condition: $condition) {
      id
      category
      name
      shortName
      isDeviceRequired
      isGpsRequired
      description
      details {
        participantsSelected
        assetsAndRoadScope
        participantStatement
        minimumDataRequired
        locationGPS
      }
      participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTod = /* GraphQL */ `
  mutation CreateTod(
    $input: CreateTodInput!
    $condition: ModelTodConditionInput
  ) {
    createTod(input: $input, condition: $condition) {
      id
      name
      days
      internalStart
      internalEnd
      centsPerMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const updateTod = /* GraphQL */ `
  mutation UpdateTod(
    $input: UpdateTodInput!
    $condition: ModelTodConditionInput
  ) {
    updateTod(input: $input, condition: $condition) {
      id
      name
      days
      internalStart
      internalEnd
      centsPerMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const deleteTod = /* GraphQL */ `
  mutation DeleteTod(
    $input: DeleteTodInput!
    $condition: ModelTodConditionInput
  ) {
    deleteTod(input: $input, condition: $condition) {
      id
      name
      days
      internalStart
      internalEnd
      centsPerMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const createToll = /* GraphQL */ `
  mutation CreateToll(
    $input: CreateTollInput!
    $condition: ModelTollConditionInput
  ) {
    createToll(input: $input, condition: $condition) {
      id
      state
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateToll = /* GraphQL */ `
  mutation UpdateToll(
    $input: UpdateTollInput!
    $condition: ModelTollConditionInput
  ) {
    updateToll(input: $input, condition: $condition) {
      id
      state
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteToll = /* GraphQL */ `
  mutation DeleteToll(
    $input: DeleteTollInput!
    $condition: ModelTollConditionInput
  ) {
    deleteToll(input: $input, condition: $condition) {
      id
      state
      name
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      paymentProcessorResponse {
        referenceNumber
        transactionResponse
      }
      paidAt
      description
      type
      retries
      status
      statusMessage
      subtotalCents
      discountCents
      amountCents
      tripSegments {
        nextToken
      }
      tripAdjustments {
        nextToken
      }
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      paymentMethodId
      statementId
      carryoverTransactionId
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      paymentProcessorResponse {
        referenceNumber
        transactionResponse
      }
      paidAt
      description
      type
      retries
      status
      statusMessage
      subtotalCents
      discountCents
      amountCents
      tripSegments {
        nextToken
      }
      tripAdjustments {
        nextToken
      }
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      paymentMethodId
      statementId
      carryoverTransactionId
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      paymentProcessorResponse {
        referenceNumber
        transactionResponse
      }
      paidAt
      description
      type
      retries
      status
      statusMessage
      subtotalCents
      discountCents
      amountCents
      tripSegments {
        nextToken
      }
      tripAdjustments {
        nextToken
      }
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      paymentMethodId
      statementId
      carryoverTransactionId
    }
  }
`;
export const createTrip = /* GraphQL */ `
  mutation CreateTrip(
    $input: CreateTripInput!
    $condition: ModelTripConditionInput
  ) {
    createTrip(input: $input, condition: $condition) {
      id
      recordId
      recordTripId
      mroDeviceSerialNumber
      vehicleVin
      tsStart
      tsEnd
      polyline
      distMro
      odoMro
      distGps
      distRoute
      fuel
      odoGps
      odoMroStart
      odoMroEnd
      fuelStart
      fuelEnd
      fuelAdded
      totalFuel
      odometerBin {
        odo
        timestamp
      }
      username
      vehicleId
      pilotProgramId
      mroId
      createdAt
      processStatus
      processMsg
      processedAt
      logs
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      reviewStatus
      reviewReason
      polylines {
        polyline
        description
        createdAt
        createdBy
      }
      invalid
      routeEngine
      routeEngineVersion
      adjustments {
        nextToken
      }
      notes
      tripSegments {
        nextToken
      }
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      updatedAt
    }
  }
`;
export const updateTrip = /* GraphQL */ `
  mutation UpdateTrip(
    $input: UpdateTripInput!
    $condition: ModelTripConditionInput
  ) {
    updateTrip(input: $input, condition: $condition) {
      id
      recordId
      recordTripId
      mroDeviceSerialNumber
      vehicleVin
      tsStart
      tsEnd
      polyline
      distMro
      odoMro
      distGps
      distRoute
      fuel
      odoGps
      odoMroStart
      odoMroEnd
      fuelStart
      fuelEnd
      fuelAdded
      totalFuel
      odometerBin {
        odo
        timestamp
      }
      username
      vehicleId
      pilotProgramId
      mroId
      createdAt
      processStatus
      processMsg
      processedAt
      logs
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      reviewStatus
      reviewReason
      polylines {
        polyline
        description
        createdAt
        createdBy
      }
      invalid
      routeEngine
      routeEngineVersion
      adjustments {
        nextToken
      }
      notes
      tripSegments {
        nextToken
      }
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      updatedAt
    }
  }
`;
export const deleteTrip = /* GraphQL */ `
  mutation DeleteTrip(
    $input: DeleteTripInput!
    $condition: ModelTripConditionInput
  ) {
    deleteTrip(input: $input, condition: $condition) {
      id
      recordId
      recordTripId
      mroDeviceSerialNumber
      vehicleVin
      tsStart
      tsEnd
      polyline
      distMro
      odoMro
      distGps
      distRoute
      fuel
      odoGps
      odoMroStart
      odoMroEnd
      fuelStart
      fuelEnd
      fuelAdded
      totalFuel
      odometerBin {
        odo
        timestamp
      }
      username
      vehicleId
      pilotProgramId
      mroId
      createdAt
      processStatus
      processMsg
      processedAt
      logs
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      reviewStatus
      reviewReason
      polylines {
        polyline
        description
        createdAt
        createdBy
      }
      invalid
      routeEngine
      routeEngineVersion
      adjustments {
        nextToken
      }
      notes
      tripSegments {
        nextToken
      }
      vehicle {
        vin
        make
        model
        year
        type
        subtype
        licensePlate
        registrationState
        isPrimary
        mroType
        status
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        lastReportedVinDate
        logs
        firstDataReceivedDate
        inactiveDate
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      updatedAt
    }
  }
`;
export const createTripAdjustment = /* GraphQL */ `
  mutation CreateTripAdjustment(
    $input: CreateTripAdjustmentInput!
    $condition: ModelTripAdjustmentConditionInput
  ) {
    createTripAdjustment(input: $input, condition: $condition) {
      tripId
      id
      username
      vehicleId
      tripSegmentId
      tripSegmentDate
      type
      state
      stateCode
      cordonId
      cordonName
      todId
      todName
      tollId
      adjMileage
      adjMileageFeeCents
      adjFuel
      adjFuelFeeCents
      adjCordonFeeCents
      adjTodFeeCents
      adjTollFeeCents
      note
      content
      createdBy
      createdAt
      transactionId
      paymentStatus
      updatedAt
    }
  }
`;
export const updateTripAdjustment = /* GraphQL */ `
  mutation UpdateTripAdjustment(
    $input: UpdateTripAdjustmentInput!
    $condition: ModelTripAdjustmentConditionInput
  ) {
    updateTripAdjustment(input: $input, condition: $condition) {
      tripId
      id
      username
      vehicleId
      tripSegmentId
      tripSegmentDate
      type
      state
      stateCode
      cordonId
      cordonName
      todId
      todName
      tollId
      adjMileage
      adjMileageFeeCents
      adjFuel
      adjFuelFeeCents
      adjCordonFeeCents
      adjTodFeeCents
      adjTollFeeCents
      note
      content
      createdBy
      createdAt
      transactionId
      paymentStatus
      updatedAt
    }
  }
`;
export const deleteTripAdjustment = /* GraphQL */ `
  mutation DeleteTripAdjustment(
    $input: DeleteTripAdjustmentInput!
    $condition: ModelTripAdjustmentConditionInput
  ) {
    deleteTripAdjustment(input: $input, condition: $condition) {
      tripId
      id
      username
      vehicleId
      tripSegmentId
      tripSegmentDate
      type
      state
      stateCode
      cordonId
      cordonName
      todId
      todName
      tollId
      adjMileage
      adjMileageFeeCents
      adjFuel
      adjFuelFeeCents
      adjCordonFeeCents
      adjTodFeeCents
      adjTollFeeCents
      note
      content
      createdBy
      createdAt
      transactionId
      paymentStatus
      updatedAt
    }
  }
`;
export const createTripBin = /* GraphQL */ `
  mutation CreateTripBin(
    $input: CreateTripBinInput!
    $condition: ModelTripBinConditionInput
  ) {
    createTripBin(input: $input, condition: $condition) {
      tripId
      id
      username
      lat
      lng
      timestamp
      timeZone
      createdAt
      updatedAt
    }
  }
`;
export const updateTripBin = /* GraphQL */ `
  mutation UpdateTripBin(
    $input: UpdateTripBinInput!
    $condition: ModelTripBinConditionInput
  ) {
    updateTripBin(input: $input, condition: $condition) {
      tripId
      id
      username
      lat
      lng
      timestamp
      timeZone
      createdAt
      updatedAt
    }
  }
`;
export const deleteTripBin = /* GraphQL */ `
  mutation DeleteTripBin(
    $input: DeleteTripBinInput!
    $condition: ModelTripBinConditionInput
  ) {
    deleteTripBin(input: $input, condition: $condition) {
      tripId
      id
      username
      lat
      lng
      timestamp
      timeZone
      createdAt
      updatedAt
    }
  }
`;
export const createTripSegment = /* GraphQL */ `
  mutation CreateTripSegment(
    $input: CreateTripSegmentInput!
    $condition: ModelTripSegmentConditionInput
  ) {
    createTripSegment(input: $input, condition: $condition) {
      id
      tripId
      transactionId
      vehicleId
      username
      createdAt
      tsStart
      tsEnd
      type
      state
      stateCode
      todId
      polylines
      calculatedMileage
      cordonId
      cordonName
      cordonEnterTime
      cordonEnterTimeZone
      cordonEnterFeeCents
      tollTxId
      tollId
      tollEntryId
      tollExitId
      tollEntryTime
      tollExitTime
      percentage
      percentageState
      mileage
      fuel
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      tripBinIds
      paymentStatus
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      transaction {
        id
        username
        createdAt
        createdBy
        updatedAt
        updatedBy
        paidAt
        description
        type
        retries
        status
        statusMessage
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
        statementId
        carryoverTransactionId
      }
      updatedAt
    }
  }
`;
export const updateTripSegment = /* GraphQL */ `
  mutation UpdateTripSegment(
    $input: UpdateTripSegmentInput!
    $condition: ModelTripSegmentConditionInput
  ) {
    updateTripSegment(input: $input, condition: $condition) {
      id
      tripId
      transactionId
      vehicleId
      username
      createdAt
      tsStart
      tsEnd
      type
      state
      stateCode
      todId
      polylines
      calculatedMileage
      cordonId
      cordonName
      cordonEnterTime
      cordonEnterTimeZone
      cordonEnterFeeCents
      tollTxId
      tollId
      tollEntryId
      tollExitId
      tollEntryTime
      tollExitTime
      percentage
      percentageState
      mileage
      fuel
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      tripBinIds
      paymentStatus
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      transaction {
        id
        username
        createdAt
        createdBy
        updatedAt
        updatedBy
        paidAt
        description
        type
        retries
        status
        statusMessage
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
        statementId
        carryoverTransactionId
      }
      updatedAt
    }
  }
`;
export const deleteTripSegment = /* GraphQL */ `
  mutation DeleteTripSegment(
    $input: DeleteTripSegmentInput!
    $condition: ModelTripSegmentConditionInput
  ) {
    deleteTripSegment(input: $input, condition: $condition) {
      id
      tripId
      transactionId
      vehicleId
      username
      createdAt
      tsStart
      tsEnd
      type
      state
      stateCode
      todId
      polylines
      calculatedMileage
      cordonId
      cordonName
      cordonEnterTime
      cordonEnterTimeZone
      cordonEnterFeeCents
      tollTxId
      tollId
      tollEntryId
      tollExitId
      tollEntryTime
      tollExitTime
      percentage
      percentageState
      mileage
      fuel
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      tripBinIds
      paymentStatus
      participant {
        accountNo
        ageGroup
        closedDate
        closedReason
        closedBy
        createdAt
        createdBy
        email
        firstDataReceivedDate
        firstName
        gender
        governmentAffiliation
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        statusMessage
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        updatedAt
        username
      }
      transaction {
        id
        username
        createdAt
        createdBy
        updatedAt
        updatedBy
        paidAt
        description
        type
        retries
        status
        statusMessage
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
        statementId
        carryoverTransactionId
      }
      updatedAt
    }
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle(
    $input: CreateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    createVehicle(input: $input, condition: $condition) {
      vin
      make
      model
      year
      type
      subtype
      licensePlate
      registrationState
      isPrimary
      mroType
      status
      beginningOdometerReading
      currentOdometerReading
      mileage
      fuelTaxCreditCents
      mileageUserFeeCents
      epaVehicleCombinedKpl
      epaVehicleCombinedMpg
      lastReportedVinDate
      logs
      reports {
        odometer
        evCapacity
        engineOilLife
        tsReportDate
      }
      locations {
        latitude
        longitude
        tsLocationDate
      }
      firstDataReceivedDate
      inactiveDate
      gotoll {
        vehicleId
        action
        status
        createdAt
        actionAt
        updatedAt
      }
      id
      username
      epaVehicleId
      mroId
      participantConnectionId
      createdAt
      createdBy
      updatedAt
      updatedBy
      mro {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      connection {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle(
    $input: UpdateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    updateVehicle(input: $input, condition: $condition) {
      vin
      make
      model
      year
      type
      subtype
      licensePlate
      registrationState
      isPrimary
      mroType
      status
      beginningOdometerReading
      currentOdometerReading
      mileage
      fuelTaxCreditCents
      mileageUserFeeCents
      epaVehicleCombinedKpl
      epaVehicleCombinedMpg
      lastReportedVinDate
      logs
      reports {
        odometer
        evCapacity
        engineOilLife
        tsReportDate
      }
      locations {
        latitude
        longitude
        tsLocationDate
      }
      firstDataReceivedDate
      inactiveDate
      gotoll {
        vehicleId
        action
        status
        createdAt
        actionAt
        updatedAt
      }
      id
      username
      epaVehicleId
      mroId
      participantConnectionId
      createdAt
      createdBy
      updatedAt
      updatedBy
      mro {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      connection {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
    }
  }
`;
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle(
    $input: DeleteVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    deleteVehicle(input: $input, condition: $condition) {
      vin
      make
      model
      year
      type
      subtype
      licensePlate
      registrationState
      isPrimary
      mroType
      status
      beginningOdometerReading
      currentOdometerReading
      mileage
      fuelTaxCreditCents
      mileageUserFeeCents
      epaVehicleCombinedKpl
      epaVehicleCombinedMpg
      lastReportedVinDate
      logs
      reports {
        odometer
        evCapacity
        engineOilLife
        tsReportDate
      }
      locations {
        latitude
        longitude
        tsLocationDate
      }
      firstDataReceivedDate
      inactiveDate
      gotoll {
        vehicleId
        action
        status
        createdAt
        actionAt
        updatedAt
      }
      id
      username
      epaVehicleId
      mroId
      participantConnectionId
      createdAt
      createdBy
      updatedAt
      updatedBy
      mro {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      connection {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
    }
  }
`;
export const createWebhookEvent = /* GraphQL */ `
  mutation CreateWebhookEvent(
    $input: CreateWebhookEventInput!
    $condition: ModelWebhookEventConditionInput
  ) {
    createWebhookEvent(input: $input, condition: $condition) {
      id
      status
      source
      sourceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const updateWebhookEvent = /* GraphQL */ `
  mutation UpdateWebhookEvent(
    $input: UpdateWebhookEventInput!
    $condition: ModelWebhookEventConditionInput
  ) {
    updateWebhookEvent(input: $input, condition: $condition) {
      id
      status
      source
      sourceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const deleteWebhookEvent = /* GraphQL */ `
  mutation DeleteWebhookEvent(
    $input: DeleteWebhookEventInput!
    $condition: ModelWebhookEventConditionInput
  ) {
    deleteWebhookEvent(input: $input, condition: $condition) {
      id
      status
      source
      sourceId
      payload
      createdAt
      updatedAt
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      surveyId
      questions {
        type
        question
        answer
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      surveyId
      questions {
        type
        question
        answer
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      surveyId
      questions {
        type
        question
        answer
      }
      createdAt
      updatedAt
    }
  }
`;
