import {
  convertFuel,
  convertMileage,
  simpleDate,
  formatVehicleFuelType,
  formatMroType,
  simpleDateTime,
  formatRucGroupName,
  convertCentsToDollars,
  formatMroTypeCertID,
} from 'utilities/format';

import { retrieveTripData } from './BillingPaymentAuditReport';

function getTripMroType(mroDeviceSerialNumber, mroId, logs, defaultVehicleMroType) {
  // Trip created by mileage reports should have `mroDeviceSerialNumber='N/A'`
  // manual or telematics
  if (mroDeviceSerialNumber === 'N/A' || mroId === 'N/A') {
    return (defaultVehicleMroType === 'telematics') ? 'telematics' : 'manual';
  }

  return defaultVehicleMroType;

  // const hasMatchedString = logs.find((s) => s.startsWith('No gps enabled.'));
  // return hasMatchedString ? 'automatedWithoutLocation' : 'automatedWithLocation';
}

const issues = [];

function mapAdjustments(tripId, adjustments, adjLength) {
  const output = {};
  for (let i = 0; i < adjLength; ++i) {
    if (i < adjustments.length) {
      output[`TRIP${i + 1} ID`] = tripId;
      output[`TRIP${i + 1}_Adjustment ID`] = adjustments[i].id;
      output[`TRIP${i + 1}_Adjustment Date`] = simpleDate(adjustments[i].createdAt);
      output[`TRIP${i + 1}_Adjustment Code`] = ''; // NOTE: There is no adjustment codes
      output[`TRIP${i + 1}_Adjustment Reason`] = adjustments[i].note;
      output[`TRIP${i + 1}_Adjustment_Total Mileage`] = convertMileage(adjustments[i].adjMileage, 'mi');
      output[`TRIP${i + 1}_Adjustment_Total RUC`] = convertCentsToDollars(adjustments[i].adjMileageFeeCents);
      output[`TRIP${i + 1}_Adjustment_Total Fuel Usage`] = convertFuel(adjustments[i].adjFuel, 'gal');
      output[`TRIP${i + 1}_Adjustment_Total Fuel Tax Credit`] = convertCentsToDollars(adjustments[i].adjFuelFeeCents);
      output[`TRIP${i + 1}_Adjustment_Total Net RUC Revenue`] = convertCentsToDollars(adjustments[i].adjMileageFeeCents - adjustments[i].adjFuelFeeCents);
    } else {
      output[`TRIP${i + 1} ID`] = '';
      output[`TRIP${i + 1}_Adjustment ID`] = '';
      output[`TRIP${i + 1}_Adjustment Date`] = '';
      output[`TRIP${i + 1}_Adjustment Code`] = ''; // NOTE: There is no adjustment codes
      output[`TRIP${i + 1}_Adjustment Reason`] = '';
      output[`TRIP${i + 1}_Adjustment_Total Mileage`] = '';
      output[`TRIP${i + 1}_Adjustment_Total RUC`] = '';
      output[`TRIP${i + 1}_Adjustment_Total Fuel Usage`] = '';
      output[`TRIP${i + 1}_Adjustment_Total Fuel Tax Credit`] = '';
      output[`TRIP${i + 1}_Adjustment_Total Net RUC Revenue`] = '';
    }
  }

  return output;
}

export default {
  name: 'Billing Summary (Monthly Trip Details)',
  useDateRange: true,
  async process(fromDate, toDate) {
    const { adjLength, tripData } = await retrieveTripData(fromDate, toDate);

    const data = tripData.map(({
      tripId,
      participant,
      vehicle,
      // transaction,
      // statement,
      // segments,
      adjustments,
      tsStart,
      tsEnd,
      fuel,
      fuelFeeCents,
      mileageFeeCents,
      mroDeviceSerialNumber,
      mroId,
      logs,

      segmentTotals,
    }) => {
      const tripMroType = getTripMroType(mroDeviceSerialNumber, mroId, logs, vehicle.mroType);

      if (tripMroType !== vehicle.mroType) {
        issues.push(
          {
            tripId,
            accountNo: participant.accountNo,
            vehicleMroType: vehicle.mroType,
            tripMroType: tripMroType,
          },
        );
      }

      // RUC-1220 Use the real mro type
      const mroTypeAtTime = tripMroType;

      return Object.assign({
        'RecordID': tripId,
        'AccountID': participant.accountNo,
        'Last Name': participant.lastName,
        'First Name': participant.firstName,
        'RUC Group': formatRucGroupName(participant.pilotProgram?.shortName),
        'VIP': participant.flags?.isVIP ? 'True' : 'False',
        'VIN': vehicle.vin,
        'Vehicle Registration State': vehicle.registrationState,
        'Vehicle Fuel Type': formatVehicleFuelType(vehicle.type),
        'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
        'MRO ID': mroDeviceSerialNumber || vehicle.mro?.deviceSerialNumber || '',
        'MRO Type': formatMroType(mroTypeAtTime),
        'MRO Cert ID': formatMroTypeCertID(mroTypeAtTime),
        'TRIP_ID': tripId,
        'TRIP_Start Date': simpleDateTime(tsStart),
        'TRIP_End Date': simpleDateTime(tsEnd),
        'TRIP_Total Mileage': convertMileage(segmentTotals.totalMileage, 'mi'),
        'TRIP_Total Chargeable Mileage': convertMileage(segmentTotals.chargeableMileage, 'mi'),
        'TRIP_Total Non-Chargeable Mileage': convertMileage(segmentTotals.nonChargeableMileage, 'mi'),
        'TRIP_Total Non-Chargeable Mileage_CA': convertMileage(segmentTotals.nonChargeableMileageCA, 'mi'),
        'TRIP_Total RUC': convertCentsToDollars(mileageFeeCents),
        'TRIP_Total Fuel Usage': convertFuel(fuel, 'gal'),
        'TRIP_Total Fuel Tax Credit': convertCentsToDollars(fuelFeeCents),
        'TRIP_Net RUC Revenue': convertCentsToDollars(mileageFeeCents - fuelFeeCents),
      }, mapAdjustments(tripId, adjustments, adjLength));
    });

    // console.log(issues);
    // console.log(JSON.stringify(issues));

    return data.flat();
  },
};
