import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { theme } from 'index.js';
import { v4 } from 'uuid';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

import ControlledInput from 'components/Form/ControlledInput';

import { listEmailTemplates } from 'graphql/queries';
import { createNotification } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

const LIST_EMAILTEMPLATES_CACHE_KEY = 'EmailTemplateTable-ListEmail';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  secondaryAction: {
    margin: theme.spacing(0, 0, 2),
  },
}));

const CreateNotificationDialog = ({ isOpen, onClose, username: participantUsername }) => {
  const { register, control, errors, watch, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      templateName: 'N/A',
    },
    mode: 'onChange',
  });
  const { isSubmitting } = formState;
  const [error, setError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [useTemplate, setUseTemplate] = useState(false);
  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);

  const watchFields = watch();

  const classes = useStyles();
  const inputs = [{
    type: 'text',
    name: 'type',
    label: 'Type',
    autoFocus: true,
    required: true,
    invalidText: 'Subject is required',
    defaultValue: 'email',
    disabled: true,
  }, {
    type: 'select',
    name: 'templateName',
    label: 'Template',
    options: emailTemplateOptions,
    required: false,
  }, {
    type: 'text',
    name: 'subject',
    label: 'Subject',
    autoFocus: true,
    required: !useTemplate,
    disabled: useTemplate,
    invalidText: 'Subject is required',
  }, {
    type: 'text',
    name: 'text',
    label: 'Text',
    multiline: true,
    rows: 4,
    autoFocus: false,
    required: !useTemplate,
    disabled: useTemplate,
    invalidText: 'Text is required',
  }];

  useEffect(() => {
    if (watchFields) {
      const { subject, text, templateName } = getValues(['subject', 'text', 'templateName']);
      const useTemplate = templateName && templateName !== 'N/A';
      setUseTemplate(useTemplate);

      if (useTemplate) {
        setIsFormValid(true);
      } else {
        setIsFormValid(subject && subject !== '' && text && text !== '');
      }
    }
  }, [watchFields, getValues]);

  useEffect(() => {
    (async () => {
      try {
        const records = await asyncListAll(listEmailTemplates, undefined, { cacheKey: LIST_EMAILTEMPLATES_CACHE_KEY });
        const templateOptions = [
          {
            value: 'N/A',
            label: 'Custom',
          },
          ...records.sort(sortBy('key')).map(({ key, subject }) => {
            return {
              value: key,
              label: `[${key}] ${subject}`,
            };
          }),
        ];

        setEmailTemplateOptions(templateOptions);
      } catch (e) {
        global.logger.debug(e);
      }
    })();
  }, []);

  function handleCloseError() {
    setError(false);
  }

  async function handleCreate({ type, templateName, subject, text }) {
    const cognitoUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    const now = new Date().toISOString();

    try {
      await asyncRetryMutation(createNotification, {
        input: {
          id: v4(),
          username: participantUsername,
          createdBy: cognitoUser.username,
          type,
          templateName,
          subject,
          text,
          html: text,
          shouldBeSentAt: now,
          retries: 0,
          status: 'pending',
        },
      });
      onClose(true);
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="create-notification-dialog"
        open={isOpen}
        onClose={onClose}
        disableBackdropClick={true}
      >
        <DialogContent>
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleCreate)}
            noValidate
          >
            <Grid container spacing={2}>
              {inputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      register={register}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting || !isFormValid}
            >Create Notification</Button>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              disabled={isSubmitting}
              onClick={() => {
                onClose();
              }}
            >Cancel</Button>
          </form>
          <Snackbar
            open={error != false}
            autoHideDuration={5000}
            onClose={handleCloseError}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Alert
              severity="error"
              variant="filled"
              onClose={handleCloseError}>
              {error}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

CreateNotificationDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  username: PropTypes.string,
};

export default CreateNotificationDialog;
