import React from 'react';
import PropTypes from 'prop-types';

import { Switch, Redirect } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import AppBar from './components/AppBar';
import { routes } from '../../routes';
import { APP } from '../../utilities/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  content: {
    'flexGrow': 1,
    'marginTop': theme.mixins.toolbar.minHeight,
    'overflow': 'auto',
    'height': '100%',
    'minHeight': `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 100px)`, // footer
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 32px)`, // footer
    },
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  unstyledHyperlink: {
    textDecoration: 'none',
    color: 'white',
  },
}));

const Home = ({ user }) => {
  const classes = useStyles();
  const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'] || ['N/A'];
  const filteredRoutes = routes.filter((route) => {
    const { roles } = route;

    if (roles) {
      // route has roles, check if user is in group
      return userGroups && userGroups.some((group) => roles.includes(group));
    } else if (!roles) {
      // route has no roles setup
      return true;
    } else {
      // no match
      return false;
    }
  });

  // const maxWidth = userGroups && userGroups.includes('Admins') ? false : 'lg';
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar routes={filteredRoutes} user={user} />
      <div className={classes.content} data-test-id="content">
        <Container maxWidth={false} className={classes.container}>
          <div className={classes.root}>
            <CssBaseline />
            <Switch>
              {filteredRoutes.map((item) => (
                <item.route
                  key={item.path}
                  exact={item.exact}
                  path={item.path}
                  roles={item.roles}
                  user={user}
                  render={(props) => (
                    <DocumentTitle title={`${APP.NAME_PILOT} | ${item.title}`}>
                      <item.component {...props} />
                    </DocumentTitle>)
                  } />
              ))}
              <Redirect to="/dashboard" />
            </Switch>
          </div>
        </Container>
      </div>
    </div>
  );
};

Home.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Home;
