import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import { GetTransactionsByTypeByCreatedAt } from './queries';

import TransactionsTable from 'pages/Admin/components/TransactionsTable';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';
import FilterSelector from 'pages/Admin/components/FilterSelector';
import TransactionsSummary from './components/TransactionsSummary';

import { getTransactionStanding } from 'utilities/constants/paymentStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Transactions() {
  const classes = useStyles();
  const filterDefinition = [
    {
      key: 'status',
      label: 'Transaction Status',
      type: 'radio',
      options: ['all', 'created', 'processed', 'failed'],
    },
    {
      key: 'standing',
      label: 'Standing',
      type: 'radio',
      options: ['all', 'due', 'overdue', 'default'],
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [allTransactions, setAllTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [billingFilters, setBillingFilters] = useState({
    'status': 'all',
    'standing': 'all',
  });

  const onUpdateDateRange = ({ from, to }) => {
    (async () => {
      try {
        setIsLoading(true);
        const results = await asyncListAll(GetTransactionsByTypeByCreatedAt, {
          type: 'transaction',
          createdAt: {
            between: [from, to],
          },
        }, {
          bypassCache: true,
        });

        const transactions = results.map((transaction) => {
          transaction.participant.fullName = `${transaction.participant.firstName} ${transaction.participant.lastName}`;
          transaction.standing = `${getTransactionStanding(transaction.status, transaction.createdAt)}`;
          transaction.tripSegments = transaction.tripSegments.items;
          transaction.tripAdjustments = transaction.tripAdjustments.items;

          if (transaction.paymentProcessorResponse) {
            const {
              referenceNumber,
              transactionResponse,
            } = transaction.paymentProcessorResponse;

            transaction.referenceNumber = referenceNumber;
            const {
              card,
            } = JSON.parse(transactionResponse);
            transaction.paymentType = card.cardType.toUpperCase();
            transaction.paymentInfo = card.cardLastFour;
          }

          return transaction;
        });

        setAllTransactions(transactions.sort(sortBy('createdAt', true)));
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const onUpdateFilters = (key, value) => {
    // update current filters
    const currentFilters = Object.assign({}, billingFilters);
    currentFilters[key] = value;
    setBillingFilters(currentFilters);
  };

  useEffect(() => {
    // filter transactions
    const filtered = allTransactions.filter(({ status }) => {
      return billingFilters['status'] === 'all' ? true : billingFilters['status'] === status ? true : false;
    }).filter(({ status, createdAt }) => {
      if (billingFilters['standing'] === 'all') {
        return true;
      }
      const standing = getTransactionStanding(status, createdAt);
      return billingFilters['standing'] === standing;
    });

    setFilteredTransactions(filtered);
  }, [billingFilters, allTransactions]);

  return (
    <div className={classes.root}>
      <DateRangeSelector
        unit='week'
        onSubmit={onUpdateDateRange}
        submitOnLoad={true}
        useLazyLoad={true}
        disabled={isLoading}
      />
      <FilterSelector
        filterDefinition={filterDefinition}
        activeFilters={billingFilters}
        onUpdate={onUpdateFilters}
      />
      {!isLoading && (
        <>
          <TransactionsSummary transactions={filteredTransactions} />
          <TransactionsTable data={filteredTransactions} />
        </>
      )}
    </div>
  );
}
