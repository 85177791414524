import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { getTrip, listTripSegments } from 'graphql/queries';
import { updateTrip, deleteTripSegment } from 'graphql/mutations';
import { asyncGet, asyncRetryMutation, asyncListAll } from 'utilities/graph';
import { calculatePolylineDistance } from 'utilities/map';
import TripVisualization from 'pages/Admin/Trip/components/TripVisualization';

const StyledPaper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(Paper);

export default function TripReview({
  tripId: inTripId,
  trip: inTrip = {},
  onUpdate,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [trip, setTrip] = useState(inTrip);
  const [polyline, setPolyline] = useState('');
  const [newPolyline, setNewPolyline] = useState('');
  const [distGps, setDistGps] = useState(0);
  const [newDistGps, setNewDistGps] = useState(0);
  const [tripSegments, setTripSegments] = useState([]);
  const [showNewPolylineVisualization, setShowNewPolylineVisualization] = useState(true);

  const handleConfirm = async (response) => {
    if (response) {
      setIsLoading(true);

      // delete trip segments
      if (tripSegments.length > 0) {
        await Promise.all(tripSegments.map(({ tripId, id }) => {
          return asyncRetryMutation(deleteTripSegment, { input: { tripId, id } });
        }));
      }

      const currentUsername = localStorage.getItem('ruc:username');
      const updatedLogs = trip.logs || [];
      const updatedPolylines = trip.polylines || [];
      const distGpsUpdateMsg = newDistGps === trip.distGps ? '' : `Update distGps from ${trip.distGps} to ${newDistGps}.`;
      const polylineUpdateMsg = newPolyline === trip.polyline ? '' : 'Update polyline.';
      updatedLogs.push(`Reprocess trip by admin ${currentUsername}. Delete ${tripSegments.length} existing segments. ${polylineUpdateMsg} ${distGpsUpdateMsg}`); // eslint-disable-line max-len

      // store the original
      if (updatedPolylines.length === 0) {
        updatedPolylines.push({
          polyline: trip.polyline,
          description: 'original polyline',
          createdBy: currentUsername,
          createdAt: trip.createdAt,
        });
      }

      if (newPolyline !== trip.polyline) {
        updatedPolylines.push({
          polyline: newPolyline,
          description: 'update',
          createdBy: currentUsername,
          createdAt: new Date().toISOString(),
        });
      }

      const input = {
        id: trip.id,
        processStatus: 'pending',
        polyline: newPolyline,
        distGps: newDistGps,
        logs: updatedLogs,
        polylines: updatedPolylines,
      };

      const {
        data: {
          updateTrip: result,
        },
      } = await asyncRetryMutation(
        updateTrip,
        { input },
        { clearCacheKeys: ['ListTrips'] },
      );

      setTrip(result);

      if (onUpdate) {
        onUpdate(result);
      }

      setIsLoading(false);
      setHasConfirmed(false);
    }
  };

  useEffect(() => {
    if (inTripId) {
      (async () => {
        const { data: { getTrip: trip } } = await asyncGet(getTrip, { id: inTripId });
        setTrip(trip);
      })();
    }
  }, [inTripId]);

  useEffect(() => {
    if (!trip || !trip.id) return;

    (async () => {
      const results = await asyncListAll(listTripSegments, { tripId: trip.id });
      setTripSegments(results);

      setDistGps((trip.distGps || 0));
      setNewDistGps(trip.distGps || 0);

      setPolyline(trip.polyline);
      setNewPolyline(trip.polyline);
    })();
  }, [trip]);

  global.logger.debug(trip);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StyledPaper elevation={2}>
          <TextField
            margin="dense"
            id="distMro"
            label="GPS Distance"
            fullWidth
            disabled={true}
            value={distGps}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="Polyline"
            label="Polyline"
            fullWidth
            disabled={true}
            value={polyline}
            InputLabelProps={{ shrink: true }}
          />
          <TripVisualization
            tripId={trip.id}
            polyline={polyline}
            height={600}
            hideEditButton={true}
            hideSegments={true}
            hideActions={true}
          />
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper elevation={2}>
          <TextField
            margin="dense"
            id="newDistMro"
            label="Modified GPS Distance"
            fullWidth
            disabled={isLoading}
            value={newDistGps}
            onChange={(event) => setNewDistGps(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="newPolyline"
            label="Modified Polyline"
            fullWidth
            disabled={isLoading}
            value={newPolyline}
            onChange={(event) => {
              setNewPolyline(event.target.value);
              setNewDistGps(calculatePolylineDistance(event.target.value));
              setShowNewPolylineVisualization(false);
              setTimeout(() => {
                setShowNewPolylineVisualization(true);
              });
            }}
            InputLabelProps={{ shrink: true }}
          />
          {showNewPolylineVisualization &&
            <TripVisualization
              tripId={trip.id}
              polyline={newPolyline}
              height={600}
              hideEditButton={false}
              hideSegments={true}
              hideActions={true}
              onPolylineUpdate={(data) => {
                setNewPolyline(data);
                setNewDistGps(calculatePolylineDistance(data));
                setShowNewPolylineVisualization(false);
                setTimeout(() => {
                  setShowNewPolylineVisualization(true);
                });
              }}
            />}
        </StyledPaper>
      </Grid>
      {trip.polylines && trip.polylines.length > 0 &&
        <Grid item xs={12}>
          <StyledPaper elevation={2}>
            <Typography>
              Polyline versions
            </Typography>
            <List>
              {trip.polylines.map(({ polyline, description, createdAt }, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${description} (${moment(createdAt).format('YYYY/MM/DD h:mm a')})`} secondary={polyline} />
                </ListItem>
              ))}
            </List>
          </StyledPaper>
        </Grid>}
      <Grid item xs={12}>
        <StyledPaper elevation={2}>
          <Typography style={{ color: 'red' }}>
            Re-process will delete the existing trip segments. And re-process an old trip may affect the existing statements and reports.
          </Typography>
          <List>
            {tripSegments.map(({ id, type, stateCode }, index) => (
              <ListItem key={id}>
                <ListItemText primary={`Segment ${index + 1} - ${id}`} secondary={`type:${type} stateCode=${stateCode}`} />
              </ListItem>
            ))}
          </List>
          <Box textAlign="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasConfirmed}
                  onChange={(event) => {
                    setHasConfirmed(event.target.checked);
                  }}
                  name="confirm-checkbox"
                  color="primary"
                  disabled={isLoading}
                />
              }
              label="I acknowledge that I have read, understand, and agree to re-process this trip."
            />
          </Box>
          <Box textAlign="center">
            <Button
              variant="contained"
              onClick={() => handleConfirm(true)}
              color="primary"
              disabled={!hasConfirmed || isLoading || !polyline}
            >
              Confirm
            </Button>
          </Box>
        </StyledPaper>
      </Grid>
    </Grid>
  );
}

TripReview.propTypes = {
  tripId: PropTypes.string,
  trip: PropTypes.object,
  onUpdate: PropTypes.func,
};
