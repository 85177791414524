import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import TripsTable from 'pages/Admin/components/TripsTable';

import { asyncListAll } from 'utilities/graph';
import { getTripsByUsernameByCreatedAt } from './queries';

import { useStyles } from '../styles';

const ParticipantTrips = ({ username, viewer, pilotProgram = '', ...props }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const trips = await asyncListAll(getTripsByUsernameByCreatedAt, { username, sortDirection: 'DESC' }, { bypassCache: true });
        setTrips(trips);
      } catch (e) {
        global.logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username]);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <TripsTable
      title="Participant's Trips"
      data={trips}
      viewer={viewer}
      pilotProgram={pilotProgram}
      {...props}
    />
  );
};

ParticipantTrips.propTypes = {
  username: PropTypes.string,
  viewer: PropTypes.string,
  pilotProgram: PropTypes.string,
};

export default ParticipantTrips;
