import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import {
  simpleDate,
  formatMroType,
  convertMileage,
  formatRUCEmployer,
  formatRucGroupName,
} from 'utilities/format';

import {
  listParticipantsWithVehicles,
  getEventsByEventNameByTimestamp,
} from './graphql';
import {
  checkDuplicateRecordId,
  listAllForDateRange,
} from './helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

// setup dates
const pilotStartDate = localStorage.getItem('ruc:configuration:PILOT_START_DATE') || '2024-08-01T00:00:00.000Z';
const month = [
  moment(pilotStartDate).subtract(1, 'Month'),
  moment(pilotStartDate),
  moment(pilotStartDate).add(1, 'Month'),
  moment(pilotStartDate).add(2, 'Month'),
  moment(pilotStartDate).add(3, 'Month'),
  moment(pilotStartDate).add(4, 'Month'),
  moment(pilotStartDate).add(5, 'Month'),
  moment(pilotStartDate).add(6, 'Month'),
];

function isBetween(date, start, end) {
  const mDate = moment(date);
  return start.isBefore(mDate) && end.isAfter(mDate);
}

function mapFields(participant, vehicle, vehicleDeleted) {
  const id = vehicle.id;

  const odo = vehicle.reports == undefined ? [] : vehicle.reports.sort((a, b) => {
    return moment(a.tsReportDate).isBefore(b.tsReportDate) ? -1 : 1;
  });

  if (odo.length === 0 && participant.status !== 'closed') {
    console.error('vehicle is not linked to mro device.', participant.accountNo, { participant, vehicle });
  }

  return Object.assign({
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Email Address': participant.email,
    'Telephone Number': participant.phoneNumber,
    'Employer': formatRUCEmployer(participant),
    'RUC Group': formatRucGroupName(participant.pilotProgram?.shortName),
    'VIP': participant.flags == undefined ? false : participant.flags?.isVIP || false,
    'VIN': vehicle.vin,
    'Starting Odometer': odo.length > 0 ? convertMileage(odo[0].odometer, 'mi') : '',
    'Starting Odometer Date': odo.length > 0 ? simpleDate(odo[0].tsReportDate) : '',
    'Final Odometer': odo.length > 0 ? convertMileage(odo.slice(-1)[0].odometer, 'mi') : '',
    'Final Odometer Date': odo.length > 0 ? simpleDate(odo.slice(-1)[0].tsReportDate) : '',
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': vehicle?.mroType != undefined ? formatMroType(vehicle.mroType) : '',
  }, eachMonthValues(vehicle, vehicleDeleted, participant));
}

function eachMonthValues(vehicle, vehicleDeleted, participant) {
  const output = {};
  // Start with M0 for July
  for (let i = 0; i <= 6; i++) {
    const isVehicleDeletedInThisMonth = vehicleDeleted != undefined && isBetween(vehicleDeleted.createdAt, month[i], month[i + 1]);
    const isAccountClosedInThisMonth = participant.closedDate != undefined && isBetween(participant.closedDate, month[i], month[i + 1]);

    output[`M${i}_VIN Delete Date`] = isVehicleDeletedInThisMonth ? simpleDate(vehicleDeleted.timestamp) : '';
    output[`M${i}_VIN Delete Reason`] = isVehicleDeletedInThisMonth ? 'Removed by CSR' : '';

    output[`M${i}_Account Close Date`] = isAccountClosedInThisMonth ? simpleDate(participant.closedDate) : '';
    output[`M${i}_Account Close Reason`] = isAccountClosedInThisMonth ? (participant.closedReason || '') : '';

    output[`M${i}_MRO (PID) Return Date`] = (vehicle.mroType === 'automatedWithLocation' || vehicle.mroType === 'automatedWithoutLocation') &&
      isAccountClosedInThisMonth ? 'Refer to CSR' : '';
  }

  return output;
}

export default function PilotCloseoutReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const [participants, vehicleEventsRemoved] = await Promise.all([
        // get all participants
        asyncListAll(listParticipantsWithVehicles),
        // get all delete vehicle events
        listAllForDateRange({
          from: pilotStartDate,
          to: moment().toISOString(),
          gqlQuery: getEventsByEventNameByTimestamp,
          params: (from, to) => {
            return {
              eventName: 'REMOVE',
              timestamp: { between: [from, to] },
              filter: {
                key: { contains: 'Vehicle' },
              },
            };
          },
        }),
      ]);

      const data = participants.map((participant) => {
        return participant.vehicles.items.map((vehicle) => {
          const vehicleDeleted = vehicleEventsRemoved.find(({ diff, username }) => {
            return username === participant.username && diff.find(({ key, old }) => key === 'id' && old === `"${vehicle.id}"`);
          });
          return mapFields(participant, vehicle, vehicleDeleted);
        });
      });

      return data.flat();
    } catch (e) {
      global.logger.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Account-VIN Closeout Report
            </Typography>
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`ACCOUNT-VIN CLOSEOUT_Date ${moment().format('MM/DD/YYYY')}.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                checkDuplicateRecordId(data);
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
