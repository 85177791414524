import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Grid from '@material-ui/core/Grid';

import { APP } from 'utilities/constants';
import Logo from 'components/Logo';

function AccountStatusAlert({ participant }) {
  if (participant.status === 'onboarded') {
    return (
      <Grid container justify="center" data-test-id="account-review">
        <Grid item xs={12} sm={9} md={6}>
          <Alert severity="info">
            <AlertTitle>You have successfully completed your enrollment in the California Road Charge Collection Pilot!</AlertTitle>
            <p>We will review your enrollment information within 2 – 3 business days and then email you with your Pilot Account details and next steps.</p>
            <p>
              If you have questions about the Pilot, <a href={`${APP.FAQ_LINK}`} rel="noopener noreferrer" target="_blank">see our FAQ</a>
              or contact us at <a href={`mailto:${APP.SUPPORT_EMAIL_HORROCKS}`}>{APP.SUPPORT_EMAIL_HORROCKS}</a>.
            </p>
            <p>
              If you need help with your Pilot Account, please contact the Pilot Help Desk by clicking the Support link above or emailing us at
              <a href={`mailto:${APP.SUPPORT_EMAIL}`}>{APP.SUPPORT_EMAIL}</a>.
            </p>
            <p>Thank you for your participation!</p>
            <p>Safe travels.</p>
            <p>The Road Charge Pilot Team</p>
            <Logo width={250} height={'unset'} margin={'auto'} fullColor display='block' />
          </Alert>
        </Grid>
      </Grid>
    );
  }

  if (participant.status === 'suspended') {
    return (<Grid container justify="center" data-test-id="account-review">
      <Grid item xs={12}>
        <Alert severity="error">
          <AlertTitle>Your account has been suspended. Please reach out to us via the Support page </AlertTitle>
        </Alert>
      </Grid>
    </Grid>);
  }

  return null;
}

AccountStatusAlert.propTypes = {
  participant: PropTypes.object,
};

export default AccountStatusAlert;
