import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Table from 'components/Table';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';

import Transaction from 'pages/Admin/Transaction';

import { sortBy } from 'utilities/sorting';

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginTop: theme.spacing(20),
  },
}));

function TransactionsTable({
  data: inData,
  title = 'Transactions',
  description = '',
  viewer = 'admin',
  nested = false,
  mroDevicePreference,
}) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [{
    name: 'id',
    label: 'Transaction ID',
    options: {
      display: viewer === 'admin',
      filter: false,
    },
  }, {
    name: 'referenceNumber',
    label: 'Reference Number',
    options: {
      display: true,
      filter: false,
    },
  }, {
    name: 'username',
    label: 'Username',
    options: {
      display: false,
      filter: false,
    },
  }, {
    name: 'type',
    label: 'Transaction Type',
    options: {
      sort: true,
    },
  }, {
    name: 'status',
    label: 'Status',
    options: {
      display: viewer === 'admin',
      sort: true,
    },
  }, {
    name: 'statusMessage',
    label: 'Status Message',
    options: {
      display: viewer === 'admin',
      filter: false,
      sort: false,
    },
  }, {
    name: 'description',
    label: 'Description',
    options: {
      filter: false,
    },
  }, {
    name: 'subtotalCents',
    label: 'Subtotal',
    type: 'currency',
    options: {
      display: viewer === 'admin',
      sort: true,
      filter: false,
    },
  }, {
    name: 'discountCents',
    label: 'Discounts',
    type: 'currency',
    options: {
      display: viewer === 'admin',
      sort: true,
      filter: false,
    },
  }, {
    name: 'amountCents',
    label: 'Total',
    type: 'currency',
    options: {
      sort: true,
      filter: false,
    },
  }, {
    name: 'createdAt',
    label: 'Created At',
    type: 'datetime',
    options: {
      sort: true,
      filter: false,
      display: true,
    },
  }, {
    name: 'updatedAt',
    label: 'Updated At',
    type: 'datetime',
    options: {
      display: viewer === 'admin',
      sort: true,
      filter: false,
    },
  }, {
    name: 'paidAt',
    label: 'Paid At',
    type: 'datetime',
    options: {
      sort: true,
      filter: false,
      display: true,
    },
  }, {
    name: 'paymentMethodId',
    label: 'Payment Method ID',
    options: {
      display: viewer === 'admin',
      sort: false,
      filter: false,
    },
  }, {
    name: 'paymentType',
    label: 'Payment Type',
    options: {
      sort: true,
      display: true,
    },
  }, {
    name: 'paymentInfo',
    label: 'Payment Info',
    options: {
      sort: true,
      display: true,
      filter: false,
    },
  }].filter((x) => {
    return viewer === 'participant' && x.options ? x.options.display !== false : true;
  });

  if (viewer === 'admin') {
    columns.unshift({
      name: 'participant.fullName',
      label: 'Participant Name',
    });

    columns.unshift({
      name: 'participant.accountNo',
      label: 'Account ID',
    });

    columns.push({
      name: 'standing',
      label: 'Standing',
      options: {
        display: true,
      },
    });

    columns.push({
      name: 'participant.username',
      label: 'Participant',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}?tab=Payments`}
              label="View participant details"
            />
          );
        },
      },
    });
  }

  const options = {
    download: viewer === 'admin',
    filter: true,
    expandableRows: true,
    isRowExpandable: () => nested ? false : true,
    renderExpandableRow(_rowData, rowMeta) {
      const { id, createdAt, username, statementId } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <Transaction
            id={id}
            createdAt={createdAt}
            username={username}
            mroDevicePreference={mroDevicePreference}
            viewer={viewer}
            statementId={statementId}
          />
        </NestedTableContainer>
      );
    },
    setRowProps: (row, dataIndex) => {
      if (viewer === 'participant') return;

      const { status: transactionStatus } = data[dataIndex];

      if (transactionStatus) {
        switch (transactionStatus.toLowerCase()) {
          case 'due':
            return { style: { background: '#fce803' } };
          case 'overdue':
          case 'failed':
            return { style: { background: '#fc9403' } };
          case 'default':
            return { style: { background: '#e87661' } };
          case 'pending':
            return { style: { background: '#FFFF9A' } };
          case 'processed':
            return { style: { background: '#d1e8ff' } };
          case 'cancelled':
            return { style: { background: '#e6e6e6' } };
        }
      }
    },
    rowsPerPage: 100,
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const transactions = inData;
        setData(transactions.sort(sortBy('createdAt', true)));
        setIsLoading(false);
      } catch (e) {
        global.logger.warn(e);
      }
    })();
  }, [inData]);

  if (isLoading) {
    return (
      <Grid container className={classes.spinner} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
    />
  );
}

TransactionsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  viewer: PropTypes.string,
  nested: PropTypes.bool,
  mroDevicePreference: PropTypes.string,
};

export default TransactionsTable;
