import React, { useState } from 'react';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import {
  getInquiriesByStatusByCreatedAt,
} from './graphql';

import { APP } from 'utilities/constants';
import { getInquiryMode } from './CustomerInteractionReport';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function isBetween(date, btime, etime) {
  const wcTime = moment(moment(date).tz('America/Los_Angeles').format('HH:mm'), 'HH:mm').tz('America/Los_Angeles');
  const bDate = moment(btime, 'HH:mm').tz('America/Los_Angeles');
  const eDate = moment(etime, 'HH:mm').tz('America/Los_Angeles');
  return wcTime.isBetween(bDate, eDate);
}

function mapFields(inquires) {
  const totalEmails = inquires.filter(({ mode }) => mode === 'email');
  const totalEmailsIBH = totalEmails.filter(({ createdAt }) => isBetween(createdAt, '08:00', '16:00'));
  const totalEmailsVIP = totalEmails.filter((inquiry) => inquiry?.participant?.flags?.isVIP);
  const totalUap = inquires.filter(({ mode }) => mode === 'UAP');
  const totalUapIBH = totalUap.filter(({ createdAt }) => isBetween(createdAt, '08:00', '16:00'));
  const totalUapVIP = totalUap.filter((inquiry) => inquiry?.participant?.flags?.isVIP);

  return {
    'Total Number of Inbound eMails': totalEmails.length,
    'Number of Inbound emails (During Business Hrs)': totalEmailsIBH.length,
    'Number of Inbound emails (After Business Hrs)': totalEmails.length - totalEmailsIBH.length,
    'Number of Inbound eMails - VIPs': totalEmailsVIP.length,
    'Total Number of Inbound Calls': 0,
    'Number of Inbound Calls (During Business Hrs)': 0,
    'Number of Inbound Calls (After Business Hrs)': 0,
    'Number of Inbound Calls - VIPs': 0,
    'Total Number of Inbound Contact via UAP': totalUap.length,
    'Number of Inbound Contact via UAP (During Business Hrs)': totalUapIBH.length,
    'Number of Inbound Contact via UAP (After Business Hrs)': totalUap.length - totalUapIBH.length,
    'Number of Inbound Contact via UAP - VIPs': totalUapVIP.length,
    'Total Number of Outbound Calls': 0,
    'Number of Outbound Calls (During Business Hrs)': 0,
    'Number of Outbound Calls - VIPs': 0,
  };
}

export default function CustomerInteractionMetricsReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);

      const fromDate = moment(from).tz(APP.TIME_ZONE).startOf('day').toISOString();
      const toDate = moment(to).tz(APP.TIME_ZONE).endOf('day').toISOString();

      const statuses = [
        'new',
        'inProgress',
        'awaitingCustomerResponse',
        'responseReceived',
        'awaitingInternal',
        'closed',
      ];
      // get inquiries to csr
      let inquires = [];

      await Promise.all(statuses.map(async (status) => {
        const data = await asyncListAll(getInquiriesByStatusByCreatedAt, {
          status,
          createdAt: {
            between: [fromDate, toDate],
          },
        });

        inquires = [...inquires, ...data];
      }));

      inquires = inquires.map((inquiry) => {
        inquiry.mode = getInquiryMode(inquiry);
        return inquiry;
      });

      return [mapFields(inquires)];
    } catch (e) {
      global.logger.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Customer Interaction Metrics Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`CUSTOMER INTERACTION (Help Desk) METRICS Date ${moment().format('MM/DD/YYYY')}.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
