export const listParticipantConnections = /* GraphQL */ `
  query ListParticipantConnections(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelParticipantConnectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipantConnections(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
      nextToken
    }
  }
`;

export const getTransactionsByUsernameByCreatedAt = /* GraphQL */ `
  query GetTransactionsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        description
        type
        status
        retries
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
        paidAt
        statementId
        tripSegments {
          items {
            id
            mileage
            fuel
            mileageFeeCents
            fuelFeeCents
            cordonFeeCents
            todFeeCents
            tollFeeCents
            paymentStatus
          }
        }
      }
      nextToken
    }
  }
`;

export const getParticipantStatementsByParticipantByMonth = /* GraphQL */ `
  query GetParticipantStatementsByParticipantByMonth(
    $username: String
    $month: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getParticipantStatementsByParticipantByMonth(
      username: $username
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        month
        periodFrom
        periodTo
        filename
        mileageUnit
        fuelUnit
        rifFee
        mileage
        fuel
        trips
        fuelTaxCredit
        totalNewMileageFee
        totalNewAdjCharges
        totalNewChages
        carryoverBalance
        balance
        paidAmount
        paidAt
        note
        isProcessed
        shouldSendEmailToParticipant
        transactions {
          items {
            id
            username
            createdAt
            createdBy
            updatedAt
            description
            type
            status
            retries
            subtotalCents
            discountCents
            amountCents
            paymentMethodId
            paidAt
            statementId
            tripSegments {
              items {
                id
                mileage
                fuel
                mileageFeeCents
                fuelFeeCents
                cordonFeeCents
                todFeeCents
                tollFeeCents
                paymentStatus
              }
            }
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
