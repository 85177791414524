import moment from 'moment-timezone';

import { APP } from 'utilities/constants';

const BILLING_BALANCE_DUE_DATE_OF_MONTH = localStorage.getItem(`ruc:configuration:BILLING_BALANCE_DUE_DATE_OF_MONTH`);

const today = moment();

export const daysAgo = (inDate) => {
  const target = moment(inDate);
  const today = moment();

  return `${Math.round((today - target) / 86400000)} days ago`;
};

export const formatMileageReportData = (data) => {
  if (!data) return '';

  const { auditStatus, tsReportDate } = data;

  const date = moment(tsReportDate).tz(APP.TIME_ZONE).format('YYYY-MM-DD');

  return `${date} ${auditStatus} (${daysAgo(tsReportDate)})`;
};

export const formatTripData = (data) => {
  if (!data) return '';

  const { processStatus, createdAt } = data;

  const date = moment(createdAt).tz(APP.TIME_ZONE).format('YYYY-MM-DD');

  return `${date} ${processStatus} (${daysAgo(createdAt)})`;
};

export const formatVehicles = (data) => {
  if (!data || data.length === 0) return '';

  return data
    .map(({ type, beginningOdometerReading, currentOdometerReading }) => {
      return `${type} (${beginningOdometerReading || 0} ~ ${currentOdometerReading || 0})`;
    })
    .join(', ');
};

export const formatNotifications = (data) => {
  if (!data || data.length === 0) return '';

  return data
    .map(({ templateName, status, shouldBeSentAt }) => {
      const date = moment(shouldBeSentAt).tz(APP.TIME_ZONE).format('YYYY-MM-DD');

      return `${date} ${templateName} ${status} (${daysAgo(shouldBeSentAt)})`;
    })
    .join(', ');
};


export const getTxDueInDays = (inTxCreatedAt) => {
  let dueDate = moment(inTxCreatedAt).startOf('month').date(BILLING_BALANCE_DUE_DATE_OF_MONTH);

  // statement is generated after the desired due date
  if (dueDate.toISOString() < inTxCreatedAt) {
    dueDate = dueDate.add(1, 'months');
  }

  return {
    dueInDays: dueDate.diff(today, 'days'),
    dueDate: dueDate.format('YYYY-MM-DD'),
  };
};
