/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub, Storage } from 'aws-amplify';
import Analytics from '@aws-amplify/analytics';

import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/Home';
import { APP } from './utilities/constants';
import { authRoutes } from './routes';
import { getConfiguration } from 'graphql/queries';
import { asyncGet } from 'utilities/graph';
import InactivityMonitor from 'components/InactivityMonitor/InactivityMonitor';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
Analytics.disable();
Storage.configure({ level: 'public' });

const CONFIG_KEYS = [
  'REGISTRATION_CLOSED',
  'PARTICIPANT_SESSION_TIMEOUT',
  'MILEAGE_UNIT',
  'FUEL_UNIT',
  'FEATURE_MULTIPLE_VEHICLES',
  'TRIP_DEFAULT_DISPLAY',
  'FEATURE_REGISTER_WITH_VIN',
  'TUPAY_IFRAME_PRODUCT_KEY',
  'TUPAY_IFRAME_URL',
  'PAYMENTS_PERSIST',
  'PAYMENTS_REGISTRATION',
  'PAYMENTS_PROVIDER',
  'PAYMENTS_ALLOWED',
  'BILLING_BALANCE_DUE_DATE_OF_MONTH',
  'BILLING_BALANCE_DUE_DAYS',
  'BILLING_BALANCE_OVERDUE_DAYS',
  'BILLING_BALANCE_DEFAULT_DAYS',
  'PILOT_START_DATE',
];

const App = () => {
  const [enableInactivityMonitor, setEnableInactivityMonitor] = useState(false);

  const checkAuth = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const isAdmin = (user.signInUserSession.accessToken.payload['cognito:groups'] || ['N/A']).includes('Admins');

      setEnableInactivityMonitor(!isAdmin);
    } catch (e) {
      setEnableInactivityMonitor(false);
    }
  };

  const resetConfigs = () => {
    return Promise.all(CONFIG_KEYS.map(async (configKey) => {
      const {
        data: {
          getConfiguration: {
            key,
            value,
          },
        },
      } = await asyncGet(getConfiguration, { key: configKey }, { authMode: 'API_KEY' });

      localStorage.setItem(`ruc:configuration:${key}`, value);
    }));
  };

  useEffect(() => {
    const listener = ({ payload }) => {
      // prevent infinite recursion
      if (payload.event === 'tokenRefresh') {
        return;
      }

      if (payload.event === 'signOut') {
        localStorage.clear();
        resetConfigs();
      }

      checkAuth();
    };

    const hubListenerCancelToken = Hub.listen('auth', listener);

    checkAuth();
    resetConfigs();

    return () => {
      hubListenerCancelToken();
    };
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Route>
          {enableInactivityMonitor && <InactivityMonitor />}
        </Route>
        <Switch>
          {authRoutes.map((item) => (
            <item.route
              key={item.path}
              exact={item.exact}
              path={item.path}
              roles={item.roles}
              render={(props) => (
                <DocumentTitle title={`${APP.NAME_PILOT} | ${item.title}`}>
                  <item.component {...props} />
                </DocumentTitle>)
              } />
          ))}
          <ProtectedRoute path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
