import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import moment from 'moment-timezone';

import { theme } from 'index.js';
import { ThemeProvider } from '@material-ui/core/styles';
import { useStyles } from 'pages/Auth/components/commonStyles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import ControlledInput from 'components/Form/ControlledInput';

import { asyncRetryMutation, asyncListAll } from 'utilities/graph';
import { listVehicles } from 'graphql/queries';
import { updateParticipant, updateVehicle } from 'graphql/mutations';

const LIST_PARTICIPANTS_CACHE_KEY = 'ParticipantsTable-ListParticiapnts';

function CloseAccountDialog({ isOpen, accountToClose, onClose }) {
  const classes = useStyles();
  const { control, errors, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const inputs = [{
    type: 'text',
    name: 'closedReason',
    label: 'Reason',
    multiline: true,
    rows: 4,
    autoFocus: true,
    required: true,
    invalidText: 'Please provide a reason for closing the account',
  }];

  const handleCloseAccount = async ({ closedReason = null }) => {
    if (!closedReason) {
      return onClose();
    }

    const { dataIndex, account } = accountToClose;
    const { username, status } = account;

    const vehicles = await asyncListAll(
      listVehicles,
      { username },
      { bypassCache: true },
    );

    // inactivate each vehicle
    await Promise.all(vehicles.map((vehicle) => {
      return asyncRetryMutation(
        updateVehicle,
        {
          input: Object.assign({}, vehicle, {
            status: 'inactive',
            inactiveDate: new Date().toISOString(),
          }),
        },
      );
    }));

    // close account
    const input = {
      closedDate: moment().toISOString(),
      closedReason,
      closedBy: localStorage.getItem('ruc:username'),
      username,
      status,
    };

    await asyncRetryMutation(
      updateParticipant,
      { input },
      { clearCacheKeys: [LIST_PARTICIPANTS_CACHE_KEY] },
    );

    onClose({
      dataIndex,
      account,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={() => onClose()}
        disableBackdropClick={true}
      >
        <DialogContent>
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleCloseAccount)}
            noValidate
          >
            <Grid container spacing={2}>
              {inputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >Close Account</Button>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              onClick={() => handleCloseAccount({})}
            >Cancel</Button>
          </form>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

CloseAccountDialog.propTypes = {
  isOpen: PropTypes.bool,
  accountToClose: PropTypes.object,
  onClose: PropTypes.func,
};

export default CloseAccountDialog;
