import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';

const VehicleReportTable = ({
  data = [],
  title = 'Vehicle Reports',
}) => {
  const columns = [
    {
      name: 'tsReportDate',
      label: 'TS Report Date',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'odometer',
      label: 'Odometer',
      type: 'mileage',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <div data-test-id="vehicle-reports-table">
      <Table
        title={title}
        description={''}
        data={data}
        columns={columns}
        options={{}}
      />
    </div>
  );
};

VehicleReportTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array.isRequired,
  viewer: PropTypes.string,
  onHandleCreate: PropTypes.func,
  onHandleRemove: PropTypes.func,
  onHandleSetupTelematics: PropTypes.func,
};

export default VehicleReportTable;
