import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function useScreenView() {
  const theme = useTheme();

  let size;
  let isMobileView = false;

  const isXs = useMediaQuery(theme.breakpoints.up('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  if (isXl) {
    size = 'xl';
  } else
  if (isLg) {
    size = 'lg';
  } else
  if (isMd) {
    size = 'md';
  } else
  if (isSm) {
    size = 'sm';
    isMobileView = true;
  } else
  if (isXs) {
    size = 'xs';
    isMobileView = true;
  }

  return {
    size,
    isMobileView,
  };
}
