/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Table from 'components/Table';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import Inquiry from 'pages/Admin/Inquiry';

import { sortBy } from 'utilities/sorting';
import { isInquiryClosed } from 'utilities/constants/inquiryStatuses';
import { APP } from 'utilities/constants';

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginTop: theme.spacing(20),
  },
}));

function InquiriesTable({
  data: inData,
  title = 'Inquiries',
  description = '',
  viewer = 'admin',
  nested = false,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const options = {
    download: viewer === 'admin',
    filter: true,
    expandableRows: true, // viewer === 'admin' ? true : false,
    expandableRowsHeader: true, // viewer === 'admin' ? true : false,
    isRowExpandable: () => nested ? false : true,
    renderExpandableRow(rowData, rowMeta) {
      const { id } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <Inquiry id={id} viewer={viewer} />
        </NestedTableContainer>
      );
    },
    onRowClick: (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      global.logger.debug('Inquiries clicked:', item);
      if (viewer === 'admin') {
        history.push(`/inquiry/${item.id}`);
      }
    },
  };

  const columns = [{
    name: 'id',
    label: 'Inquiry ID',
    options: {
      display: false,
      filter: false,
    },
  }, {
    name: 'category',
    label: 'Category',
  }, {
    name: 'subject',
    label: 'Subject',
    options: {
      filter: false,
    },
  }, {
    name: 'username',
    label: 'Username',
    options: {
      display: false,
      filter: false,
    },
  }, {
    type: 'datetime',
    name: 'createdAt',
    label: 'Created',
    options: {
      filter: false,
    },
  }, {
    type: 'datetime',
    name: 'updatedAt',
    label: 'Last Updated',
    options: {
      filter: false,
    },
  }, {
    name: 'updatedBy',
    label: 'Last Updated By',
    options: {
      filter: false,
      customBodyRender: (value) => {
        switch (value) {
          case null:
          default:
            return 'system';
        }
      },
    },
  }, {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRender: (value) => {
        return (viewer === 'admin') ? value : isInquiryClosed(value) ? 'closed' : 'open';
      },
    },
  }].filter((x) => {
    return viewer === 'participant' && x.options ? x.options.display !== false : true;
  });

  if (viewer === 'admin') {
    columns.unshift({
      name: 'participant.fullName',
      label: 'Participant Name',
    }, {
      name: 'status',
      label: 'Status',
    }, {
      name: 'createdBy',
      label: 'Author',
    });
  }

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setData(inData.sort(sortBy('createdAt', true)));
        setIsLoading(false);
      } catch (e) {
        global.logger.warn(e);
      }
    })();
  }, [inData]);

  if (isLoading) {
    return (
      <Grid container className={classes.spinner} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Table
          title={title}
          description={description}
          data={data}
          columns={columns}
          options={options}
          useCacheColumns={viewer === 'admin'}
        />
      </Grid>
      <Grid item xs={12}>
        <Alert color="info">
          <ul>
            <li>For questions about enrollment, Road Charge billing and payments, mileage reporting options, the participant dashboard,
              or any other technical assistance, please click on the Support link above or email
              &nbsp;<a href={`mailto:${APP.SUPPORT_EMAIL}`}>{APP.SUPPORT_EMAIL}</a>.</li>
            <li>For questions about Pilot surveys, the gift card incentives up to $400,
              or other general Pilot questions, please email <a href={`mailto:${APP.SUPPORT_EMAIL_HORROCKS}`}>{APP.SUPPORT_EMAIL_HORROCKS}</a>.</li>
            <li>To speak directly with a Pilot representative, call <a href={`tel:${APP.PHONE_DIGITS}`}>{APP.PHONE_FORMAT}</a>.</li>
          </ul>
        </Alert>
      </Grid>
    </Grid>
  );
}

InquiriesTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  viewer: PropTypes.string,
  nested: PropTypes.bool,
};

export default InquiriesTable;
