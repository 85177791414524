/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Logo from 'components/Logo';

export default function RoleExplanation({ pilot }) {
  return (
    <Box marginTop={2}>
      <Box xs={12}>
        <Logo width={250} fullColor display='block' margin='auto' />
      </Box>
      <Typography component="h6" variant="h6" gutterBottom color="primary">
        Complete Your Enrollment in the California Road Charge Collection Pilot
      </Typography>
      <Typography component="p" variant="body1" gutterBottom>
        Thank you for enrolling in the Pilot! Please enter your email and password below to get started. You will need to enter your vehicle information number (VIN), to choose a mileage reporting option, and input your payment card information to create your Pilot Account with Transurban.
      </Typography>
      {
        pilot === 'MBUF' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does the Pilot work?</b>
          </Typography>
          <ul>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You will drive as you normally do, report your mileage, and pay your Road Charge each month for 6 months through your Pilot Account. The Pilot occurs from August 1, 2024  to January 31, 2025.  Your first Road Charge payment will be due in the beginning of September for the miles driven in August.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                The state does not want participants to pay twice for transportation funding, so as road charges are paid there will be gas tax credits accumulated and refunded at the end of the Pilot. Electric vehicles will receive a partial credit of the Road Improvement Fee (annual registration fee) that applies to electric vehicles model year 2020 and newer.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You can also earn up to $400 in gift cards for completing Pilot activities such as taking two surveys, paying road charges promptly, and closing out your Pilot Account.
              </Typography>
            </li>
          </ul>
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            <b>Enter your email and password below to enroll now</b>
          </Typography>
        </>
      }
      {
        pilot === 'MBUF+TOD' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally do. The Study will run from June to the end of October 2021. You will be asked for voluntary feedback along the way.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <ul>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                Your mileage will be collected using an OBDII device that plugs into your car. The device will be mailed to you once the enrollment process is complete.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You can choose between devices with or without GPS. A GPS device enables you to see exactly where you drove on a map.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You will receive mock statements* via email and on your MBUF Study online account that will include a summary of your mileage-based user fee (MBUF) compared to the current state and federal gas tax.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                An additional mock charge* of 5 cents will be applied to each mile driven during the following
                <Typography component="span" variant="body1" color="primary"><b> weekday rush hours</b></Typography>:
                Monday – Friday: 6 – 10 AM and 3 – 7 PM
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                Any additional rush hour charges will be displayed on your mock statement* alongside the standard MBUF charge.
              </Typography>
            </li>
          </ul>
          <Typography component="p" variant="body1" gutterBottom>
            <i>
              *No real money payment will be exchanged as part of this MBUF Study.
            </i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            To enroll start by entering your email and new password below.
          </Typography>
        </>
      }
      {
        pilot === 'MBUF+C' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally do. The Study will run from June to the end of October 2021. You will be asked for voluntary feedback along the way.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <ul>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                Your mileage will be collected using an OBDII device that plugs into your car. The device will be mailed to you once the enrollment process is complete.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You will receive mock statements* via email and on your MBUF Study online account that will include a summary of your mileage-based user fee (MBUF) compared to the current state and federal gas tax.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                An additional mock charge* of $5.00 dollars per day will be applied to your account if you enter the Tysons Corner zone during weekday business hours, along with an additional 5 cents per mile for travel within the zone during
                <Typography component="span" variant="body1" color="primary">
                  <b> weekday business hours.  Weekday Tysons Corner zone business hours are: </b>
                </Typography>
                Monday – Friday: 6:00 AM to 7:00 PM
              </Typography>
            </li>
          </ul>
          <Typography component="p" variant="body1" gutterBottom>
            <i>*No real money payment will be exchanged as part of this MBUF Study.</i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>For your reference, here is a map of the Tysons Corner zone:</b>
          </Typography>
          <img
            src={'/tyson-cordon.png'}
            width="100%"
            alt="tyson-cordon"
          />
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            To enroll start by entering your email and new password below.
          </Typography>
        </>
      }
      {
        pilot === 'MBUF+TF' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally do. The Study will run from June to the end of October 2021. You will be asked for voluntary feedback along the way.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <ul>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                Your mileage will be collected using an OBDII device that plugs into your car. The device will be mailed to you once the enrollment process is complete.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You will receive mock statements* via email that will include your standard mileage-based user fee (MBUF) along with any tolls you’ve been charged during the study.
              </Typography>
            </li>
          </ul>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>Toll roads part of our program:</b>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            The following toll roads in the Greater Washington Area are participating in our MBUF program.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            <ul>
              <li>I-95, I-395 and I-495 Express Lanes</li>
              <li>I-66 Inside the Beltway</li>
              <li>Dulles Toll Road</li>
            </ul>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            <i>*Please note, while the MBUF Study statements and the associated MBUF charges are for testing purposes only and no real payment will be exchanged, you will still need to pay tolls as you normally would through the Transurban GoToll app.</i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            To enroll start by entering your email and new password below.
          </Typography>
        </>
      }
      {
        pilot === 'OB-MBUF' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally would and self-report your mileage once per month on your MBUF Study online account. Reminders will be sent via email each month. The study will run from June to the end of October 2021. You will be asked for voluntary feedback along the way.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            You will receive mock statements* via email and on your MBUF Study online account that will include a summary of your mileage-based user fee (MBUF) compared to the current state and federal gas tax based on the total miles you’ve driven.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            <i>*No real money payment will be exchanged as part of this MBUF Study.</i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            To enroll start by entering your email and new password below.
          </Typography>
        </>
      }
    </Box>
  );
}

RoleExplanation.propTypes = {
  pilot: PropTypes.string,
};
