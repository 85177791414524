import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Tooltip from '@material-ui/core/Tooltip';
import UpdateIcon from '@material-ui/icons/Update';

import Table from 'components/Table';
import {
  mroDevicePreferences,
  states,
  vehicleFuelPreferences,
  vehicleMakesAndModels,
  vehicleStatus,
} from 'utilities/constants';
import { asyncRetryMutation } from 'utilities/graph';
import { updateVehicle } from 'graphql/mutations';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import VehicleReportTable from './VehicleReportTable';

const VehicleTable = ({
  data: inData,
  title = 'Vehicles',
  description = '',
  viewer = 'admin',
  hideColumns = [],
  onHandleCreate,
  onHandleRemove,
}) => {
  const [data, setData] = useState();
  const multipleVehiclesEnabled = localStorage.getItem(`ruc:configuration:FEATURE_MULTIPLE_VEHICLES`) === 'enabled';

  const columns = [
    {
      name: 'status',
      label: 'Status',
      edit: {
        type: 'select',
        menu: vehicleStatus.map((item) => {
          return { label: item.toUpperCase(), value: item };
        }),
      },
      options: {
        display: viewer === 'admin',
        filter: true,
        sort: true,
        customBodyRender(value) {
          return value ? value.toUpperCase() : '';
        },
        customFilterListOptions: {
          render: (status) => `Status:${status}`,
        },
      },
    },
    {
      name: 'id',
      label: 'Vehicle ID',
      options: {
        display: viewer === 'admin',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'vin',
      label: 'VIN',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (value) ? value : (
            <Tooltip
              title="Waiting for vehicle to report data."
              placement="right"
            >
              <UpdateIcon />
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'make',
      label: 'Make',
      edit: viewer === 'admin' ? {
        type: 'select',
        menu: (data) => {
          return vehicleMakesAndModels.map(({ brand }) => brand.toUpperCase());
        },
        rerenderAfterSelect: true, // to refresh model menu
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'model',
      label: 'Model',
      edit: viewer === 'admin' ? {
        type: 'select',
        menu: (data) => {
          const { models } = vehicleMakesAndModels.find(({ brand }) => {
            return brand.toLowerCase() === data.make.toLowerCase();
          });
          return models;
        },
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'year',
      label: 'Year',
      edit: viewer === 'admin' ? {
        type: 'number',
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'licensePlate',
      label: 'License Plate',
      edit: viewer === 'admin' ? {
        type: 'text',
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'registrationState',
      label: 'State',
      edit: viewer === 'admin' ? {
        type: 'select',
        menu: Object.keys(states),
      } : undefined,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'beginningOdometerReading',
      label: 'Beginning Odometer Reading',
      type: 'mileage',
      edit: viewer === 'admin' ? {
        type: 'number',
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'currentOdometerReading',
      label: 'Current Odometer Reading',
      type: 'mileage',
      edit: viewer === 'admin' ? {
        type: 'number',
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mileage',
      label: 'Mileage',
      type: 'mileage',
      edit: viewer === 'admin' ? {
        type: 'number',
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'epaVehicleCombinedMpg',
      label: 'Mpg',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Fuel Type',
      edit: viewer === 'admin' ? {
        type: 'select',
        menu: vehicleFuelPreferences,
      } : undefined,
      options: {
        filter: false,
        display: true,
        customBodyRender(item) {
          const preference = vehicleFuelPreferences.find(({ value }) => value === item) || {};
          const { label = 'N/A' } = preference;
          return label;
        },
      },
    },
    {
      name: 'mroType',
      label: 'Reporting Type',
      edit: viewer === 'admin' ? {
        type: 'select',
        menu: mroDevicePreferences,
      } : undefined,
      options: {
        filter: false,
        display: true,
        customBodyRender(item) {
          const preference = mroDevicePreferences.find(({ value }) => value === item) || {};
          const { label = 'N/A' } = preference;
          return label;
        },
      },
    },
    {
      name: 'mroId',
      label: 'MRO ID',
      type: 'text',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mro.deviceSerialNumber',
      label: 'MRO S/N',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
  ].filter((x) => {
    if (hideColumns && hideColumns.includes(x.name)) {
      return false;
    }

    if (viewer === 'participant' && x.options) {
      return x.options.display !== false;
    }

    return true;
  });

  const options = viewer === 'participant' ? {
    download: false,
    search: true,
    print: true,
    viewColumns: true,
    filter: true,
  } : {
    expandableRows: true,
    expandableRowsHeader: true,
    isRowExpandable: () => true,
    isRowSelectable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const { reports } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <VehicleReportTable data={reports || []} />
        </NestedTableContainer>
      );
    },
  };

  const onUpate = async (item, dataIndex) => {
    const input = {
      id: item.id,
      username: item.username,
      updatedBy: localStorage.getItem('ruc:username'),
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        if (name === 'status') {
          input['inactiveDate'] = (item[name] === 'inactive') ? moment().toISOString() : null;
        }
        input[name] = item[name];
      }
    });

    await asyncRetryMutation(updateVehicle, { input });
    Object.assign(data[dataIndex], input);
    setData([...data]);
  };

  useEffect(() => {
    setData(inData);
  }, [inData]);

  return (
    <div data-test-id="vehicles-table">
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        onHandleCreateItem={(multipleVehiclesEnabled && viewer === 'admin') ? onHandleCreate : undefined}
        onHandleRemoveItem={viewer === 'admin' ? onHandleRemove : undefined}
        onUpdateItem={onUpate}
        useCacheColumns={viewer === 'admin'}
      />
    </div>
  );
};

VehicleTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array.isRequired,
  viewer: PropTypes.string,
  hideColumns: PropTypes.array,
  onHandleCreate: PropTypes.func,
  onHandleRemove: PropTypes.func,
  onHandleSetupTelematics: PropTypes.func,
};

export default VehicleTable;
