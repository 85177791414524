import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import VehicleTable from 'pages/Admin/components/VehicleTable';
import CreateVehicleDialog from './CreateVehicleDialog';
import RemoveVehicleDialog from './RemoveVehicleDialog';

import {
  asyncGet,
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';
import {
  getParticipantMroDevicePreference,
  getParticipantVehicles,
} from './queries';
import {
  updateVehicle,
} from 'graphql/mutations';

import { useStyles } from '../styles';


const ParticipantVehicles = ({ username, viewer }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [createVehicleDialogIsOpen, setCreateVehicleDialogIsOpen] = useState(false);
  const [removeVehicleDialogIsOpen, setRemoveVehicleDialogIsOpen] = useState(false);
  const [actionVehicle, setActionVehicle] = useState(null);
  const [hideColumns, setHideColumns] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const vehicles = await asyncListAll(
          getParticipantVehicles,
          { username }, { bypassCache: !forceRefresh },
        );
        setVehicles(vehicles);

        const toHideColumns = [];

        if (viewer === 'participant' && vehicles.some(({ mroType }) => mroType === 'automatedWithLocation')) {
          toHideColumns.push('beginningOdometerReading');
          toHideColumns.push('currentOdometerReading');
          toHideColumns.push('mileage');
        }

        console.log('toHideColumns', toHideColumns);

        setHideColumns(toHideColumns);
      } catch (e) {
        global.logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username, viewer, forceRefresh]);

  const handleOpenCreateVehicleDialog = () => {
    setCreateVehicleDialogIsOpen(true);
  };

  const handleCloseCreateVehicleDialog = (forceUpdate = false, vehicle = null) => {
    setCreateVehicleDialogIsOpen(false);

    // set new vehicle device type based on participant preferences
    if (vehicle) {
      (async () => {
        const {
          data: {
            getParticipant: {
              mroDevicePreference,
            },
          },
        } = await asyncGet(getParticipantMroDevicePreference, { username: vehicle.username });

        await asyncRetryMutation(updateVehicle, {
          input: Object.assign({}, vehicle, {
            mroType: mroDevicePreference,
          }),
        });

        setForceRefresh(!forceRefresh);
      })();
    }
  };

  const handleSetupTelematics = () => {

  };

  const handleOpenRemoveVehicleDialog = (vehicle) => {
    setRemoveVehicleDialogIsOpen(true);
    setActionVehicle(vehicle);
  };

  const handleCloseRemoveVehicleDialog = (forceUpdate) => {
    setRemoveVehicleDialogIsOpen(false);
    setActionVehicle(null);
    if (forceUpdate) {
      setForceRefresh(!forceRefresh);
    }
  };

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <>
      <VehicleTable
        title={(viewer == 'admin') ? `Participant's Vehicles` : 'My Vehicles'}
        data={vehicles}
        hideColumns={hideColumns}
        viewer={viewer}
        onHandleCreate={handleOpenCreateVehicleDialog}
        onHandleRemove={handleOpenRemoveVehicleDialog}
        onHandleSetupTelematics={handleSetupTelematics}
      />
      <>
        <CreateVehicleDialog
          isOpen={createVehicleDialogIsOpen}
          onClose={handleCloseCreateVehicleDialog}
          username={username}
        />
        <RemoveVehicleDialog
          isOpen={removeVehicleDialogIsOpen}
          onClose={handleCloseRemoveVehicleDialog}
          vehicle={actionVehicle}
        />
      </>
    </>
  );
};

ParticipantVehicles.propTypes = {
  username: PropTypes.string,
  viewer: PropTypes.string,
};

export default ParticipantVehicles;
