import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { listPaymentCards } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';
import BillingInfoStoredPaymentList from './BillingInfoStoredPaymentList';
import Tupay from './Tupay';

import { useStyles } from './styles';

const BillingInfo = ({
  user,
  showTitle = true,
  showSaved = true,
  onCompleted: inOnComplete,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [canAddNewCard, setCanAddNewCard] = useState(false);
  const [error, setError] = useState('');

  const { username } = user;

  const classes = useStyles();
  const billingFeatures = systemBillingFeatures(localStorage);

  const refreshPaymentMethods = useCallback(async (username) => {
    try {
      setIsLoading(true);
      const paymentCards = await asyncListAll(listPaymentCards, {
        username,
        filter: {
          showAsStored: {
            ne: false,
          },
        },
      });

      const methods = (paymentCards || []).map((method) => {
        method.label = method.alias ? method.alias : `**** **** **** ${method.last4.toString().padStart(4, '0')}`;
        return method;
      });

      setPaymentMethods(methods);
      setCanAddNewCard(methods.length < 3);
    } catch (e) {
      global.logger.warn(e);
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }, [setError]);

  useEffect(() => {
    (async () => {
      await refreshPaymentMethods(username);
    })();
  }, [username, refreshPaymentMethods]);

  const handleCloseError = () => {
    setError('');
  };

  const onCompleted = (data) => {
    if (inOnComplete) return inOnComplete(data);

    return refreshPaymentMethods(username);
  };

  if (!billingFeatures.allowed) {
    return <Disabled classes={classes} user={user} />;
  }

  return (
    <Container component="main" data-test-id="billing-info-container" disableGutters>
      <Grid container className={classes.paper} justifyContent="center">
        {!showTitle ? null : (
          <>
            <Avatar variant="circle" className={classes.avatar}>
              <CreditCardOutlinedIcon color="inherit" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Billing Info
            </Typography>
          </>
        )}

        {
          isLoading &&
          <Grid container className={classes.root} justify="center" alignItems="center">
            <CircularProgress color="inherit" />
          </Grid>
        }

        {showSaved && !isLoading &&
          <BillingInfoStoredPaymentList
            classes={classes}
            paymentMethods={paymentMethods}
            username={username}
            onDeleteCompleted={() => refreshPaymentMethods(username)}
            setError={setError}
          />}

        {
          canAddNewCard && !isLoading && billingFeatures.provider === 'tupay' &&
          <Tupay classes={classes} user={user} showAsStored={true} setError={setError} onCompleted={onCompleted} style={{ width: '100%' }} />
        }

        <Snackbar
          open={error != false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Alert color="info">
            Up to three credit or debit cards can be stored for your convenience.
            Please note that saving your payment information does NOT set up autopay.
            Autopay is not allowed for this Pilot.
            You will need to pay your monthly Road Charge by logging into your account and clicking the “Pay” button on your dashboard each month.
            <br /><br />
            If you decide to save your payment information to your account,
            you will see a $1 temporary pre-authorization charge on your credit or debit card account.
            This temporary pre-authorization charge will automatically be reversed after your account is verified.
          </Alert>
        </Grid>
      </Grid>
    </Container>
  );
};

BillingInfo.propTypes = {
  user: PropTypes.object,
  showTitle: PropTypes.bool,
  showSaved: PropTypes.bool,
  onCompleted: PropTypes.func,
};

const Disabled = ({ classes, user }) => {
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <CreditCardOutlinedIcon color="inherit" />
        </Avatar>
        <Typography variant="body1">
          Payment methods are currently disabled in the system and will not be used.
        </Typography>
      </div>
    </Container>
  );
};

Disabled.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};

export default BillingInfo;
