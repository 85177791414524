import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TransactionsTable from 'pages/Admin/components/TransactionsTable';

import { asyncListAll } from 'utilities/graph';
import { getTransactionsByUsernameByCreatedAt } from 'graphql/queries';

function Payments({ username, title = 'Payments' }) {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const transactions = await asyncListAll(getTransactionsByUsernameByCreatedAt, {
          username,
          sortDirection: 'DESC',
        }, {
          bypassCache: true,
        });
        setTransactions(transactions);
      } catch (e) {
        global.logger.warn(e);
      }
    })();
  }, [username]);

  return (
    <TransactionsTable
      title={title}
      data={transactions}
      viewer="admin"
    />
  );
}

Payments.propTypes = {
  username: PropTypes.string,
  title: PropTypes.string,
};

export default Payments;
