import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MroEventsTable from 'pages/Admin/components/MroEventsTable';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';
import { getMroEventsByIsProcessedByDateTime } from './queries';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function MroEvents() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const onSubmit = async ({ from, to }) => {
    setIsLoading(true);
    const [
      unprocessed,
      processed,
    ] = await Promise.all([
      asyncListAll(getMroEventsByIsProcessedByDateTime, {
        isProcessed: 0,
        dateTime: {
          between: [from, to],
        },
      }, { bypassCache: true }),
      asyncListAll(getMroEventsByIsProcessedByDateTime, {
        isProcessed: 1,
        dateTime: {
          between: [from, to],
        },
      }, { bypassCache: true }),
    ]);

    setData([...unprocessed, ...processed].sort(sortBy('createdAt', true)));
    setIsLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <DateRangeSelector
        unit={'week'}
        preset={'7days'}
        onSubmit={onSubmit}
        submitOnLoad={true}
        disabled={isLoading}
      />

      <MroEventsTable data={data} />
    </div>
  );
}
