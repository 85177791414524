import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import NotificationsTable from 'pages/Admin/components/NotificationsTable';

import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Notifications() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const onSubmit = async ({ from, to }) => {
    setIsLoading(true);

    const statuses = [
      'pending',
      'sent',
      'failed',
      'cancelled',
    ];
    let notifications = [];
    await Promise.all(statuses.map(async (status) => {
      const data = await asyncListAll(
        /* GraphQL */
        `
        query GetNotificationsByStatusByShouldBeSentAt(
          $status: NotificationStatus
          $shouldBeSentAt: ModelStringKeyConditionInput
          $sortDirection: ModelSortDirection
          $filter: ModelNotificationFilterInput
          $limit: Int
          $nextToken: String
        ) {
          getNotificationsByStatusByShouldBeSentAt(
            status: $status
            shouldBeSentAt: $shouldBeSentAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              id
              username
              type
              templateName
              email
              subject
              text
              html
              shouldBeSentAt
              retries
              status
              statusMsg
              sentAt
              createdBy
              createdAt
              logs
              updatedAt
              participant {
                # firstName
                # lastName
                email
              }
            }
            nextToken
          }
        }
      `, {
          status,
          shouldBeSentAt: {
            between: [from, to],
          },
        }, { bypassCache: true },
      );

      notifications = [...notifications, ...data];
    }));

    setNotifications(notifications.sort(sortBy('createdAt', true)));
    setIsLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <DateRangeSelector
        unit='day'
        onSubmit={onSubmit}
        submitOnLoad={true}
        disabled={isLoading}
      />

      <NotificationsTable data={notifications} />
    </div>
  );
}
